@import url(https://fonts.googleapis.com/css?family=Lato:400,400i,700,700i,900,900i|Playfair+Display:400,400i,700,700i);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@charset "UTF-8";
:root
{
    --blue: #007bff;
    --indigo: #6610f2;
    --purple: #6f42c1;
    --pink: #e83e8c;
    --red: #dc3545;
    --orange: #fd7e14;
    --yellow: #ffc107;
    --green: #28a745;
    --teal: #20c997;
    --cyan: #17a2b8;
    --white: #fff;
    --gray: #6c757d;
    --gray-dark: #343a40;
    --primary: #f5a637;
    --secondary: #6c757d;
    --success: #28a745;
    --info: #17a2b8;
    --warning: #ffc107;
    --danger: #dc3545;
    --light: #f8f9fa;
    --dark: #343a40;
    --breakpoint-xs: 0;
    --breakpoint-sm: 576px;
    --breakpoint-md: 768px;
    --breakpoint-lg: 992px;
    --breakpoint-xl: 1200px;
    --font-family-sans-serif: 'Lato', sans-serif;
    --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
}

*,
*::before,
*::after
{
    box-sizing: border-box;
}

html
{
    font-family: sans-serif;
    line-height: 1.15;

    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section
{
    display: block;
}

body
{
    font-family: 'Lato', sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.7;

    margin: 0;

    text-align: left;

    color: #777;
    background-color: #fff;
}

[tabindex='-1']:focus
{
    outline: 0 !important;
}

hr
{
    overflow: visible;

    box-sizing: content-box;
    height: 0;
}

h1,
h2,
h3,
h4,
.section_menu__item__price,
h5,
h6
{
    margin-top: 0;
    margin-bottom: .5rem;
}

p
{
    margin-top: 0;
    margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title]
{
    cursor: help;
            text-decoration: underline;
            text-decoration: underline dotted;

    border-bottom: 0;

    -webkit-text-decoration: underline dotted;
    -webkit-text-decoration-skip-ink: none;
            text-decoration-skip-ink: none;
}

address
{
    font-style: normal;
    line-height: inherit;

    margin-bottom: 1rem;
}

ol,
ul,
dl
{
    margin-top: 0;
    margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol
{
    margin-bottom: 0;
}

dt
{
    font-weight: 700;
}

dd
{
    margin-bottom: .5rem;
    margin-left: 0;
}

blockquote
{
    margin: 0 0 1rem;
}

b,
strong
{
    font-weight: bolder;
}

small
{
    font-size: 80%;
}

sub,
sup
{
    font-size: 75%;
    line-height: 0;

    position: relative;

    vertical-align: baseline;
}

sub
{
    bottom: -.25em;
}

sup
{
    top: -.5em;
}

a
{
    text-decoration: none;

    color: #f5a637;
    background-color: transparent;
}
a:hover
{
    text-decoration: underline;

    color: #f5a637;
}

a:not([href]):not([tabindex])
{
    text-decoration: none;

    color: inherit;
}
a:not([href]):not([tabindex]):hover,
a:not([href]):not([tabindex]):focus
{
    text-decoration: none;

    color: inherit;
}
a:not([href]):not([tabindex]):focus
{
    outline: 0;
}

pre,
code,
kbd,
samp
{
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
    font-size: 1em;
}

pre
{
    overflow: auto;

    margin-top: 0;
    margin-bottom: 1rem;
}

figure
{
    margin: 0 0 1rem;
}

img
{
    vertical-align: middle;

    border-style: none;
}

svg
{
    overflow: hidden;

    vertical-align: middle;
}

table
{
    border-collapse: collapse;
}

caption
{
    padding-top: .75rem;
    padding-bottom: .75rem;

    caption-side: bottom;

    text-align: left;

    color: #ababab;
}

th
{
    text-align: inherit;
}

label
{
    display: inline-block;

    margin-bottom: .5rem;
}

button
{
    border-radius: 0;
}

button:focus
{
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea
{
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;

    margin: 0;
}

button,
input
{
    overflow: visible;
}

button,
select
{
    text-transform: none;
}

select
{
    word-wrap: normal;
}

button,
[type='button'],
[type='reset'],
[type='submit']
{
    -webkit-appearance: button;
}

button:not(:disabled),
[type='button']:not(:disabled),
[type='reset']:not(:disabled),
[type='submit']:not(:disabled)
{
    cursor: pointer;
}

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner
{
    padding: 0;

    border-style: none;
}

input[type='radio'],
input[type='checkbox']
{
    box-sizing: border-box;
    padding: 0;
}

input[type='date'],
input[type='time'],
input[type='datetime-local'],
input[type='month']
{
    -webkit-appearance: listbox;
}

textarea
{
    overflow: auto;

    resize: vertical;
}

fieldset
{
    min-width: 0;
    margin: 0;
    padding: 0;

    border: 0;
}

legend
{
    font-size: 1.5rem;
    line-height: inherit;

    display: block;

    width: 100%;
    max-width: 100%;
    margin-bottom: .5rem;
    padding: 0;

    white-space: normal;

    color: inherit;
}

progress
{
    vertical-align: baseline;
}

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button
{
    height: auto;
}

[type='search']
{
    outline-offset: -2px;

    -webkit-appearance: none;
}

[type='search']::-webkit-search-decoration
{
    -webkit-appearance: none;
}

::-webkit-file-upload-button
{
    font: inherit;

    -webkit-appearance: button;
}
.loader {
  border: 8px solid #f3f3f3; /* Light grey */
  border-top: 8px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  -webkit-animation: spin 2s linear infinite;
          animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

output
{
    display: inline-block;
}

summary
{
    display: list-item;

    cursor: pointer;
}

template
{
    display: none;
}

[hidden]
{
    display: none !important;
}

h1,
h2,
h3,
h4,
.section_menu__item__price,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6
{
    font-family: 'Playfair Display', serif;
    font-weight: 400;
    line-height: 1.42857;

    margin-bottom: .5rem;

    color: #151515;
}

h1,
.h1
{
    font-size: 2.5rem;
}

h2,
.h2
{
    font-size: 2rem;
}

h3,
.h3
{
    font-size: 1.75rem;
}

h4,
.section_menu__item__price,
.h4
{
    font-size: 1.5rem;
}

h5,
.h5
{
    font-size: 1.25rem;
}

h6,
.h6
{
    font-size: 1rem;
}

.lead
{
    font-size: 1.25rem;
    font-weight: 300;
}

.display-1
{
    font-size: 6rem;
    font-weight: 300;
    line-height: 1.42857;
}
.about-us
{
  background-image: url(/static/media/36.b0bbdfd8.jpg)
}
.display-2
{
    font-size: 5.5rem;
    font-weight: 300;
    line-height: 1.42857;
}

.display-3
{
    font-size: 4.5rem;
    font-weight: 300;
    line-height: 1.42857;
}

.display-4
{
    font-size: 3.5rem;
    font-weight: 300;
    line-height: 1.42857;
}

hr
{
    margin-top: 1rem;
    margin-bottom: 1rem;

    border: 0;
    border-top: 1px solid rgba(0, 0, 0, .1);
}

small,
.small
{
    font-size: 80%;
    font-weight: 400;
}

mark,
.mark
{
    padding: .2em;

    background-color: #fcf8e3;
}

.list-unstyled
{
    padding-left: 0;

    list-style: none;
}

.list-inline
{
    padding-left: 0;

    list-style: none;
}

.list-inline-item
{
    display: inline-block;
}
.list-inline-item:not(:last-child)
{
    margin-right: .5rem;
}

.initialism
{
    font-size: 90%;

    text-transform: uppercase;
}

.blockquote
{
    font-size: 1.25rem;

    margin-bottom: 1rem;
}

.blockquote-footer
{
    font-size: 80%;

    display: block;

    color: #6c757d;
}
.blockquote-footer::before
{
    content: '\2014\00A0';
}

.img-fluid
{
    max-width: 100%;
    height: auto;
}

.img-thumbnail
{
    max-width: 100%;
    height: auto;
    padding: .25rem;

    border: 1px solid #dee2e6;
    border-radius: 0;
    background-color: #fff;
}

.figure
{
    display: inline-block;
}

.figure-img
{
    line-height: 1;

    margin-bottom: .5rem;
}

.figure-caption
{
    font-size: 90%;

    color: #6c757d;
}

code
{
    font-size: 87.5%;

    word-break: break-word;

    color: #e83e8c;
}
a > code
{
    color: inherit;
}

kbd
{
    font-size: 87.5%;

    padding: .2rem .4rem;

    color: #fff;
    border-radius: 0;
    background-color: #212529;
}
kbd kbd
{
    font-size: 100%;
    font-weight: 700;

    padding: 0;
}

pre
{
    font-size: 87.5%;

    display: block;

    color: #212529;
}
pre code
{
    font-size: inherit;

    word-break: normal;

    color: inherit;
}

.pre-scrollable
{
    overflow-y: scroll;

    max-height: 340px;
}

.container
{
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding-right: 15px;
    padding-left: 15px;
}
@media (min-width: 576px)
{
    .container
    {
        max-width: 540px;
    }
}
@media (min-width: 768px)
{
    .container
    {
        max-width: 720px;
    }
}
@media (min-width: 992px)
{
    .container
    {
        max-width: 960px;
    }
}
@media (min-width: 1200px)
{
    .container
    {
        max-width: 1140px;
    }
}

.container-fluid
{
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding-right: 15px;
    padding-left: 15px;
}

.row
{
    display: flex;

    margin-right: -15px;
    margin-left: -15px;

    flex-wrap: wrap;
}

.no-gutters
{
    margin-right: 0;
    margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*='col-']
{
    padding-right: 0;
    padding-left: 0;
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto
{
    position: relative;

    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}

.col
{
    max-width: 100%;

    flex-basis: 0;
    flex-grow: 1;
}

.col-auto
{
    width: auto;
    max-width: 100%;

    flex: 0 0 auto;
}

.col-1
{
    max-width: 8.33333%;

    flex: 0 0 8.33333%;
}

.col-2
{
    max-width: 16.66667%;

    flex: 0 0 16.66667%;
}

.col-3
{
    max-width: 25%;

    flex: 0 0 25%;
}

.col-4
{
    max-width: 33.33333%;

    flex: 0 0 33.33333%;
}

.col-5
{
    max-width: 41.66667%;

    flex: 0 0 41.66667%;
}

.col-6
{
    max-width: 50%;

    flex: 0 0 50%;
}

.col-7
{
    max-width: 58.33333%;

    flex: 0 0 58.33333%;
}

.col-8
{
    max-width: 66.66667%;

    flex: 0 0 66.66667%;
}

.col-9
{
    max-width: 75%;

    flex: 0 0 75%;
}

.col-10
{
    max-width: 83.33333%;

    flex: 0 0 83.33333%;
}

.col-11
{
    max-width: 91.66667%;

    flex: 0 0 91.66667%;
}

.col-12
{
    max-width: 100%;

    flex: 0 0 100%;
}

.order-first
{
    order: -1;
}

.order-last
{
    order: 13;
}

.order-0
{
    order: 0;
}

.order-1
{
    order: 1;
}

.order-2
{
    order: 2;
}

.order-3
{
    order: 3;
}

.order-4
{
    order: 4;
}

.order-5
{
    order: 5;
}

.order-6
{
    order: 6;
}

.order-7
{
    order: 7;
}

.order-8
{
    order: 8;
}

.order-9
{
    order: 9;
}

.order-10
{
    order: 10;
}

.order-11
{
    order: 11;
}

.order-12
{
    order: 12;
}

.offset-1
{
    margin-left: 8.33333%;
}

.offset-2
{
    margin-left: 16.66667%;
}

.offset-3
{
    margin-left: 25%;
}

.offset-4
{
    margin-left: 33.33333%;
}

.offset-5
{
    margin-left: 41.66667%;
}

.offset-6
{
    margin-left: 50%;
}

.offset-7
{
    margin-left: 58.33333%;
}

.offset-8
{
    margin-left: 66.66667%;
}

.offset-9
{
    margin-left: 75%;
}

.offset-10
{
    margin-left: 83.33333%;
}

.offset-11
{
    margin-left: 91.66667%;
}

@media (min-width: 576px)
{
    .col-sm
    {
        max-width: 100%;

        flex-basis: 0;
        flex-grow: 1;
    }
    .col-sm-auto
    {
        width: auto;
        max-width: 100%;

        flex: 0 0 auto;
    }
    .col-sm-1
    {
        max-width: 8.33333%;

        flex: 0 0 8.33333%;
    }
    .col-sm-2
    {
        max-width: 16.66667%;

        flex: 0 0 16.66667%;
    }
    .col-sm-3
    {
        max-width: 25%;

        flex: 0 0 25%;
    }
    .col-sm-4
    {
        max-width: 33.33333%;

        flex: 0 0 33.33333%;
    }
    .col-sm-5
    {
        max-width: 41.66667%;

        flex: 0 0 41.66667%;
    }
    .col-sm-6
    {
        max-width: 50%;

        flex: 0 0 50%;
    }
    .col-sm-7
    {
        max-width: 58.33333%;

        flex: 0 0 58.33333%;
    }
    .col-sm-8
    {
        max-width: 66.66667%;

        flex: 0 0 66.66667%;
    }
    .col-sm-9
    {
        max-width: 75%;

        flex: 0 0 75%;
    }
    .col-sm-10
    {
        max-width: 83.33333%;

        flex: 0 0 83.33333%;
    }
    .col-sm-11
    {
        max-width: 91.66667%;

        flex: 0 0 91.66667%;
    }
    .col-sm-12
    {
        max-width: 100%;

        flex: 0 0 100%;
    }
    .order-sm-first
    {
        order: -1;
    }
    .order-sm-last
    {
        order: 13;
    }
    .order-sm-0
    {
        order: 0;
    }
    .order-sm-1
    {
        order: 1;
    }
    .order-sm-2
    {
        order: 2;
    }
    .order-sm-3
    {
        order: 3;
    }
    .order-sm-4
    {
        order: 4;
    }
    .order-sm-5
    {
        order: 5;
    }
    .order-sm-6
    {
        order: 6;
    }
    .order-sm-7
    {
        order: 7;
    }
    .order-sm-8
    {
        order: 8;
    }
    .order-sm-9
    {
        order: 9;
    }
    .order-sm-10
    {
        order: 10;
    }
    .order-sm-11
    {
        order: 11;
    }
    .order-sm-12
    {
        order: 12;
    }
    .offset-sm-0
    {
        margin-left: 0;
    }
    .offset-sm-1
    {
        margin-left: 8.33333%;
    }
    .offset-sm-2
    {
        margin-left: 16.66667%;
    }
    .offset-sm-3
    {
        margin-left: 25%;
    }
    .offset-sm-4
    {
        margin-left: 33.33333%;
    }
    .offset-sm-5
    {
        margin-left: 41.66667%;
    }
    .offset-sm-6
    {
        margin-left: 50%;
    }
    .offset-sm-7
    {
        margin-left: 58.33333%;
    }
    .offset-sm-8
    {
        margin-left: 66.66667%;
    }
    .offset-sm-9
    {
        margin-left: 75%;
    }
    .offset-sm-10
    {
        margin-left: 83.33333%;
    }
    .offset-sm-11
    {
        margin-left: 91.66667%;
    }
}

@media (min-width: 768px)
{
    .col-md
    {
        max-width: 100%;

        flex-basis: 0;
        flex-grow: 1;
    }
    .col-md-auto
    {
        width: auto;
        max-width: 100%;

        flex: 0 0 auto;
    }
    .col-md-1
    {
        max-width: 8.33333%;

        flex: 0 0 8.33333%;
    }
    .col-md-2
    {
        max-width: 16.66667%;

        flex: 0 0 16.66667%;
    }
    .col-md-3
    {
        max-width: 25%;

        flex: 0 0 25%;
    }
    .col-md-4
    {
        max-width: 33.33333%;

        flex: 0 0 33.33333%;
    }
    .col-md-5
    {
        max-width: 41.66667%;

        flex: 0 0 41.66667%;
    }
    .col-md-6
    {
        max-width: 50%;

        flex: 0 0 50%;
    }
    .col-md-7
    {
        max-width: 58.33333%;

        flex: 0 0 58.33333%;
    }
    .col-md-8
    {
        max-width: 66.66667%;

        flex: 0 0 66.66667%;
    }
    .col-md-9
    {
        max-width: 75%;

        flex: 0 0 75%;
    }
    .col-md-10
    {
        max-width: 83.33333%;

        flex: 0 0 83.33333%;
    }
    .col-md-11
    {
        max-width: 91.66667%;

        flex: 0 0 91.66667%;
    }
    .col-md-12
    {
        max-width: 100%;

        flex: 0 0 100%;
    }
    .order-md-first
    {
        order: -1;
    }
    .order-md-last
    {
        order: 13;
    }
    .order-md-0
    {
        order: 0;
    }
    .order-md-1
    {
        order: 1;
    }
    .order-md-2
    {
        order: 2;
    }
    .order-md-3
    {
        order: 3;
    }
    .order-md-4
    {
        order: 4;
    }
    .order-md-5
    {
        order: 5;
    }
    .order-md-6
    {
        order: 6;
    }
    .order-md-7
    {
        order: 7;
    }
    .order-md-8
    {
        order: 8;
    }
    .order-md-9
    {
        order: 9;
    }
    .order-md-10
    {
        order: 10;
    }
    .order-md-11
    {
        order: 11;
    }
    .order-md-12
    {
        order: 12;
    }
    .offset-md-0
    {
        margin-left: 0;
    }
    .offset-md-1
    {
        margin-left: 8.33333%;
    }
    .offset-md-2
    {
        margin-left: 16.66667%;
    }
    .offset-md-3
    {
        margin-left: 25%;
    }
    .offset-md-4
    {
        margin-left: 33.33333%;
    }
    .offset-md-5
    {
        margin-left: 41.66667%;
    }
    .offset-md-6
    {
        margin-left: 50%;
    }
    .offset-md-7
    {
        margin-left: 58.33333%;
    }
    .offset-md-8
    {
        margin-left: 66.66667%;
    }
    .offset-md-9
    {
        margin-left: 75%;
    }
    .offset-md-10
    {
        margin-left: 83.33333%;
    }
    .offset-md-11
    {
        margin-left: 91.66667%;
    }
}

@media (min-width: 992px)
{
    .col-lg
    {
        max-width: 100%;

        flex-basis: 0;
        flex-grow: 1;
    }
    .col-lg-auto
    {
        width: auto;
        max-width: 100%;

        flex: 0 0 auto;
    }
    .col-lg-1
    {
        max-width: 8.33333%;

        flex: 0 0 8.33333%;
    }
    .col-lg-2
    {
        max-width: 16.66667%;

        flex: 0 0 16.66667%;
    }
    .col-lg-3
    {
        max-width: 25%;

        flex: 0 0 25%;
    }
    .col-lg-4
    {
        max-width: 33.33333%;

        flex: 0 0 33.33333%;
    }
    .col-lg-5
    {
        max-width: 41.66667%;

        flex: 0 0 41.66667%;
    }
    .col-lg-6
    {
        max-width: 50%;

        flex: 0 0 50%;
    }
    .col-lg-7
    {
        max-width: 58.33333%;

        flex: 0 0 58.33333%;
    }
    .col-lg-8
    {
        max-width: 66.66667%;

        flex: 0 0 66.66667%;
    }
    .col-lg-9
    {
        max-width: 75%;

        flex: 0 0 75%;
    }
    .col-lg-10
    {
        max-width: 83.33333%;

        flex: 0 0 83.33333%;
    }
    .col-lg-11
    {
        max-width: 91.66667%;

        flex: 0 0 91.66667%;
    }
    .col-lg-12
    {
        max-width: 100%;

        flex: 0 0 100%;
    }
    .order-lg-first
    {
        order: -1;
    }
    .order-lg-last
    {
        order: 13;
    }
    .order-lg-0
    {
        order: 0;
    }
    .order-lg-1
    {
        order: 1;
    }
    .order-lg-2
    {
        order: 2;
    }
    .order-lg-3
    {
        order: 3;
    }
    .order-lg-4
    {
        order: 4;
    }
    .order-lg-5
    {
        order: 5;
    }
    .order-lg-6
    {
        order: 6;
    }
    .order-lg-7
    {
        order: 7;
    }
    .order-lg-8
    {
        order: 8;
    }
    .order-lg-9
    {
        order: 9;
    }
    .order-lg-10
    {
        order: 10;
    }
    .order-lg-11
    {
        order: 11;
    }
    .order-lg-12
    {
        order: 12;
    }
    .offset-lg-0
    {
        margin-left: 0;
    }
    .offset-lg-1
    {
        margin-left: 8.33333%;
    }
    .offset-lg-2
    {
        margin-left: 16.66667%;
    }
    .offset-lg-3
    {
        margin-left: 25%;
    }
    .offset-lg-4
    {
        margin-left: 33.33333%;
    }
    .offset-lg-5
    {
        margin-left: 41.66667%;
    }
    .offset-lg-6
    {
        margin-left: 50%;
    }
    .offset-lg-7
    {
        margin-left: 58.33333%;
    }
    .offset-lg-8
    {
        margin-left: 66.66667%;
    }
    .offset-lg-9
    {
        margin-left: 75%;
    }
    .offset-lg-10
    {
        margin-left: 83.33333%;
    }
    .offset-lg-11
    {
        margin-left: 91.66667%;
    }
}

@media (min-width: 1200px)
{
    .col-xl
    {
        max-width: 100%;

        flex-basis: 0;
        flex-grow: 1;
    }
    .col-xl-auto
    {
        width: auto;
        max-width: 100%;

        flex: 0 0 auto;
    }
    .col-xl-1
    {
        max-width: 8.33333%;

        flex: 0 0 8.33333%;
    }
    .col-xl-2
    {
        max-width: 16.66667%;

        flex: 0 0 16.66667%;
    }
    .col-xl-3
    {
        max-width: 25%;

        flex: 0 0 25%;
    }
    .col-xl-4
    {
        max-width: 33.33333%;

        flex: 0 0 33.33333%;
    }
    .col-xl-5
    {
        max-width: 41.66667%;

        flex: 0 0 41.66667%;
    }
    .col-xl-6
    {
        max-width: 50%;

        flex: 0 0 50%;
    }
    .col-xl-7
    {
        max-width: 58.33333%;

        flex: 0 0 58.33333%;
    }
    .col-xl-8
    {
        max-width: 66.66667%;

        flex: 0 0 66.66667%;
    }
    .col-xl-9
    {
        max-width: 75%;

        flex: 0 0 75%;
    }
    .col-xl-10
    {
        max-width: 83.33333%;

        flex: 0 0 83.33333%;
    }
    .col-xl-11
    {
        max-width: 91.66667%;

        flex: 0 0 91.66667%;
    }
    .col-xl-12
    {
        max-width: 100%;

        flex: 0 0 100%;
    }
    .order-xl-first
    {
        order: -1;
    }
    .order-xl-last
    {
        order: 13;
    }
    .order-xl-0
    {
        order: 0;
    }
    .order-xl-1
    {
        order: 1;
    }
    .order-xl-2
    {
        order: 2;
    }
    .order-xl-3
    {
        order: 3;
    }
    .order-xl-4
    {
        order: 4;
    }
    .order-xl-5
    {
        order: 5;
    }
    .order-xl-6
    {
        order: 6;
    }
    .order-xl-7
    {
        order: 7;
    }
    .order-xl-8
    {
        order: 8;
    }
    .order-xl-9
    {
        order: 9;
    }
    .order-xl-10
    {
        order: 10;
    }
    .order-xl-11
    {
        order: 11;
    }
    .order-xl-12
    {
        order: 12;
    }
    .offset-xl-0
    {
        margin-left: 0;
    }
    .offset-xl-1
    {
        margin-left: 8.33333%;
    }
    .offset-xl-2
    {
        margin-left: 16.66667%;
    }
    .offset-xl-3
    {
        margin-left: 25%;
    }
    .offset-xl-4
    {
        margin-left: 33.33333%;
    }
    .offset-xl-5
    {
        margin-left: 41.66667%;
    }
    .offset-xl-6
    {
        margin-left: 50%;
    }
    .offset-xl-7
    {
        margin-left: 58.33333%;
    }
    .offset-xl-8
    {
        margin-left: 66.66667%;
    }
    .offset-xl-9
    {
        margin-left: 75%;
    }
    .offset-xl-10
    {
        margin-left: 83.33333%;
    }
    .offset-xl-11
    {
        margin-left: 91.66667%;
    }
}

.table
{
    width: 100%;
    margin-bottom: 1rem;

    color: #777;
}
.table th,
.table td
{
    padding: .75rem;

    vertical-align: top;

    border-top: 1px solid #f0f0f0;
}
.table thead th
{
    vertical-align: bottom;

    border-bottom: 2px solid #f0f0f0;
}
.table tbody + tbody
{
    border-top: 2px solid #f0f0f0;
}

.table-sm th,
.table-sm td
{
    padding: .3rem;
}

.table-bordered
{
    border: 1px solid #f0f0f0;
}
.table-bordered th,
.table-bordered td
{
    border: 1px solid #f0f0f0;
}
.table-bordered thead th,
.table-bordered thead td
{
    border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody
{
    border: 0;
}

.table-striped tbody tr:nth-of-type(odd)
{
    background-color: rgba(0, 0, 0, .05);
}

.table-hover tbody tr:hover
{
    color: #777;
    background-color: rgba(0, 0, 0, .075);
}

.table-primary,
.table-primary > th,
.table-primary > td
{
    background-color: #fce6c7;
}

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody
{
    border-color: #fad197;
}

.table-hover .table-primary:hover
{
    background-color: #fbdbaf;
}
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th
{
    background-color: #fbdbaf;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td
{
    background-color: #d6d8db;
}

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody
{
    border-color: #b3b7bb;
}

.table-hover .table-secondary:hover
{
    background-color: #c8cbcf;
}
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th
{
    background-color: #c8cbcf;
}

.table-success,
.table-success > th,
.table-success > td
{
    background-color: #c3e6cb;
}

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody
{
    border-color: #8fd19e;
}

.table-hover .table-success:hover
{
    background-color: #b1dfbb;
}
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th
{
    background-color: #b1dfbb;
}

.table-info,
.table-info > th,
.table-info > td
{
    background-color: #bee5eb;
}

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody
{
    border-color: #86cfda;
}

.table-hover .table-info:hover
{
    background-color: #abdde5;
}
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th
{
    background-color: #abdde5;
}

.table-warning,
.table-warning > th,
.table-warning > td
{
    background-color: #ffeeba;
}

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody
{
    border-color: #ffdf7e;
}

.table-hover .table-warning:hover
{
    background-color: #ffe8a1;
}
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th
{
    background-color: #ffe8a1;
}

.table-danger,
.table-danger > th,
.table-danger > td
{
    background-color: #f5c6cb;
}

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody
{
    border-color: #ed969e;
}

.table-hover .table-danger:hover
{
    background-color: #f1b0b7;
}
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th
{
    background-color: #f1b0b7;
}

.table-light,
.table-light > th,
.table-light > td
{
    background-color: #fdfdfe;
}

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody
{
    border-color: #fbfcfc;
}

.table-hover .table-light:hover
{
    background-color: #ececf6;
}
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th
{
    background-color: #ececf6;
}

.table-dark,
.table-dark > th,
.table-dark > td
{
    background-color: #c6c8ca;
}

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody
{
    border-color: #95999c;
}

.table-hover .table-dark:hover
{
    background-color: #b9bbbe;
}
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th
{
    background-color: #b9bbbe;
}

.table-active,
.table-active > th,
.table-active > td
{
    background-color: rgba(0, 0, 0, .075);
}

.table-hover .table-active:hover
{
    background-color: rgba(0, 0, 0, .075);
}
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th
{
    background-color: rgba(0, 0, 0, .075);
}

.table .thead-dark th
{
    color: #fff;
    border-color: #454d55;
    background-color: #343a40;
}

.table .thead-light th
{
    color: #495057;
    border-color: #f0f0f0;
    background-color: #e9ecef;
}

.table-dark
{
    color: #fff;
    background-color: #343a40;
}
.table-dark th,
.table-dark td,
.table-dark thead th
{
    border-color: #454d55;
}
.table-dark.table-bordered
{
    border: 0;
}
.table-dark.table-striped tbody tr:nth-of-type(odd)
{
    background-color: rgba(255, 255, 255, .05);
}
.table-dark.table-hover tbody tr:hover
{
    color: #fff;
    background-color: rgba(255, 255, 255, .075);
}

@media (max-width: 575.98px)
{
    .table-responsive-sm
    {
        display: block;
        overflow-x: auto;

        width: 100%;

        -webkit-overflow-scrolling: touch;
    }
    .table-responsive-sm > .table-bordered
    {
        border: 0;
    }
}

@media (max-width: 767.98px)
{
    .table-responsive-md
    {
        display: block;
        overflow-x: auto;

        width: 100%;

        -webkit-overflow-scrolling: touch;
    }
    .table-responsive-md > .table-bordered
    {
        border: 0;
    }
}

@media (max-width: 991.98px)
{
    .table-responsive-lg
    {
        display: block;
        overflow-x: auto;

        width: 100%;

        -webkit-overflow-scrolling: touch;
    }
    .table-responsive-lg > .table-bordered
    {
        border: 0;
    }
}

@media (max-width: 1199.98px)
{
    .table-responsive-xl
    {
        display: block;
        overflow-x: auto;

        width: 100%;

        -webkit-overflow-scrolling: touch;
    }
    .table-responsive-xl > .table-bordered
    {
        border: 0;
    }
}

.table-responsive
{
    display: block;
    overflow-x: auto;

    width: 100%;

    -webkit-overflow-scrolling: touch;
}
.table-responsive > .table-bordered
{
    border: 0;
}

.form-control
{
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.25;

    display: block;

    width: 100%;
    height: calc(1.25em + 2rem + 2px);
    padding: 1rem 2rem;

    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;

    color: #495057;
    border: 1px solid #f0f0f0;
    border-radius: 0;
    background-color: #fff;
    background-clip: padding-box;
}
@media (prefers-reduced-motion: reduce)
{
    .form-control
    {
        transition: none;
    }
}
.form-control::-ms-expand
{
    border: 0;
    background-color: transparent;
}
.form-control:focus
{
    color: #495057;
    border-color: #f5a637;
    outline: 0;
    background-color: #fff;
    box-shadow: none;
}
.form-control:-ms-input-placeholder
{
    opacity: 1;
    color: #6c757d;
}
.form-control::-webkit-input-placeholder
{
    opacity: 1;
    color: #6c757d;
}
.form-control::-ms-input-placeholder
{
    opacity: 1;
    color: #6c757d;
}
.form-control::placeholder
{
    opacity: 1;
    color: #6c757d;
}
.form-control:disabled,
.form-control[readonly]
{
    opacity: 1;
    background-color: #e9ecef;
}

select.form-control:focus::-ms-value
{
    color: #495057;
    background-color: #fff;
}

.form-control-file,
.form-control-range
{
    display: block;

    width: 100%;
}

.col-form-label
{
    font-size: inherit;
    line-height: 1.25;

    margin-bottom: 0;
    padding-top: calc(1rem + 1px);
    padding-bottom: calc(1rem + 1px);
}

.col-form-label-lg
{
    font-size: 1.25rem;
    line-height: 1.5;

    padding-top: calc(.5rem + 1px);
    padding-bottom: calc(.5rem + 1px);
}

.col-form-label-sm
{
    font-size: .85rem;
    line-height: 1.25;

    padding-top: calc(.75rem + 1px);
    padding-bottom: calc(.75rem + 1px);
}

.form-control-plaintext
{
    line-height: 1.25;

    display: block;

    width: 100%;
    margin-bottom: 0;
    padding-top: 1rem;
    padding-bottom: 1rem;

    color: #777;
    border: solid transparent;
    border-width: 1px 0;
    background-color: transparent;
}
.form-control-plaintext.form-control-sm,
.form-control-plaintext.form-control-lg
{
    padding-right: 0;
    padding-left: 0;
}

.form-control-sm
{
    font-size: .85rem;
    line-height: 1.25;

    height: calc(1.25em + 1.5rem + 2px);
    padding: .75rem 1.5rem;

    border-radius: 0;
}

.form-control-lg
{
    font-size: 1.25rem;
    line-height: 1.5;

    height: calc(1.5em + 1rem + 2px);
    padding: .5rem 1rem;

    border-radius: 0;
}

select.form-control[size],
select.form-control[multiple]
{
    height: auto;
}

textarea.form-control
{
    height: auto;
}

.form-group
{
    margin-bottom: 1rem;
}

.form-text
{
    display: block;

    margin-top: .25rem;
}

.form-row
{
    display: flex;

    margin-right: -5px;
    margin-left: -5px;

    flex-wrap: wrap;
}
.form-row > .col,
.form-row > [class*='col-']
{
    padding-right: 5px;
    padding-left: 5px;
}

.form-check
{
    position: relative;

    display: block;

    padding-left: 1.25rem;
}

.form-check-input
{
    position: absolute;

    margin-top: .3rem;
    margin-left: -1.25rem;
}
.form-check-input:disabled ~ .form-check-label
{
    color: #ababab;
}

.form-check-label
{
    margin-bottom: 0;
}

.form-check-inline
{
    display: inline-flex;

    margin-right: .75rem;
    padding-left: 0;

    align-items: center;
}
.form-check-inline .form-check-input
{
    position: static;

    margin-top: 0;
    margin-right: .3125rem;
    margin-left: 0;
}

.valid-feedback
{
    font-size: 80%;

    display: none;

    width: 100%;
    margin-top: .25rem;

    color: #28a745;
}

.valid-tooltip
{
    font-size: .85rem;
    line-height: 1.7;

    position: absolute;
    z-index: 5;
    top: 100%;

    display: none;

    max-width: 100%;
    margin-top: .1rem;
    padding: .25rem .5rem;

    color: #fff;
    border-radius: 0;
    background-color: rgba(40, 167, 69, .9);
}

.was-validated .form-control:valid,
.form-control.is-valid
{
    padding-right: calc(1.25em + 2rem);

    border-color: #28a745;
    background-image: url('data:image/svg xml,<svg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 8 8\'><path fill=\'#28a745\' d=\'M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z\'/></svg>');
    background-repeat: no-repeat;
    background-position: center right calc(.3125em + .5rem);
    background-size: calc(.625em + 1rem) calc(.625em + 1rem);
}
.was-validated .form-control:valid:focus,
.form-control.is-valid:focus
{
    border-color: #28a745;
    box-shadow: 0 0 0 .2rem rgba(40, 167, 69, .25);
}
.was-validated .form-control:valid ~ .valid-feedback,
.was-validated .form-control:valid ~ .valid-tooltip,
.form-control.is-valid ~ .valid-feedback,
.form-control.is-valid ~ .valid-tooltip
{
    display: block;
}

.was-validated textarea.form-control:valid,
textarea.form-control.is-valid
{
    padding-right: calc(1.25em + 2rem);

    background-position: top calc(.3125em + .5rem) right calc(.3125em + .5rem);
}

.was-validated .custom-select:valid,
.custom-select.is-valid
{
    padding-right: calc((1em + 2rem) * 3 / 4 + 3rem);

    border-color: #28a745;
    background: url('data:image/svg xml,<svg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 4 5\'><path fill=\'#343a40\' d=\'M2 0L0 2h4zm0 5L0 3h4z\'/></svg>') no-repeat right 2rem center/8px 10px, url('data:image/svg xml,<svg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 8 8\'><path fill=\'#28a745\' d=\'M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z\'/></svg>') #fff no-repeat center right 3rem/calc(.625em + 1rem) calc(.625em + 1rem);
}
.was-validated .custom-select:valid:focus,
.custom-select.is-valid:focus
{
    border-color: #28a745;
    box-shadow: 0 0 0 .2rem rgba(40, 167, 69, .25);
}
.was-validated .custom-select:valid ~ .valid-feedback,
.was-validated .custom-select:valid ~ .valid-tooltip,
.custom-select.is-valid ~ .valid-feedback,
.custom-select.is-valid ~ .valid-tooltip
{
    display: block;
}

.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip,
.form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip
{
    display: block;
}

.was-validated .form-check-input:valid ~ .form-check-label,
.form-check-input.is-valid ~ .form-check-label
{
    color: #28a745;
}

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip,
.form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip
{
    display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label,
.custom-control-input.is-valid ~ .custom-control-label
{
    color: #28a745;
}
.was-validated .custom-control-input:valid ~ .custom-control-label::before,
.custom-control-input.is-valid ~ .custom-control-label::before
{
    border-color: #28a745;
}

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip,
.custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip
{
    display: block;
}

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before,
.custom-control-input.is-valid:checked ~ .custom-control-label::before
{
    border-color: #34ce57;
    background-color: #34ce57;
}

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before,
.custom-control-input.is-valid:focus ~ .custom-control-label::before
{
    box-shadow: 0 0 0 .2rem rgba(40, 167, 69, .25);
}

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before,
.custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before
{
    border-color: #28a745;
}

.was-validated .custom-file-input:valid ~ .custom-file-label,
.custom-file-input.is-valid ~ .custom-file-label
{
    border-color: #28a745;
}

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip,
.custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip
{
    display: block;
}

.was-validated .custom-file-input:valid:focus ~ .custom-file-label,
.custom-file-input.is-valid:focus ~ .custom-file-label
{
    border-color: #28a745;
    box-shadow: 0 0 0 .2rem rgba(40, 167, 69, .25);
}

.invalid-feedback
{
    font-size: 80%;

    display: none;

    width: 100%;
    margin-top: .25rem;

    color: #dc3545;
}

.invalid-tooltip
{
    font-size: .85rem;
    line-height: 1.7;

    position: absolute;
    z-index: 5;
    top: 100%;

    display: none;

    max-width: 100%;
    margin-top: .1rem;
    padding: .25rem .5rem;

    color: #fff;
    border-radius: 0;
    background-color: rgba(220, 53, 69, .9);
}

.was-validated .form-control:invalid,
.form-control.is-invalid
{
    padding-right: calc(1.25em + 2rem);

    border-color: #dc3545;
    background-image: url('data:image/svg xml,<svg xmlns=\'http://www.w3.org/2000/svg\' fill=\'#dc3545\' viewBox=\'-2 -2 7 7\'><path stroke=\'#dc3545\' d=\'M0 0l3 3m0-3L0 3\'/><circle r=\'.5\'/><circle cx=\'3\' r=\'.5\'/><circle cy=\'3\' r=\'.5\'/><circle cx=\'3\' cy=\'3\' r=\'.5\'/></svg>');
    background-repeat: no-repeat;
    background-position: center right calc(.3125em + .5rem);
    background-size: calc(.625em + 1rem) calc(.625em + 1rem);
}
.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus
{
    border-color: #dc3545;
    box-shadow: 0 0 0 .2rem rgba(220, 53, 69, .25);
}
.was-validated .form-control:invalid ~ .invalid-feedback,
.was-validated .form-control:invalid ~ .invalid-tooltip,
.form-control.is-invalid ~ .invalid-feedback,
.form-control.is-invalid ~ .invalid-tooltip
{
    display: block;
}

.was-validated textarea.form-control:invalid,
textarea.form-control.is-invalid
{
    padding-right: calc(1.25em + 2rem);

    background-position: top calc(.3125em + .5rem) right calc(.3125em + .5rem);
}

.was-validated .custom-select:invalid,
.custom-select.is-invalid
{
    padding-right: calc((1em + 2rem) * 3 / 4 + 3rem);

    border-color: #dc3545;
    background: url('data:image/svg xml,<svg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 4 5\'><path fill=\'#343a40\' d=\'M2 0L0 2h4zm0 5L0 3h4z\'/></svg>') no-repeat right 2rem center/8px 10px, url('data:image/svg xml,<svg xmlns=\'http://www.w3.org/2000/svg\' fill=\'#dc3545\' viewBox=\'-2 -2 7 7\'><path stroke=\'#dc3545\' d=\'M0 0l3 3m0-3L0 3\'/><circle r=\'.5\'/><circle cx=\'3\' r=\'.5\'/><circle cy=\'3\' r=\'.5\'/><circle cx=\'3\' cy=\'3\' r=\'.5\'/></svg>') #fff no-repeat center right 3rem/calc(.625em + 1rem) calc(.625em + 1rem);
}
.was-validated .custom-select:invalid:focus,
.custom-select.is-invalid:focus
{
    border-color: #dc3545;
    box-shadow: 0 0 0 .2rem rgba(220, 53, 69, .25);
}
.was-validated .custom-select:invalid ~ .invalid-feedback,
.was-validated .custom-select:invalid ~ .invalid-tooltip,
.custom-select.is-invalid ~ .invalid-feedback,
.custom-select.is-invalid ~ .invalid-tooltip
{
    display: block;
}

.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip,
.form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip
{
    display: block;
}

.was-validated .form-check-input:invalid ~ .form-check-label,
.form-check-input.is-invalid ~ .form-check-label
{
    color: #dc3545;
}

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip,
.form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip
{
    display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label,
.custom-control-input.is-invalid ~ .custom-control-label
{
    color: #dc3545;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label::before,
.custom-control-input.is-invalid ~ .custom-control-label::before
{
    border-color: #dc3545;
}

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip,
.custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip
{
    display: block;
}

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before,
.custom-control-input.is-invalid:checked ~ .custom-control-label::before
{
    border-color: #e4606d;
    background-color: #e4606d;
}

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before,
.custom-control-input.is-invalid:focus ~ .custom-control-label::before
{
    box-shadow: 0 0 0 .2rem rgba(220, 53, 69, .25);
}

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before,
.custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before
{
    border-color: #dc3545;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label,
.custom-file-input.is-invalid ~ .custom-file-label
{
    border-color: #dc3545;
}

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip,
.custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip
{
    display: block;
}

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label,
.custom-file-input.is-invalid:focus ~ .custom-file-label
{
    border-color: #dc3545;
    box-shadow: 0 0 0 .2rem rgba(220, 53, 69, .25);
}

.form-inline
{
    display: flex;

    flex-flow: row wrap;
    align-items: center;
}
.form-inline .form-check
{
    width: 100%;
}
@media (min-width: 576px)
{
    .form-inline label
    {
        display: flex;

        margin-bottom: 0;

        align-items: center;
        justify-content: center;
    }
    .form-inline .form-group
    {
        display: flex;

        margin-bottom: 0;

        flex: 0 0 auto;
        flex-flow: row wrap;
        align-items: center;
    }
    .form-inline .form-control
    {
        display: inline-block;

        width: auto;

        vertical-align: middle;
    }
    .form-inline .form-control-plaintext
    {
        display: inline-block;
    }
    .form-inline .input-group,
    .form-inline .custom-select
    {
        width: auto;
    }
    .form-inline .form-check
    {
        display: flex;

        width: auto;
        padding-left: 0;

        align-items: center;
        justify-content: center;
    }
    .form-inline .form-check-input
    {
        position: relative;

        margin-top: 0;
        margin-right: .25rem;
        margin-left: 0;

        flex-shrink: 0;
    }
    .form-inline .custom-control
    {
        align-items: center;
        justify-content: center;
    }
    .form-inline .custom-control-label
    {
        margin-bottom: 0;
    }
}

.btn
{
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.25;

    display: inline-block;

    padding: 1rem 2rem;

    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    transition: color .1s;
    text-align: center;
    vertical-align: middle;

    color: #777;
    border: 1px solid transparent;
    border-radius: 0;
    background-color: transparent;
}
@media (prefers-reduced-motion: reduce)
{
    .btn
    {
        transition: none;
    }
}
.btn:hover
{
    text-decoration: none;

    color: #777;
}
.btn:focus,
.btn.focus
{
    outline: 0;
    box-shadow: none;
}
.btn.disabled,
.btn:disabled
{
    opacity: .65;
}

a.btn.disabled,
fieldset:disabled a.btn
{
    pointer-events: none;
}

.btn-primary
{
    color: #212529;
    border-color: #f5a637;
    background-color: #f5a637;
}
.btn-primary:hover
{
    color: #212529;
    border-color: #ed8f0c;
    background-color: #f39613;
}
.btn-primary:focus,
.btn-primary.focus
{
    box-shadow: 0 0 0 .2rem rgba(213, 147, 53, .5);
}
.btn-primary.disabled,
.btn-primary:disabled
{
    color: #212529;
    border-color: #f5a637;
    background-color: #f5a637;
}
.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle
{
    color: #212529;
    border-color: #e1880b;
    background-color: #ed8f0c;
}
.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus
{
    box-shadow: 0 0 0 .2rem rgba(213, 147, 53, .5);
}

.btn-secondary
{
    color: #fff;
    border-color: #6c757d;
    background-color: #6c757d;
}
.btn-secondary:hover
{
    color: #fff;
    border-color: #545b62;
    background-color: #5a6268;
}
.btn-secondary:focus,
.btn-secondary.focus
{
    box-shadow: 0 0 0 .2rem rgba(130, 138, 145, .5);
}
.btn-secondary.disabled,
.btn-secondary:disabled
{
    color: #fff;
    border-color: #6c757d;
    background-color: #6c757d;
}
.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle
{
    color: #fff;
    border-color: #4e555b;
    background-color: #545b62;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus
{
    box-shadow: 0 0 0 .2rem rgba(130, 138, 145, .5);
}

.btn-success
{
    color: #fff;
    border-color: #28a745;
    background-color: #28a745;
}
.btn-success:hover
{
    color: #fff;
    border-color: #1e7e34;
    background-color: #218838;
}
.btn-success:focus,
.btn-success.focus
{
    box-shadow: 0 0 0 .2rem rgba(72, 180, 97, .5);
}
.btn-success.disabled,
.btn-success:disabled
{
    color: #fff;
    border-color: #28a745;
    background-color: #28a745;
}
.btn-success:not(:disabled):not(.disabled):active,
.btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle
{
    color: #fff;
    border-color: #1c7430;
    background-color: #1e7e34;
}
.btn-success:not(:disabled):not(.disabled):active:focus,
.btn-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-success.dropdown-toggle:focus
{
    box-shadow: 0 0 0 .2rem rgba(72, 180, 97, .5);
}

.btn-info
{
    color: #fff;
    border-color: #17a2b8;
    background-color: #17a2b8;
}
.btn-info:hover
{
    color: #fff;
    border-color: #117a8b;
    background-color: #138496;
}
.btn-info:focus,
.btn-info.focus
{
    box-shadow: 0 0 0 .2rem rgba(58, 176, 195, .5);
}
.btn-info.disabled,
.btn-info:disabled
{
    color: #fff;
    border-color: #17a2b8;
    background-color: #17a2b8;
}
.btn-info:not(:disabled):not(.disabled):active,
.btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle
{
    color: #fff;
    border-color: #10707f;
    background-color: #117a8b;
}
.btn-info:not(:disabled):not(.disabled):active:focus,
.btn-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-info.dropdown-toggle:focus
{
    box-shadow: 0 0 0 .2rem rgba(58, 176, 195, .5);
}

.btn-warning
{
    color: #212529;
    border-color: #ffc107;
    background-color: #ffc107;
}
.btn-warning:hover
{
    color: #212529;
    border-color: #d39e00;
    background-color: #e0a800;
}
.btn-warning:focus,
.btn-warning.focus
{
    box-shadow: 0 0 0 .2rem rgba(222, 170, 12, .5);
}
.btn-warning.disabled,
.btn-warning:disabled
{
    color: #212529;
    border-color: #ffc107;
    background-color: #ffc107;
}
.btn-warning:not(:disabled):not(.disabled):active,
.btn-warning:not(:disabled):not(.disabled).active,
.show > .btn-warning.dropdown-toggle
{
    color: #212529;
    border-color: #c69500;
    background-color: #d39e00;
}
.btn-warning:not(:disabled):not(.disabled):active:focus,
.btn-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-warning.dropdown-toggle:focus
{
    box-shadow: 0 0 0 .2rem rgba(222, 170, 12, .5);
}

.btn-danger
{
    color: #fff;
    border-color: #dc3545;
    background-color: #dc3545;
}
.btn-danger:hover
{
    color: #fff;
    border-color: #bd2130;
    background-color: #c82333;
}
.btn-danger:focus,
.btn-danger.focus
{
    box-shadow: 0 0 0 .2rem rgba(225, 83, 97, .5);
}
.btn-danger.disabled,
.btn-danger:disabled
{
    color: #fff;
    border-color: #dc3545;
    background-color: #dc3545;
}
.btn-danger:not(:disabled):not(.disabled):active,
.btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-danger.dropdown-toggle
{
    color: #fff;
    border-color: #b21f2d;
    background-color: #bd2130;
}
.btn-danger:not(:disabled):not(.disabled):active:focus,
.btn-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-danger.dropdown-toggle:focus
{
    box-shadow: 0 0 0 .2rem rgba(225, 83, 97, .5);
}

.btn-light
{
    color: #212529;
    border-color: #f8f9fa;
    background-color: #f8f9fa;
}
.btn-light:hover
{
    color: #212529;
    border-color: #dae0e5;
    background-color: #e2e6ea;
}
.btn-light:focus,
.btn-light.focus
{
    box-shadow: 0 0 0 .2rem rgba(216, 217, 219, .5);
}
.btn-light.disabled,
.btn-light:disabled
{
    color: #212529;
    border-color: #f8f9fa;
    background-color: #f8f9fa;
}
.btn-light:not(:disabled):not(.disabled):active,
.btn-light:not(:disabled):not(.disabled).active,
.show > .btn-light.dropdown-toggle
{
    color: #212529;
    border-color: #d3d9df;
    background-color: #dae0e5;
}
.btn-light:not(:disabled):not(.disabled):active:focus,
.btn-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-light.dropdown-toggle:focus
{
    box-shadow: 0 0 0 .2rem rgba(216, 217, 219, .5);
}

.btn-dark
{
    color: #fff;
    border-color: #343a40;
    background-color: #343a40;
}
.btn-dark:hover
{
    color: #fff;
    border-color: #1d2124;
    background-color: #23272b;
}
.btn-dark:focus,
.btn-dark.focus
{
    box-shadow: 0 0 0 .2rem rgba(82, 88, 93, .5);
}
.btn-dark.disabled,
.btn-dark:disabled
{
    color: #fff;
    border-color: #343a40;
    background-color: #343a40;
}
.btn-dark:not(:disabled):not(.disabled):active,
.btn-dark:not(:disabled):not(.disabled).active,
.show > .btn-dark.dropdown-toggle
{
    color: #fff;
    border-color: #171a1d;
    background-color: #1d2124;
}
.btn-dark:not(:disabled):not(.disabled):active:focus,
.btn-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-dark.dropdown-toggle:focus
{
    box-shadow: 0 0 0 .2rem rgba(82, 88, 93, .5);
}

.btn-outline-primary
{
    color: #f5a637;
    border-color: #f5a637;
}
.btn-outline-primary:hover
{
    color: #212529;
    border-color: #f5a637;
    background-color: #f5a637;
}
.btn-outline-primary:focus,
.btn-outline-primary.focus
{
    box-shadow: 0 0 0 .2rem rgba(245, 166, 55, .5);
}
.btn-outline-primary.disabled,
.btn-outline-primary:disabled
{
    color: #f5a637;
    background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle
{
    color: #212529;
    border-color: #f5a637;
    background-color: #f5a637;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus
{
    box-shadow: 0 0 0 .2rem rgba(245, 166, 55, .5);
}

.btn-outline-secondary
{
    color: #6c757d;
    border-color: #6c757d;
}
.btn-outline-secondary:hover
{
    color: #fff;
    border-color: #6c757d;
    background-color: #6c757d;
}
.btn-outline-secondary:focus,
.btn-outline-secondary.focus
{
    box-shadow: 0 0 0 .2rem rgba(108, 117, 125, .5);
}
.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled
{
    color: #6c757d;
    background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle
{
    color: #fff;
    border-color: #6c757d;
    background-color: #6c757d;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus
{
    box-shadow: 0 0 0 .2rem rgba(108, 117, 125, .5);
}

.btn-outline-success
{
    color: #28a745;
    border-color: #28a745;
}
.btn-outline-success:hover
{
    color: #fff;
    border-color: #28a745;
    background-color: #28a745;
}
.btn-outline-success:focus,
.btn-outline-success.focus
{
    box-shadow: 0 0 0 .2rem rgba(40, 167, 69, .5);
}
.btn-outline-success.disabled,
.btn-outline-success:disabled
{
    color: #28a745;
    background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active,
.btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle
{
    color: #fff;
    border-color: #28a745;
    background-color: #28a745;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-success.dropdown-toggle:focus
{
    box-shadow: 0 0 0 .2rem rgba(40, 167, 69, .5);
}

.btn-outline-info
{
    color: #17a2b8;
    border-color: #17a2b8;
}
.btn-outline-info:hover
{
    color: #fff;
    border-color: #17a2b8;
    background-color: #17a2b8;
}
.btn-outline-info:focus,
.btn-outline-info.focus
{
    box-shadow: 0 0 0 .2rem rgba(23, 162, 184, .5);
}
.btn-outline-info.disabled,
.btn-outline-info:disabled
{
    color: #17a2b8;
    background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled):active,
.btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle
{
    color: #fff;
    border-color: #17a2b8;
    background-color: #17a2b8;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-info.dropdown-toggle:focus
{
    box-shadow: 0 0 0 .2rem rgba(23, 162, 184, .5);
}

.btn-outline-warning
{
    color: #ffc107;
    border-color: #ffc107;
}
.btn-outline-warning:hover
{
    color: #212529;
    border-color: #ffc107;
    background-color: #ffc107;
}
.btn-outline-warning:focus,
.btn-outline-warning.focus
{
    box-shadow: 0 0 0 .2rem rgba(255, 193, 7, .5);
}
.btn-outline-warning.disabled,
.btn-outline-warning:disabled
{
    color: #ffc107;
    background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled):active,
.btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle
{
    color: #212529;
    border-color: #ffc107;
    background-color: #ffc107;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus
{
    box-shadow: 0 0 0 .2rem rgba(255, 193, 7, .5);
}

.btn-outline-danger
{
    color: #dc3545;
    border-color: #dc3545;
}
.btn-outline-danger:hover
{
    color: #fff;
    border-color: #dc3545;
    background-color: #dc3545;
}
.btn-outline-danger:focus,
.btn-outline-danger.focus
{
    box-shadow: 0 0 0 .2rem rgba(220, 53, 69, .5);
}
.btn-outline-danger.disabled,
.btn-outline-danger:disabled
{
    color: #dc3545;
    background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled):active,
.btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle
{
    color: #fff;
    border-color: #dc3545;
    background-color: #dc3545;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus
{
    box-shadow: 0 0 0 .2rem rgba(220, 53, 69, .5);
}

.btn-outline-light
{
    color: #f8f9fa;
    border-color: #f8f9fa;
}
.btn-outline-light:hover
{
    color: #212529;
    border-color: #f8f9fa;
    background-color: #f8f9fa;
}
.btn-outline-light:focus,
.btn-outline-light.focus
{
    box-shadow: 0 0 0 .2rem rgba(248, 249, 250, .5);
}
.btn-outline-light.disabled,
.btn-outline-light:disabled
{
    color: #f8f9fa;
    background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled):active,
.btn-outline-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-light.dropdown-toggle
{
    color: #212529;
    border-color: #f8f9fa;
    background-color: #f8f9fa;
}
.btn-outline-light:not(:disabled):not(.disabled):active:focus,
.btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-light.dropdown-toggle:focus
{
    box-shadow: 0 0 0 .2rem rgba(248, 249, 250, .5);
}

.btn-outline-dark
{
    color: #343a40;
    border-color: #343a40;
}
.btn-outline-dark:hover
{
    color: #fff;
    border-color: #343a40;
    background-color: #343a40;
}
.btn-outline-dark:focus,
.btn-outline-dark.focus
{
    box-shadow: 0 0 0 .2rem rgba(52, 58, 64, .5);
}
.btn-outline-dark.disabled,
.btn-outline-dark:disabled
{
    color: #343a40;
    background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled):active,
.btn-outline-dark:not(:disabled):not(.disabled).active,
.show > .btn-outline-dark.dropdown-toggle
{
    color: #fff;
    border-color: #343a40;
    background-color: #343a40;
}
.btn-outline-dark:not(:disabled):not(.disabled):active:focus,
.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus
{
    box-shadow: 0 0 0 .2rem rgba(52, 58, 64, .5);
}

.btn-link
{
    font-weight: 400;

    text-decoration: none;

    color: #f5a637;
}
.btn-link:hover
{
    text-decoration: underline;

    color: #f5a637;
}
.btn-link:focus,
.btn-link.focus
{
    text-decoration: underline;

    box-shadow: none;
}
.btn-link:disabled,
.btn-link.disabled
{
    pointer-events: none;

    color: #6c757d;
}

.btn-lg,
.btn-group-lg > .btn
{
    font-size: 1.25rem;
    line-height: 1.5;

    padding: .5rem 1rem;

    border-radius: 0;
}

.btn-sm,
.btn-group-sm > .btn
{
    font-size: .85rem;
    line-height: 1.25;

    padding: .75rem 1.5rem;

    border-radius: 0;
}

.btn-block
{
    display: block;

    width: 100%;
}
.btn-block + .btn-block
{
    margin-top: .5rem;
}

input[type='submit'].btn-block,
input[type='reset'].btn-block,
input[type='button'].btn-block
{
    width: 100%;
}

.fade
{
    transition: opacity .15s linear;
}
@media (prefers-reduced-motion: reduce)
{
    .fade
    {
        transition: none;
    }
}
.fade:not(.show)
{
    opacity: 0;
}

.collapse:not(.show)
{
    display: none;
}

.collapsing
{
    position: relative;

    overflow: hidden;

    height: 0;

    transition: height .35s ease;
}
@media (prefers-reduced-motion: reduce)
{
    .collapsing
    {
        transition: none;
    }
}

.dropup,
.dropright,
.dropdown,
.dropleft
{
    position: relative;
}

.dropdown-toggle
{
    white-space: nowrap;
}
.dropdown-toggle::after
{
    display: inline-block;

    margin-left: .255em;

    content: '';
    vertical-align: .255em;

    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent;
}
.dropdown-toggle:empty::after
{
    margin-left: 0;
}

.dropdown-menu
{
    font-size: 1rem;

    position: absolute;
    z-index: 1000;
    top: 100%;
    left: 0;

    display: none;
    float: left;

    min-width: 10rem;
    margin: .125rem 0 0;
    padding: .5rem 0;

    list-style: none;

    text-align: left;

    color: #777;
    border: 1px solid rgba(0, 0, 0, .15);
    border-radius: 0;
    background-color: #fff;
    background-clip: padding-box;
}

.dropdown-menu-left
{
    right: auto;
    left: 0;
}

.dropdown-menu-right
{
    right: 0;
    left: auto;
}

@media (min-width: 576px)
{
    .dropdown-menu-sm-left
    {
        right: auto;
        left: 0;
    }
    .dropdown-menu-sm-right
    {
        right: 0;
        left: auto;
    }
}

@media (min-width: 768px)
{
    .dropdown-menu-md-left
    {
        right: auto;
        left: 0;
    }
    .dropdown-menu-md-right
    {
        right: 0;
        left: auto;
    }
}

@media (min-width: 992px)
{
    .dropdown-menu-lg-left
    {
        right: auto;
        left: 0;
    }
    .dropdown-menu-lg-right
    {
        right: 0;
        left: auto;
    }
}

@media (min-width: 1200px)
{
    .dropdown-menu-xl-left
    {
        right: auto;
        left: 0;
    }
    .dropdown-menu-xl-right
    {
        right: 0;
        left: auto;
    }
}

.dropup .dropdown-menu
{
    top: auto;
    bottom: 100%;

    margin-top: 0;
    margin-bottom: .125rem;
}

.dropup .dropdown-toggle::after
{
    display: inline-block;

    margin-left: .255em;

    content: '';
    vertical-align: .255em;

    border-top: 0;
    border-right: .3em solid transparent;
    border-bottom: .3em solid;
    border-left: .3em solid transparent;
}

.dropup .dropdown-toggle:empty::after
{
    margin-left: 0;
}

.dropright .dropdown-menu
{
    top: 0;
    right: auto;
    left: 100%;

    margin-top: 0;
    margin-left: .125rem;
}

.dropright .dropdown-toggle::after
{
    display: inline-block;

    margin-left: .255em;

    content: '';
    vertical-align: .255em;

    border-top: .3em solid transparent;
    border-right: 0;
    border-bottom: .3em solid transparent;
    border-left: .3em solid;
}

.dropright .dropdown-toggle:empty::after
{
    margin-left: 0;
}

.dropright .dropdown-toggle::after
{
    vertical-align: 0;
}

.dropleft .dropdown-menu
{
    top: 0;
    right: 100%;
    left: auto;

    margin-top: 0;
    margin-right: .125rem;
}

.dropleft .dropdown-toggle::after
{
    display: inline-block;

    margin-left: .255em;

    content: '';
    vertical-align: .255em;
}

.dropleft .dropdown-toggle::after
{
    display: none;
}

.dropleft .dropdown-toggle::before
{
    display: inline-block;

    margin-right: .255em;

    content: '';
    vertical-align: .255em;

    border-top: .3em solid transparent;
    border-right: .3em solid;
    border-bottom: .3em solid transparent;
}

.dropleft .dropdown-toggle:empty::after
{
    margin-left: 0;
}

.dropleft .dropdown-toggle::before
{
    vertical-align: 0;
}

.dropdown-menu[x-placement^='top'],
.dropdown-menu[x-placement^='right'],
.dropdown-menu[x-placement^='bottom'],
.dropdown-menu[x-placement^='left']
{
    right: auto;
    bottom: auto;
}

.dropdown-divider
{
    overflow: hidden;

    height: 0;
    margin: .5rem 0;

    border-top: 1px solid #e9ecef;
}

.dropdown-item
{
    font-weight: 400;

    display: block;
    clear: both;

    width: 100%;
    padding: .25rem 1.5rem;

    text-align: inherit;
    white-space: nowrap;

    color: #212529;
    border: 0;
    background-color: transparent;
}
.dropdown-item:hover,
.dropdown-item:focus
{
    text-decoration: none;

    color: #16181b;
    background-color: #f8f9fa;
}
.dropdown-item.active,
.dropdown-item:active
{
    text-decoration: none;

    color: #fff;
    background-color: #f5a637;
}
.dropdown-item.disabled,
.dropdown-item:disabled
{
    pointer-events: none;

    color: #6c757d;
    background-color: transparent;
}

.dropdown-menu.show
{
    display: block;
}

.dropdown-header
{
    font-size: .85rem;

    display: block;

    margin-bottom: 0;
    padding: .5rem 1.5rem;

    white-space: nowrap;

    color: #6c757d;
}

.dropdown-item-text
{
    display: block;

    padding: .25rem 1.5rem;

    color: #212529;
}

.btn-group,
.btn-group-vertical
{
    position: relative;

    display: inline-flex;

    vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn
{
    position: relative;

    flex: 1 1 auto;
}
.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover
{
    z-index: 1;
}
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active
{
    z-index: 1;
}

.btn-toolbar
{
    display: flex;

    flex-wrap: wrap;
    justify-content: flex-start;
}
.btn-toolbar .input-group
{
    width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child)
{
    margin-left: -1px;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn
{
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn
{
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.dropdown-toggle-split
{
    padding-right: 1.5rem;
    padding-left: 1.5rem;
}
.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after
{
    margin-left: 0;
}
.dropleft .dropdown-toggle-split::before
{
    margin-right: 0;
}

.btn-sm + .dropdown-toggle-split,
.btn-group-sm > .btn + .dropdown-toggle-split
{
    padding-right: 1.125rem;
    padding-left: 1.125rem;
}

.btn-lg + .dropdown-toggle-split,
.btn-group-lg > .btn + .dropdown-toggle-split
{
    padding-right: .75rem;
    padding-left: .75rem;
}

.btn-group-vertical
{
    flex-direction: column;

    align-items: flex-start;
    justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group
{
    width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child)
{
    margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn
{
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn
{
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn
{
    margin-bottom: 0;
}
.btn-group-toggle > .btn input[type='radio'],
.btn-group-toggle > .btn input[type='checkbox'],
.btn-group-toggle > .btn-group > .btn input[type='radio'],
.btn-group-toggle > .btn-group > .btn input[type='checkbox']
{
    position: absolute;

    clip: rect(0, 0, 0, 0);

    pointer-events: none;
}

.input-group
{
    position: relative;

    display: flex;

    width: 100%;

    flex-wrap: wrap;
    align-items: stretch;
}
.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file
{
    position: relative;

    width: 1%;
    margin-bottom: 0;

    flex: 1 1 auto;
}
.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file
{
    margin-left: -1px;
}
.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label
{
    z-index: 3;
}
.input-group > .custom-file .custom-file-input:focus
{
    z-index: 4;
}
.input-group > .form-control:not(:last-child),
.input-group > .custom-select:not(:last-child)
{
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child)
{
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.input-group > .custom-file
{
    display: flex;

    align-items: center;
}
.input-group > .custom-file:not(:last-child) .custom-file-label,
.input-group > .custom-file:not(:last-child) .custom-file-label::after
{
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.input-group > .custom-file:not(:first-child) .custom-file-label
{
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.input-group-prepend,
.input-group-append
{
    display: flex;
}
.input-group-prepend .btn,
.input-group-append .btn
{
    position: relative;
    z-index: 2;
}
.input-group-prepend .btn:focus,
.input-group-append .btn:focus
{
    z-index: 3;
}
.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn
{
    margin-left: -1px;
}

.input-group-prepend
{
    margin-right: -1px;
}

.input-group-append
{
    margin-left: -1px;
}

.input-group-text
{
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.25;

    display: flex;

    margin-bottom: 0;
    padding: 1rem 2rem;

    text-align: center;
    white-space: nowrap;

    color: #495057;
    border: 1px solid #f0f0f0;
    border-radius: 0;
    background-color: #e9ecef;

    align-items: center;
}
.input-group-text input[type='radio'],
.input-group-text input[type='checkbox']
{
    margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select
{
    height: calc(1.5em + 1rem + 2px);
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn
{
    font-size: 1.25rem;
    line-height: 1.5;

    padding: .5rem 1rem;

    border-radius: 0;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select
{
    height: calc(1.25em + 1.5rem + 2px);
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn
{
    font-size: .85rem;
    line-height: 1.25;

    padding: .75rem 1.5rem;

    border-radius: 0;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select
{
    padding-right: 3rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child)
{
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child)
{
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.custom-control
{
    position: relative;

    display: block;

    min-height: 1.7rem;
    padding-left: 1.5rem;
}

.custom-control-inline
{
    display: inline-flex;

    margin-right: 1rem;
}

.custom-control-input
{
    position: absolute;
    z-index: -1;

    opacity: 0;
}
.custom-control-input:checked ~ .custom-control-label::before
{
    color: #fff;
    border-color: #f5a637;
    background-color: #f5a637;
}
.custom-control-input:focus ~ .custom-control-label::before
{
    box-shadow: none;
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before
{
    border-color: #f5a637;
}
.custom-control-input:not(:disabled):active ~ .custom-control-label::before
{
    color: #fff;
    border-color: #fef2e1;
    background-color: #fef2e1;
}
.custom-control-input:disabled ~ .custom-control-label
{
    color: #6c757d;
}
.custom-control-input:disabled ~ .custom-control-label::before
{
    background-color: #e9ecef;
}

.custom-control-label
{
    position: relative;

    margin-bottom: 0;

    vertical-align: top;
}
.custom-control-label::before
{
    position: absolute;
    top: .35rem;
    left: -1.5rem;

    display: block;

    width: 1rem;
    height: 1rem;

    content: '';
    pointer-events: none;

    border: #adb5bd solid 1px;
    background-color: #fff;
}
.custom-control-label::after
{
    position: absolute;
    top: .35rem;
    left: -1.5rem;

    display: block;

    width: 1rem;
    height: 1rem;

    content: '';

    background: no-repeat 50% / 50% 50%;
}

.custom-checkbox .custom-control-label::before
{
    border-radius: 0;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after
{
    background-image: url('data:image/svg xml,<svg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 8 8\'><path fill=\'#fff\' d=\'M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z\'/></svg>');
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before
{
    border-color: #f5a637;
    background-color: #f5a637;
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after
{
    background-image: url('data:image/svg xml,<svg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 4 4\'><path stroke=\'#fff\' d=\'M0 2h4\'/></svg>');
}

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before
{
    background-color: rgba(245, 166, 55, .5);
}

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before
{
    background-color: rgba(245, 166, 55, .5);
}

.custom-radio .custom-control-label::before
{
    border-radius: 50%;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after
{
    background-image: url('data:image/svg xml,<svg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'-4 -4 8 8\'><circle r=\'3\' fill=\'#fff\'/></svg>');
}

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before
{
    background-color: rgba(245, 166, 55, .5);
}

.custom-switch
{
    padding-left: 2.25rem;
}
.custom-switch .custom-control-label::before
{
    left: -2.25rem;

    width: 1.75rem;

    pointer-events: all;

    border-radius: .5rem;
}
.custom-switch .custom-control-label::after
{
    top: calc(.35rem + 2px);
    left: calc(-2.25rem + 2px);

    width: calc(1rem - 4px);
    height: calc(1rem - 4px);

    transition: transform .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;

    border-radius: .5rem;
    background-color: #adb5bd;
}
@media (prefers-reduced-motion: reduce)
{
    .custom-switch .custom-control-label::after
    {
        transition: none;
    }
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after
{
    transform: translateX(.75rem);

    background-color: #fff;
}
.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before
{
    background-color: rgba(245, 166, 55, .5);
}

.custom-select
{
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.25;

    display: inline-block;

    width: 100%;
    height: calc(1.25em + 2rem + 2px);
    padding: 1rem 3rem 1rem 2rem;

    vertical-align: middle;

    color: #495057;
    border: 1px solid #f0f0f0;
    border-radius: 0;
    background: url('data:image/svg xml,<svg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 4 5\'><path fill=\'#343a40\' d=\'M2 0L0 2h4zm0 5L0 3h4z\'/></svg>') no-repeat right 2rem center/8px 10px;
    background-color: #fff;

    -webkit-appearance: none;
            -moz-appearance: none;
         appearance: none;
}
.custom-select:focus
{
    border-color: #f5a637;
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(245, 166, 55, .25);
}
.custom-select:focus::-ms-value
{
    color: #495057;
    background-color: #fff;
}
.custom-select[multiple],
.custom-select[size]:not([size='1'])
{
    height: auto;
    padding-right: 2rem;

    background-image: none;
}
.custom-select:disabled
{
    color: #6c757d;
    background-color: #e9ecef;
}
.custom-select::-ms-expand
{
    display: none;
}

.custom-select-sm
{
    font-size: .85rem;

    height: calc(1.25em + 1.5rem + 2px);
    padding-top: .75rem;
    padding-bottom: .75rem;
    padding-left: 1.5rem;
}

.custom-select-lg
{
    font-size: 1.25rem;

    height: calc(1.5em + 1rem + 2px);
    padding-top: .5rem;
    padding-bottom: .5rem;
    padding-left: 1rem;
}

.custom-file
{
    position: relative;

    display: inline-block;

    width: 100%;
    height: calc(1.25em + 2rem + 2px);
    margin-bottom: 0;
}

.custom-file-input
{
    position: relative;
    z-index: 2;

    width: 100%;
    height: calc(1.25em + 2rem + 2px);
    margin: 0;

    opacity: 0;
}
.custom-file-input:focus ~ .custom-file-label
{
    border-color: #f5a637;
    box-shadow: none;
}
.custom-file-input:disabled ~ .custom-file-label
{
    background-color: #e9ecef;
}
.custom-file-input:lang(en) ~ .custom-file-label::after
{
    content: 'Browse';
}
.custom-file-input ~ .custom-file-label[data-browse]::after
{
    content: attr(data-browse);
}

.custom-file-label
{
    font-weight: 400;
    line-height: 1.25;

    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    left: 0;

    height: calc(1.25em + 2rem + 2px);
    padding: 1rem 2rem;

    color: #495057;
    border: 1px solid #f0f0f0;
    border-radius: 0;
    background-color: #fff;
}
.custom-file-label::after
{
    line-height: 1.25;

    position: absolute;
    z-index: 3;
    top: 0;
    right: 0;
    bottom: 0;

    display: block;

    height: calc(1.25em + 2rem);
    padding: 1rem 2rem;

    content: 'Browse';

    color: #495057;
    border-left: inherit;
    border-radius: 0 0 0 0;
    background-color: #e9ecef;
}

.custom-range
{
    width: 100%;
    height: calc(1rem + .4rem);
    padding: 0;

    background-color: transparent;

    -webkit-appearance: none;
            -moz-appearance: none;
         appearance: none;
}
.custom-range:focus
{
    outline: none;
}
.custom-range:focus::-webkit-slider-thumb
{
    box-shadow: 0 0 0 1px #fff, none;
}
.custom-range:focus::-moz-range-thumb
{
    box-shadow: 0 0 0 1px #fff, none;
}
.custom-range:focus::-ms-thumb
{
    box-shadow: 0 0 0 1px #fff, none;
}
.custom-range::-moz-focus-outer
{
    border: 0;
}
.custom-range::-webkit-slider-thumb
{
    width: 1rem;
    height: 1rem;
    margin-top: -.25rem;
    -webkit-transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;

    border: 0;
    border-radius: 1rem;
    background-color: #f5a637;

    -webkit-appearance: none;
            appearance: none;
}
@media (prefers-reduced-motion: reduce)
{
    .custom-range::-webkit-slider-thumb
    {
        -webkit-transition: none;
        transition: none;
    }
}
.custom-range::-webkit-slider-thumb:active
{
    background-color: #fef2e1;
}
.custom-range::-webkit-slider-runnable-track
{
    width: 100%;
    height: .5rem;

    cursor: pointer;

    color: transparent;
    border-color: transparent;
    border-radius: 1rem;
    background-color: #dee2e6;
}
.custom-range::-moz-range-thumb
{
    width: 1rem;
    height: 1rem;
    -moz-transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;

    border: 0;
    border-radius: 1rem;
    background-color: #f5a637;

    -moz-appearance: none;

         appearance: none;
}
@media (prefers-reduced-motion: reduce)
{
    .custom-range::-moz-range-thumb
    {
        -moz-transition: none;
        transition: none;
    }
}
.custom-range::-moz-range-thumb:active
{
    background-color: #fef2e1;
}
.custom-range::-moz-range-track
{
    width: 100%;
    height: .5rem;

    cursor: pointer;

    color: transparent;
    border-color: transparent;
    border-radius: 1rem;
    background-color: #dee2e6;
}
.custom-range::-ms-thumb
{
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: .2rem;
    margin-left: .2rem;
    -ms-transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;

    border: 0;
    border-radius: 1rem;
    background-color: #f5a637;

    appearance: none;
}
@media (prefers-reduced-motion: reduce)
{
    .custom-range::-ms-thumb
    {
        -ms-transition: none;
        transition: none;
    }
}
.custom-range::-ms-thumb:active
{
    background-color: #fef2e1;
}
.custom-range::-ms-track
{
    width: 100%;
    height: .5rem;

    cursor: pointer;

    color: transparent;
    border-width: .5rem;
    border-color: transparent;
    background-color: transparent;
}
.custom-range::-ms-fill-lower
{
    border-radius: 1rem;
    background-color: #dee2e6;
}
.custom-range::-ms-fill-upper
{
    margin-right: 15px;

    border-radius: 1rem;
    background-color: #dee2e6;
}
.custom-range:disabled::-webkit-slider-thumb
{
    background-color: #adb5bd;
}
.custom-range:disabled::-webkit-slider-runnable-track
{
    cursor: default;
}
.custom-range:disabled::-moz-range-thumb
{
    background-color: #adb5bd;
}
.custom-range:disabled::-moz-range-track
{
    cursor: default;
}
.custom-range:disabled::-ms-thumb
{
    background-color: #adb5bd;
}

.custom-control-label::before,
.custom-file-label,
.custom-select
{
    transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}
@media (prefers-reduced-motion: reduce)
{
    .custom-control-label::before,
    .custom-file-label,
    .custom-select
    {
        transition: none;
    }
}

.nav
{
    display: flex;

    margin-bottom: 0;
    padding-left: 0;

    list-style: none;

    flex-wrap: wrap;
}

.nav-link
{
    display: block;

    padding: .5rem 1rem;
}
.nav-link:hover,
.nav-link:focus
{
    text-decoration: none;
}
.nav-link.disabled
{
    cursor: default;
    pointer-events: none;

    color: #6c757d;
}

.nav-tabs
{
    border-bottom: 1px solid #dee2e6;
}
.nav-tabs .nav-item
{
    margin-bottom: -1px;
}
.nav-tabs .nav-link
{
    border: 1px solid transparent;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus
{
    border-color: #e9ecef #e9ecef #dee2e6;
}
.nav-tabs .nav-link.disabled
{
    color: #6c757d;
    border-color: transparent;
    background-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link
{
    color: #495057;
    border-color: #dee2e6 #dee2e6 #fff;
    background-color: #fff;
}
.nav-tabs .dropdown-menu
{
    margin-top: -1px;

    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.nav-pills .nav-link
{
    border-radius: 0;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link
{
    color: #fff;
    background-color: #f5a637;
}

.nav-fill .nav-item
{
    text-align: center;

    flex: 1 1 auto;
}

.nav-justified .nav-item
{
    text-align: center;

    flex-basis: 0;
    flex-grow: 1;
}

.tab-content > .tab-pane
{
    display: none;
}

.tab-content > .active
{
    display: block;
}

.navbar
{
    position: relative;

    display: flex;

    padding: .5rem 1rem;

    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}
.navbar > .container,
.navbar > .container-fluid
{
    display: flex;

    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}

.navbar-brand
{
    font-size: 1.25rem;
    line-height: inherit;

    display: inline-block;

    margin-right: 1rem;
    padding-top: .2875rem;
    padding-bottom: .2875rem;

    white-space: nowrap;
}
.navbar-brand:hover,
.navbar-brand:focus
{
    text-decoration: none;
}

.navbar-nav
{
    display: flex;
    flex-direction: column;

    margin-bottom: 0;
    padding-left: 0;

    list-style: none;
}
.navbar-nav .nav-link
{
    padding-right: 0;
    padding-left: 0;
}
.navbar-nav .dropdown-menu
{
    position: static;

    float: none;
}

.navbar-text
{
    display: inline-block;

    padding-top: .5rem;
    padding-bottom: .5rem;
}

.navbar-collapse
{
    flex-basis: 100%;
    flex-grow: 1;
    align-items: center;
}

.navbar-toggler
{
    font-size: 1.25rem;
    line-height: 1;

    padding: .25rem .75rem;

    border: 1px solid transparent;
    border-radius: 0;
    background-color: transparent;
}
.navbar-toggler:hover,
.navbar-toggler:focus
{
    text-decoration: none;
}

.navbar-toggler-icon
{
    display: inline-block;

    width: 1.5em;
    height: 1.5em;

    content: '';
    vertical-align: middle;

    background: no-repeat center center;
    background-size: 100% 100%;
}

@media (max-width: 575.98px)
{
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid
    {
        padding-right: 0;
        padding-left: 0;
    }
}

@media (min-width: 576px)
{
    .navbar-expand-sm
    {
        flex-flow: row nowrap;
        justify-content: flex-start;
    }
    .navbar-expand-sm .navbar-nav
    {
        flex-direction: row;
    }
    .navbar-expand-sm .navbar-nav .dropdown-menu
    {
        position: absolute;
    }
    .navbar-expand-sm .navbar-nav .nav-link
    {
        padding-right: .5rem;
        padding-left: .5rem;
    }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid
    {
        flex-wrap: nowrap;
    }
    .navbar-expand-sm .navbar-collapse
    {
        display: flex !important;

        flex-basis: auto;
    }
    .navbar-expand-sm .navbar-toggler
    {
        display: none;
    }
}

@media (max-width: 767.98px)
{
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid
    {
        padding-right: 0;
        padding-left: 0;
    }
}

@media (min-width: 768px)
{
    .navbar-expand-md
    {
        flex-flow: row nowrap;
        justify-content: flex-start;
    }
    .navbar-expand-md .navbar-nav
    {
        flex-direction: row;
    }
    .navbar-expand-md .navbar-nav .dropdown-menu
    {
        position: absolute;
    }
    .navbar-expand-md .navbar-nav .nav-link
    {
        padding-right: .5rem;
        padding-left: .5rem;
    }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid
    {
        flex-wrap: nowrap;
    }
    .navbar-expand-md .navbar-collapse
    {
        display: flex !important;

        flex-basis: auto;
    }
    .navbar-expand-md .navbar-toggler
    {
        display: none;
    }
}

@media (max-width: 991.98px)
{
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid
    {
        padding-right: 0;
        padding-left: 0;
    }
}

@media (min-width: 992px)
{
    .navbar-expand-lg
    {
        flex-flow: row nowrap;
        justify-content: flex-start;
    }
    .navbar-expand-lg .navbar-nav
    {
        flex-direction: row;
    }
    .navbar-expand-lg .navbar-nav .dropdown-menu
    {
        position: absolute;
    }
    .navbar-expand-lg .navbar-nav .nav-link
    {
        padding-right: .5rem;
        padding-left: .5rem;
    }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid
    {
        flex-wrap: nowrap;
    }
    .navbar-expand-lg .navbar-collapse
    {
        display: flex !important;

        flex-basis: auto;
    }
    .navbar-expand-lg .navbar-toggler
    {
        display: none;
    }
}

@media (max-width: 1199.98px)
{
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid
    {
        padding-right: 0;
        padding-left: 0;
    }
}

@media (min-width: 1200px)
{
    .navbar-expand-xl
    {
        flex-flow: row nowrap;
        justify-content: flex-start;
    }
    .navbar-expand-xl .navbar-nav
    {
        flex-direction: row;
    }
    .navbar-expand-xl .navbar-nav .dropdown-menu
    {
        position: absolute;
    }
    .navbar-expand-xl .navbar-nav .nav-link
    {
        padding-right: .5rem;
        padding-left: .5rem;
    }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid
    {
        flex-wrap: nowrap;
    }
    .navbar-expand-xl .navbar-collapse
    {
        display: flex !important;

        flex-basis: auto;
    }
    .navbar-expand-xl .navbar-toggler
    {
        display: none;
    }
}

.navbar-expand
{
    flex-flow: row nowrap;
    justify-content: flex-start;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid
{
    padding-right: 0;
    padding-left: 0;
}
.navbar-expand .navbar-nav
{
    flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu
{
    position: absolute;
}
.navbar-expand .navbar-nav .nav-link
{
    padding-right: .5rem;
    padding-left: .5rem;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid
{
    flex-wrap: nowrap;
}
.navbar-expand .navbar-collapse
{
    display: flex !important;

    flex-basis: auto;
}
.navbar-expand .navbar-toggler
{
    display: none;
}

.navbar-light .navbar-brand
{
    color: #f5a637;
}
.navbar-light .navbar-brand:hover,
.navbar-light .navbar-brand:focus
{
    color: #f5a637;
}

.navbar-light .navbar-nav .nav-link
{
    color: white;
}
.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus
{
    color: #f5a637;
}
.navbar-light .navbar-nav .nav-link.disabled
{
    color: rgba(0, 0, 0, .3);
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active
{
    color: #f5a637;
}

.navbar-light .navbar-toggler
{
    color: white;
    border-color: transparent;
}

.navbar-light .navbar-toggler-icon
{
    background-image: url('data:image/svg xml,<svg viewBox=\'0 0 30 30\' xmlns=\'http://www.w3.org/2000/svg\'><path stroke=\'white\' stroke-width=\'2\' stroke-linecap=\'round\' stroke-miterlimit=\'10\' d=\'M4 7h22M4 15h22M4 23h22\'/></svg>');
}

.navbar-light .navbar-text
{
    color: white;
}
.navbar-light .navbar-text a
{
    color: #f5a637;
}
.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus
{
    color: #f5a637;
}

.navbar-dark .navbar-brand
{
    color: #f5a637;
}
.navbar-dark .navbar-brand:hover,
.navbar-dark .navbar-brand:focus
{
    color: #f5a637;
}

.navbar-dark .navbar-nav .nav-link
{
    color: #151515;
}
.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link:focus
{
    color: #f5a637;
}
.navbar-dark .navbar-nav .nav-link.disabled
{
    color: rgba(255, 255, 255, .25);
}

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active
{
    color: #f5a637;
}

.navbar-dark .navbar-toggler
{
    color: #151515;
    border-color: transparent;
}
.navbar-dark .navbar-text
{
    color: #151515;
}
.navbar-dark .navbar-text a
{
    color: #f5a637;
}
.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus
{
    color: #f5a637;
}

.card
{
    position: relative;

    display: flex;
    flex-direction: column;

    min-width: 0;

    word-wrap: break-word;

    border: 1px solid rgba(0, 0, 0, .125);
    border-radius: 0;
    background-color: #fff;
    background-clip: border-box;
}
.card > hr
{
    margin-right: 0;
    margin-left: 0;
}
.card > .list-group:first-child .list-group-item:first-child
{
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
.card > .list-group:last-child .list-group-item:last-child
{
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.card-body
{
    padding: 1.25rem;

    flex: 1 1 auto;
}

.card-title
{
    margin-bottom: .75rem;
}

.card-subtitle
{
    margin-top: -.375rem;
    margin-bottom: 0;
}

.card-text:last-child
{
    margin-bottom: 0;
}

.card-link:hover
{
    text-decoration: none;
}

.card-link + .card-link
{
    margin-left: 1.25rem;
}

.card-header
{
    margin-bottom: 0;
    padding: .75rem 1.25rem;

    border-bottom: 1px solid rgba(0, 0, 0, .125);
    background-color: rgba(0, 0, 0, .03);
}
.card-header:first-child
{
    border-radius: calc(0 - 1px) calc(0 - 1px) 0 0;
}
.card-header + .list-group .list-group-item:first-child
{
    border-top: 0;
}

.card-footer
{
    padding: .75rem 1.25rem;

    border-top: 1px solid rgba(0, 0, 0, .125);
    background-color: rgba(0, 0, 0, .03);
}
.card-footer:last-child
{
    border-radius: 0 0 calc(0 - 1px) calc(0 - 1px);
}

.card-header-tabs
{
    margin-right: -.625rem;
    margin-bottom: -.75rem;
    margin-left: -.625rem;

    border-bottom: 0;
}

.card-header-pills
{
    margin-right: -.625rem;
    margin-left: -.625rem;
}

.card-img-overlay
{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    padding: 1.25rem;
}

.card-img
{
    width: 100%;

    border-radius: calc(0 - 1px);
}

.card-img-top
{
    width: 100%;

    border-top-left-radius: calc(0 - 1px);
    border-top-right-radius: calc(0 - 1px);
}

.card-img-bottom
{
    width: 100%;

    border-bottom-right-radius: calc(0 - 1px);
    border-bottom-left-radius: calc(0 - 1px);
}

.card-deck
{
    display: flex;
    flex-direction: column;
}
.card-deck .card
{
    margin-bottom: 15px;
}
@media (min-width: 576px)
{
    .card-deck
    {
        margin-right: -15px;
        margin-left: -15px;

        flex-flow: row wrap;
    }
    .card-deck .card
    {
        display: flex;
        flex-direction: column;

        margin-right: 15px;
        margin-bottom: 0;
        margin-left: 15px;

        flex: 1 0;
    }
}

.card-group
{
    display: flex;
    flex-direction: column;
}
.card-group > .card
{
    margin-bottom: 15px;
}
@media (min-width: 576px)
{
    .card-group
    {
        flex-flow: row wrap;
    }
    .card-group > .card
    {
        margin-bottom: 0;

        flex: 1 0;
    }
    .card-group > .card + .card
    {
        margin-left: 0;

        border-left: 0;
    }
    .card-group > .card:not(:last-child)
    {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
    .card-group > .card:not(:last-child) .card-img-top,
    .card-group > .card:not(:last-child) .card-header
    {
        border-top-right-radius: 0;
    }
    .card-group > .card:not(:last-child) .card-img-bottom,
    .card-group > .card:not(:last-child) .card-footer
    {
        border-bottom-right-radius: 0;
    }
    .card-group > .card:not(:first-child)
    {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
    .card-group > .card:not(:first-child) .card-img-top,
    .card-group > .card:not(:first-child) .card-header
    {
        border-top-left-radius: 0;
    }
    .card-group > .card:not(:first-child) .card-img-bottom,
    .card-group > .card:not(:first-child) .card-footer
    {
        border-bottom-left-radius: 0;
    }
}

.card-columns .card
{
    margin-bottom: .75rem;
}

@media (min-width: 576px)
{
    .card-columns
    {
        -webkit-column-count: 3;
                column-count: 3;
        grid-column-gap: 1.25rem;
        -webkit-column-gap: 1.25rem;
                column-gap: 1.25rem;
        orphans: 1;
        widows: 1;
    }
    .card-columns .card
    {
        display: inline-block;

        width: 100%;
    }
}

.accordion > .card
{
    overflow: hidden;
}
.accordion > .card:not(:first-of-type) .card-header:first-child
{
    border-radius: 0;
}
.accordion > .card:not(:first-of-type):not(:last-of-type)
{
    border-bottom: 0;
    border-radius: 0;
}
.accordion > .card:first-of-type
{
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}
.accordion > .card:last-of-type
{
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
.accordion > .card .card-header
{
    margin-bottom: -1px;
}

.breadcrumb
{
    display: flex;

    margin-bottom: 1rem;
    padding: .75rem 1rem;

    list-style: none;

    border-radius: 0;
    background-color: #e9ecef;

    flex-wrap: wrap;
}

.breadcrumb-item + .breadcrumb-item
{
    padding-left: .5rem;
}
.breadcrumb-item + .breadcrumb-item::before
{
    display: inline-block;

    padding-right: .5rem;

    content: '/';

    color: #6c757d;
}

.breadcrumb-item + .breadcrumb-item:hover::before
{
    text-decoration: underline;
}

.breadcrumb-item + .breadcrumb-item:hover::before
{
    text-decoration: none;
}

.breadcrumb-item.active
{
    color: #6c757d;
}

.pagination
{
    display: flex;

    padding-left: 0;

    list-style: none;

    border-radius: 0;
}

.page-link
{
    line-height: 1.25;

    position: relative;

    display: block;

    margin-left: -1px;
    padding: .5rem .75rem;

    color: #f5a637;
    border: 1px solid #dee2e6;
    background-color: #fff;
}
.page-link:hover
{
    z-index: 2;

    text-decoration: none;

    color: #f5a637;
    border-color: #dee2e6;
    background-color: #e9ecef;
}
.page-link:focus
{
    z-index: 2;

    outline: 0;
    box-shadow: none;
}

.page-item:first-child .page-link
{
    margin-left: 0;

    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.page-item:last-child .page-link
{
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.page-item.active .page-link
{
    z-index: 1;

    color: #fff;
    border-color: #f5a637;
    background-color: #f5a637;
}

.page-item.disabled .page-link
{
    cursor: auto;
    pointer-events: none;

    color: #6c757d;
    border-color: #dee2e6;
    background-color: #fff;
}

.pagination-lg .page-link
{
    font-size: 1.25rem;
    line-height: 1.5;

    padding: .75rem 1.5rem;
}

.pagination-lg .page-item:first-child .page-link
{
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.pagination-lg .page-item:last-child .page-link
{
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.pagination-sm .page-link
{
    font-size: .85rem;
    line-height: 1.5;

    padding: .25rem .5rem;
}

.pagination-sm .page-item:first-child .page-link
{
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.pagination-sm .page-item:last-child .page-link
{
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.badge
{
    font-size: 75%;
    font-weight: 700;
    line-height: 1;

    display: inline-block;

    padding: .25em .4em;

    transition: color .1s;
    text-align: center;
    vertical-align: baseline;
    white-space: nowrap;

    border-radius: 0;
}
@media (prefers-reduced-motion: reduce)
{
    .badge
    {
        transition: none;
    }
}
a.badge:hover,
a.badge:focus
{
    text-decoration: none;
}
.badge:empty
{
    display: none;
}

.btn .badge
{
    position: relative;
    top: -1px;
}

.badge-pill
{
    padding-right: .6em;
    padding-left: .6em;

    border-radius: 10rem;
}

.badge-primary
{
    color: #212529;
    background-color: #f5a637;
}
a.badge-primary:hover,
a.badge-primary:focus
{
    color: #212529;
    background-color: #ed8f0c;
}
a.badge-primary:focus,
a.badge-primary.focus
{
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(245, 166, 55, .5);
}

.badge-secondary
{
    color: #fff;
    background-color: #6c757d;
}
a.badge-secondary:hover,
a.badge-secondary:focus
{
    color: #fff;
    background-color: #545b62;
}
a.badge-secondary:focus,
a.badge-secondary.focus
{
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(108, 117, 125, .5);
}

.badge-success
{
    color: #fff;
    background-color: #28a745;
}
a.badge-success:hover,
a.badge-success:focus
{
    color: #fff;
    background-color: #1e7e34;
}
a.badge-success:focus,
a.badge-success.focus
{
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(40, 167, 69, .5);
}

.badge-info
{
    color: #fff;
    background-color: #17a2b8;
}
a.badge-info:hover,
a.badge-info:focus
{
    color: #fff;
    background-color: #117a8b;
}
a.badge-info:focus,
a.badge-info.focus
{
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(23, 162, 184, .5);
}

.badge-warning
{
    color: #212529;
    background-color: #ffc107;
}
a.badge-warning:hover,
a.badge-warning:focus
{
    color: #212529;
    background-color: #d39e00;
}
a.badge-warning:focus,
a.badge-warning.focus
{
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(255, 193, 7, .5);
}

.badge-danger
{
    color: #fff;
    background-color: #dc3545;
}
a.badge-danger:hover,
a.badge-danger:focus
{
    color: #fff;
    background-color: #bd2130;
}
a.badge-danger:focus,
a.badge-danger.focus
{
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(220, 53, 69, .5);
}

.badge-light
{
    color: #212529;
    background-color: #f8f9fa;
}
a.badge-light:hover,
a.badge-light:focus
{
    color: #212529;
    background-color: #dae0e5;
}
a.badge-light:focus,
a.badge-light.focus
{
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(248, 249, 250, .5);
}

.badge-dark
{
    color: #fff;
    background-color: #343a40;
}
a.badge-dark:hover,
a.badge-dark:focus
{
    color: #fff;
    background-color: #1d2124;
}
a.badge-dark:focus,
a.badge-dark.focus
{
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(52, 58, 64, .5);
}

.jumbotron
{
    margin-bottom: 2rem;
    padding: 2rem 1rem;

    border-radius: 0;
    background-color: #e9ecef;
}
@media (min-width: 576px)
{
    .jumbotron
    {
        padding: 4rem 2rem;
    }
}

.jumbotron-fluid
{
    padding-right: 0;
    padding-left: 0;

    border-radius: 0;
}

.alert
{
    position: relative;

    margin-bottom: 1rem;
    padding: 1.5rem 1.25rem;

    border: 1px solid transparent;
    border-radius: 0;
}

.alert-heading
{
    color: inherit;
}

.alert-link
{
    font-weight: 700;
}

.alert-dismissible
{
    padding-right: 4rem;
}
.alert-dismissible .close
{
    position: absolute;
    top: 0;
    right: 0;

    padding: 1.5rem 1.25rem;

    color: inherit;
}

.alert-primary
{
    color: #7f561d;
    border-color: #fce6c7;
    background-color: #fdedd7;
}
.alert-primary hr
{
    border-top-color: #fbdbaf;
}
.alert-primary .alert-link
{
    color: #553a14;
}

.alert-secondary
{
    color: #383d41;
    border-color: #d6d8db;
    background-color: #e2e3e5;
}
.alert-secondary hr
{
    border-top-color: #c8cbcf;
}
.alert-secondary .alert-link
{
    color: #202326;
}

.alert-success
{
    color: #155724;
    border-color: #c3e6cb;
    background-color: #d4edda;
}
.alert-success hr
{
    border-top-color: #b1dfbb;
}
.alert-success .alert-link
{
    color: #0b2e13;
}

.alert-info
{
    color: #0c5460;
    border-color: #bee5eb;
    background-color: #d1ecf1;
}
.alert-info hr
{
    border-top-color: #abdde5;
}
.alert-info .alert-link
{
    color: #062c33;
}

.alert-warning
{
    color: #856404;
    border-color: #ffeeba;
    background-color: #fff3cd;
}
.alert-warning hr
{
    border-top-color: #ffe8a1;
}
.alert-warning .alert-link
{
    color: #533f03;
}

.alert-danger
{
    color: #721c24;
    border-color: #f5c6cb;
    background-color: #f8d7da;
}
.alert-danger hr
{
    border-top-color: #f1b0b7;
}
.alert-danger .alert-link
{
    color: #491217;
}

.alert-light
{
    color: #818182;
    border-color: #fdfdfe;
    background-color: #fefefe;
}
.alert-light hr
{
    border-top-color: #ececf6;
}
.alert-light .alert-link
{
    color: #686868;
}

.alert-dark
{
    color: #1b1e21;
    border-color: #c6c8ca;
    background-color: #d6d8d9;
}
.alert-dark hr
{
    border-top-color: #b9bbbe;
}
.alert-dark .alert-link
{
    color: #040505;
}

@-webkit-keyframes progress-bar-stripes
{
    from
    {
        background-position: 1rem 0;
    }
    to
    {
        background-position: 0 0;
    }
}

@keyframes progress-bar-stripes
{
    from
    {
        background-position: 1rem 0;
    }
    to
    {
        background-position: 0 0;
    }
}

.progress
{
    font-size: .75rem;

    display: flex;
    overflow: hidden;

    height: 1rem;

    border-radius: 0;
    background-color: #e9ecef;
}

.progress-bar
{
    display: flex;
    flex-direction: column;

    transition: width .6s ease;
    text-align: center;
    white-space: nowrap;

    color: #fff;
    background-color: #f5a637;

    justify-content: center;
}
@media (prefers-reduced-motion: reduce)
{
    .progress-bar
    {
        transition: none;
    }
}

.progress-bar-striped
{
    background-image: linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
    background-size: 1rem 1rem;
}

.progress-bar-animated
{
    -webkit-animation: progress-bar-stripes 1s linear infinite;
            animation: progress-bar-stripes 1s linear infinite;
}
@media (prefers-reduced-motion: reduce)
{
    .progress-bar-animated
    {
        -webkit-animation: none;
                animation: none;
    }
}

.media
{
    display: flex;

    align-items: flex-start;
}

.media-body
{
    flex: 1 1;
}

.list-group
{
    display: flex;
    flex-direction: column;

    margin-bottom: 0;
    padding-left: 0;
}

.list-group-item-action
{
    width: 100%;

    text-align: inherit;

    color: #495057;
}
.list-group-item-action:hover,
.list-group-item-action:focus
{
    z-index: 1;

    text-decoration: none;

    color: #495057;
    background-color: #f8f9fa;
}
.list-group-item-action:active
{
    color: #777;
    background-color: #e9ecef;
}

.list-group-item
{
    position: relative;

    display: block;

    margin-bottom: -1px;
    padding: .75rem 1.25rem;

    border: 1px solid rgba(0, 0, 0, .125);
    background-color: #fff;
}
.list-group-item:first-child
{
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
.list-group-item:last-child
{
    margin-bottom: 0;

    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}
.list-group-item.disabled,
.list-group-item:disabled
{
    pointer-events: none;

    color: #6c757d;
    background-color: #fff;
}
.list-group-item.active
{
    z-index: 2;

    color: #fff;
    border-color: #f5a637;
    background-color: #f5a637;
}

.list-group-horizontal
{
    flex-direction: row;
}
.list-group-horizontal .list-group-item
{
    margin-right: -1px;
    margin-bottom: 0;
}
.list-group-horizontal .list-group-item:first-child
{
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
}
.list-group-horizontal .list-group-item:last-child
{
    margin-right: 0;

    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

@media (min-width: 576px)
{
    .list-group-horizontal-sm
    {
        flex-direction: row;
    }
    .list-group-horizontal-sm .list-group-item
    {
        margin-right: -1px;
        margin-bottom: 0;
    }
    .list-group-horizontal-sm .list-group-item:first-child
    {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-left-radius: 0;
    }
    .list-group-horizontal-sm .list-group-item:last-child
    {
        margin-right: 0;

        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
    }
}

@media (min-width: 768px)
{
    .list-group-horizontal-md
    {
        flex-direction: row;
    }
    .list-group-horizontal-md .list-group-item
    {
        margin-right: -1px;
        margin-bottom: 0;
    }
    .list-group-horizontal-md .list-group-item:first-child
    {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-left-radius: 0;
    }
    .list-group-horizontal-md .list-group-item:last-child
    {
        margin-right: 0;

        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
    }
}

@media (min-width: 992px)
{
    .list-group-horizontal-lg
    {
        flex-direction: row;
    }
    .list-group-horizontal-lg .list-group-item
    {
        margin-right: -1px;
        margin-bottom: 0;
    }
    .list-group-horizontal-lg .list-group-item:first-child
    {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-left-radius: 0;
    }
    .list-group-horizontal-lg .list-group-item:last-child
    {
        margin-right: 0;

        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
    }
}

@media (min-width: 1200px)
{
    .list-group-horizontal-xl
    {
        flex-direction: row;
    }
    .list-group-horizontal-xl .list-group-item
    {
        margin-right: -1px;
        margin-bottom: 0;
    }
    .list-group-horizontal-xl .list-group-item:first-child
    {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-left-radius: 0;
    }
    .list-group-horizontal-xl .list-group-item:last-child
    {
        margin-right: 0;

        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
    }
}

.list-group-flush .list-group-item
{
    border-right: 0;
    border-left: 0;
    border-radius: 0;
}
.list-group-flush .list-group-item:last-child
{
    margin-bottom: -1px;
}

.list-group-flush:first-child .list-group-item:first-child
{
    border-top: 0;
}

.list-group-flush:last-child .list-group-item:last-child
{
    margin-bottom: 0;

    border-bottom: 0;
}

.list-group-item-primary
{
    color: #7f561d;
    background-color: #fce6c7;
}
.list-group-item-primary.list-group-item-action:hover,
.list-group-item-primary.list-group-item-action:focus
{
    color: #7f561d;
    background-color: #fbdbaf;
}
.list-group-item-primary.list-group-item-action.active
{
    color: #fff;
    border-color: #7f561d;
    background-color: #7f561d;
}

.list-group-item-secondary
{
    color: #383d41;
    background-color: #d6d8db;
}
.list-group-item-secondary.list-group-item-action:hover,
.list-group-item-secondary.list-group-item-action:focus
{
    color: #383d41;
    background-color: #c8cbcf;
}
.list-group-item-secondary.list-group-item-action.active
{
    color: #fff;
    border-color: #383d41;
    background-color: #383d41;
}

.list-group-item-success
{
    color: #155724;
    background-color: #c3e6cb;
}
.list-group-item-success.list-group-item-action:hover,
.list-group-item-success.list-group-item-action:focus
{
    color: #155724;
    background-color: #b1dfbb;
}
.list-group-item-success.list-group-item-action.active
{
    color: #fff;
    border-color: #155724;
    background-color: #155724;
}

.list-group-item-info
{
    color: #0c5460;
    background-color: #bee5eb;
}
.list-group-item-info.list-group-item-action:hover,
.list-group-item-info.list-group-item-action:focus
{
    color: #0c5460;
    background-color: #abdde5;
}
.list-group-item-info.list-group-item-action.active
{
    color: #fff;
    border-color: #0c5460;
    background-color: #0c5460;
}

.list-group-item-warning
{
    color: #856404;
    background-color: #ffeeba;
}
.list-group-item-warning.list-group-item-action:hover,
.list-group-item-warning.list-group-item-action:focus
{
    color: #856404;
    background-color: #ffe8a1;
}
.list-group-item-warning.list-group-item-action.active
{
    color: #fff;
    border-color: #856404;
    background-color: #856404;
}

.list-group-item-danger
{
    color: #721c24;
    background-color: #f5c6cb;
}
.list-group-item-danger.list-group-item-action:hover,
.list-group-item-danger.list-group-item-action:focus
{
    color: #721c24;
    background-color: #f1b0b7;
}
.list-group-item-danger.list-group-item-action.active
{
    color: #fff;
    border-color: #721c24;
    background-color: #721c24;
}

.list-group-item-light
{
    color: #818182;
    background-color: #fdfdfe;
}
.list-group-item-light.list-group-item-action:hover,
.list-group-item-light.list-group-item-action:focus
{
    color: #818182;
    background-color: #ececf6;
}
.list-group-item-light.list-group-item-action.active
{
    color: #fff;
    border-color: #818182;
    background-color: #818182;
}

.list-group-item-dark
{
    color: #1b1e21;
    background-color: #c6c8ca;
}
.list-group-item-dark.list-group-item-action:hover,
.list-group-item-dark.list-group-item-action:focus
{
    color: #1b1e21;
    background-color: #b9bbbe;
}
.list-group-item-dark.list-group-item-action.active
{
    color: #fff;
    border-color: #1b1e21;
    background-color: #1b1e21;
}

.close
{
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;

    float: right;

    opacity: .5;
    color: #000;
    text-shadow: 0 1px 0 #fff;
}
.close:hover
{
    text-decoration: none;

    color: #000;
}
.close:not(:disabled):not(.disabled):hover,
.close:not(:disabled):not(.disabled):focus
{
    opacity: .75;
}

button.close
{
    padding: 0;

    border: 0;
    background-color: transparent;

    -webkit-appearance: none;
            -moz-appearance: none;
         appearance: none;
}

a.close.disabled
{
    pointer-events: none;
}

.toast
{
    font-size: .875rem;

    overflow: hidden;

    max-width: 350px;

    opacity: 0;
    border: 1px solid rgba(0, 0, 0, .1);
    border-radius: .25rem;
    background-color: rgba(255, 255, 255, .85);
    background-clip: padding-box;
    box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .1);

    -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);
}
.toast:not(:last-child)
{
    margin-bottom: .75rem;
}
.toast.showing
{
    opacity: 1;
}
.toast.show
{
    display: block;

    opacity: 1;
}
.toast.hide
{
    display: none;
}

.toast-header
{
    display: flex;

    padding: .25rem .75rem;

    color: #6c757d;
    border-bottom: 1px solid rgba(0, 0, 0, .05);
    background-color: rgba(255, 255, 255, .85);
    background-clip: padding-box;

    align-items: center;
}

.toast-body
{
    padding: .75rem;
}

.modal-open
{
    overflow: hidden;
}
.modal-open .modal
{
    overflow-x: hidden;
    overflow-y: auto;
}

.modal
{
    position: fixed;
    z-index: 1050;
    top: 0;
    left: 0;

    display: none;
    overflow: hidden;

    width: 100%;
    height: 100%;

    outline: 0;
}

.modal-dialog
{
    position: relative;

    width: auto;
    margin: .5rem;

    pointer-events: none;
}
.modal.fade .modal-dialog
{
    transition: transform .3s ease-out;
    transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce)
{
    .modal.fade .modal-dialog
    {
        transition: none;
    }
}
.modal.show .modal-dialog
{
    transform: none;
}

.modal-dialog-scrollable
{
    display: flex;

    max-height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content
{
    overflow: hidden;

    max-height: calc(100vh - 1rem);
}
.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer
{
    flex-shrink: 0;
}
.modal-dialog-scrollable .modal-body
{
    overflow-y: auto;
}

.modal-dialog-centered
{
    display: flex;

    min-height: calc(100% - 1rem);

    align-items: center;
}
.modal-dialog-centered::before
{
    display: block;

    height: calc(100vh - 1rem);

    content: '';
}
.modal-dialog-centered.modal-dialog-scrollable
{
    flex-direction: column;

    height: 100%;

    justify-content: center;
}
.modal-dialog-centered.modal-dialog-scrollable .modal-content
{
    max-height: none;
}
.modal-dialog-centered.modal-dialog-scrollable::before
{
    content: none;
}

.modal-content
{
    position: relative;

    display: flex;
    flex-direction: column;

    width: 100%;

    pointer-events: auto;

    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: 0;
    outline: 0;
    background-color: #fff;
    background-clip: padding-box;
}

.modal-backdrop
{
    position: fixed;
    z-index: 1040;
    top: 0;
    left: 0;

    width: 100vw;
    height: 100vh;

    background-color: #000;
}
.modal-backdrop.fade
{
    opacity: 0;
}
.modal-backdrop.show
{
    opacity: .5;
}

.modal-header
{
    display: flex;

    padding: 1rem 1rem;

    border-bottom: 1px solid #dee2e6;
    border-top-left-radius: 0;
    border-top-right-radius: 0;

    align-items: flex-start;
    justify-content: space-between;
}
.modal-header .close
{
    margin: -1rem -1rem -1rem auto;
    padding: 1rem 1rem;
}

.modal-title
{
    line-height: 1.7;

    margin-bottom: 0;
}

.modal-body
{
    position: relative;

    padding: 1rem;

    flex: 1 1 auto;
}

.modal-footer
{
    display: flex;

    padding: 1rem;

    border-top: 1px solid #dee2e6;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;

    align-items: center;
    justify-content: flex-end;
}
.modal-footer > :not(:first-child)
{
    margin-left: .25rem;
}
.modal-footer > :not(:last-child)
{
    margin-right: .25rem;
}

.modal-scrollbar-measure
{
    position: absolute;
    top: -9999px;

    overflow: scroll;

    width: 50px;
    height: 50px;
}

@media (min-width: 576px)
{
    .modal-dialog
    {
        max-width: 500px;
        margin: 1.75rem auto;
    }
    .modal-dialog-scrollable
    {
        max-height: calc(100% - 3.5rem);
    }
    .modal-dialog-scrollable .modal-content
    {
        max-height: calc(100vh - 3.5rem);
    }
    .modal-dialog-centered
    {
        min-height: calc(100% - 3.5rem);
    }
    .modal-dialog-centered::before
    {
        height: calc(100vh - 3.5rem);
    }
    .modal-sm
    {
        max-width: 300px;
    }
}

@media (min-width: 992px)
{
    .modal-lg,
    .modal-xl
    {
        max-width: 800px;
    }
}

@media (min-width: 1200px)
{
    .modal-xl
    {
        max-width: 1140px;
    }
}

.tooltip
{
    font-family: 'Lato', sans-serif;
    font-size: .85rem;
    font-weight: 400;
    font-style: normal;
    line-height: 1.7;

    position: absolute;
    z-index: 1070;

    display: block;

    margin: 0;

    text-align: left;
    text-align: start;
    white-space: normal;
    text-decoration: none;
    letter-spacing: normal;
    word-spacing: normal;
    text-transform: none;
    word-wrap: break-word;
    word-break: normal;

    opacity: 0;
    text-shadow: none;

    line-break: auto;
}
.tooltip.show
{
    opacity: .9;
}
.tooltip .arrow
{
    position: absolute;

    display: block;

    width: .8rem;
    height: .4rem;
}
.tooltip .arrow::before
{
    position: absolute;

    content: '';

    border-style: solid;
    border-color: transparent;
}

.bs-tooltip-top,
.bs-tooltip-auto[x-placement^='top']
{
    padding: .4rem 0;
}
.bs-tooltip-top .arrow,
.bs-tooltip-auto[x-placement^='top'] .arrow
{
    bottom: 0;
}
.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[x-placement^='top'] .arrow::before
{
    top: 0;

    border-width: .4rem .4rem 0;
    border-top-color: #000;
}

.bs-tooltip-right,
.bs-tooltip-auto[x-placement^='right']
{
    padding: 0 .4rem;
}
.bs-tooltip-right .arrow,
.bs-tooltip-auto[x-placement^='right'] .arrow
{
    left: 0;

    width: .4rem;
    height: .8rem;
}
.bs-tooltip-right .arrow::before,
.bs-tooltip-auto[x-placement^='right'] .arrow::before
{
    right: 0;

    border-width: .4rem .4rem .4rem 0;
    border-right-color: #000;
}

.bs-tooltip-bottom,
.bs-tooltip-auto[x-placement^='bottom']
{
    padding: .4rem 0;
}
.bs-tooltip-bottom .arrow,
.bs-tooltip-auto[x-placement^='bottom'] .arrow
{
    top: 0;
}
.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^='bottom'] .arrow::before
{
    bottom: 0;

    border-width: 0 .4rem .4rem;
    border-bottom-color: #000;
}

.bs-tooltip-left,
.bs-tooltip-auto[x-placement^='left']
{
    padding: 0 .4rem;
}
.bs-tooltip-left .arrow,
.bs-tooltip-auto[x-placement^='left'] .arrow
{
    right: 0;

    width: .4rem;
    height: .8rem;
}
.bs-tooltip-left .arrow::before,
.bs-tooltip-auto[x-placement^='left'] .arrow::before
{
    left: 0;

    border-width: .4rem 0 .4rem .4rem;
    border-left-color: #000;
}

.tooltip-inner
{
    max-width: 200px;
    padding: .25rem .5rem;

    text-align: center;

    color: #fff;
    border-radius: 0;
    background-color: #000;
}

.popover
{
    font-family: 'Lato', sans-serif;
    font-size: .85rem;
    font-weight: 400;
    font-style: normal;
    line-height: 1.7;

    position: absolute;
    z-index: 1060;
    top: 0;
    left: 0;

    display: block;

    max-width: 276px;

    text-align: left;
    text-align: start;
    white-space: normal;
    text-decoration: none;
    letter-spacing: normal;
    word-spacing: normal;
    text-transform: none;
    word-wrap: break-word;
    word-break: normal;

    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: 0;
    background-color: #fff;
    background-clip: padding-box;
    text-shadow: none;

    line-break: auto;
}
.popover .arrow
{
    position: absolute;

    display: block;

    width: 1rem;
    height: .5rem;
    margin: 0 0;
}
.popover .arrow::before,
.popover .arrow::after
{
    position: absolute;

    display: block;

    content: '';

    border-style: solid;
    border-color: transparent;
}

.bs-popover-top,
.bs-popover-auto[x-placement^='top']
{
    margin-bottom: .5rem;
}
.bs-popover-top > .arrow,
.bs-popover-auto[x-placement^='top'] > .arrow
{
    bottom: calc((.5rem + 1px) * -1);
}
.bs-popover-top > .arrow::before,
.bs-popover-auto[x-placement^='top'] > .arrow::before
{
    bottom: 0;

    border-width: .5rem .5rem 0;
    border-top-color: rgba(0, 0, 0, .25);
}
.bs-popover-top > .arrow::after,
.bs-popover-auto[x-placement^='top'] > .arrow::after
{
    bottom: 1px;

    border-width: .5rem .5rem 0;
    border-top-color: #fff;
}

.bs-popover-right,
.bs-popover-auto[x-placement^='right']
{
    margin-left: .5rem;
}
.bs-popover-right > .arrow,
.bs-popover-auto[x-placement^='right'] > .arrow
{
    left: calc((.5rem + 1px) * -1);

    width: .5rem;
    height: 1rem;
    margin: 0 0;
}
.bs-popover-right > .arrow::before,
.bs-popover-auto[x-placement^='right'] > .arrow::before
{
    left: 0;

    border-width: .5rem .5rem .5rem 0;
    border-right-color: rgba(0, 0, 0, .25);
}
.bs-popover-right > .arrow::after,
.bs-popover-auto[x-placement^='right'] > .arrow::after
{
    left: 1px;

    border-width: .5rem .5rem .5rem 0;
    border-right-color: #fff;
}

.bs-popover-bottom,
.bs-popover-auto[x-placement^='bottom']
{
    margin-top: .5rem;
}
.bs-popover-bottom > .arrow,
.bs-popover-auto[x-placement^='bottom'] > .arrow
{
    top: calc((.5rem + 1px) * -1);
}
.bs-popover-bottom > .arrow::before,
.bs-popover-auto[x-placement^='bottom'] > .arrow::before
{
    top: 0;

    border-width: 0 .5rem .5rem .5rem;
    border-bottom-color: rgba(0, 0, 0, .25);
}
.bs-popover-bottom > .arrow::after,
.bs-popover-auto[x-placement^='bottom'] > .arrow::after
{
    top: 1px;

    border-width: 0 .5rem .5rem .5rem;
    border-bottom-color: #fff;
}
.bs-popover-bottom .popover-header::before,
.bs-popover-auto[x-placement^='bottom'] .popover-header::before
{
    position: absolute;
    top: 0;
    left: 50%;

    display: block;

    width: 1rem;
    margin-left: -.5rem;

    content: '';

    border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left,
.bs-popover-auto[x-placement^='left']
{
    margin-right: .5rem;
}
.bs-popover-left > .arrow,
.bs-popover-auto[x-placement^='left'] > .arrow
{
    right: calc((.5rem + 1px) * -1);

    width: .5rem;
    height: 1rem;
    margin: 0 0;
}
.bs-popover-left > .arrow::before,
.bs-popover-auto[x-placement^='left'] > .arrow::before
{
    right: 0;

    border-width: .5rem 0 .5rem .5rem;
    border-left-color: rgba(0, 0, 0, .25);
}
.bs-popover-left > .arrow::after,
.bs-popover-auto[x-placement^='left'] > .arrow::after
{
    right: 1px;

    border-width: .5rem 0 .5rem .5rem;
    border-left-color: #fff;
}

.popover-header
{
    font-size: 1rem;

    margin-bottom: 0;
    padding: .5rem .75rem;

    color: #151515;
    border-bottom: 1px solid #ebebeb;
    border-top-left-radius: calc(0 - 1px);
    border-top-right-radius: calc(0 - 1px);
    background-color: #f7f7f7;
}
.popover-header:empty
{
    display: none;
}

.popover-body
{
    padding: .5rem .75rem;

    color: #777;
}

.carousel
{
    position: relative;
}

.carousel.pointer-event
{
    touch-action: pan-y;
}

.carousel-inner
{
    position: relative;

    overflow: hidden;

    width: 100%;
}
.carousel-inner::after
{
    display: block;
    clear: both;

    content: '';
}

.carousel-item
{
    position: relative;

    display: none;
    float: left;

    width: 100%;
    margin-right: -100%;

    transition: transform .6s ease-in-out;

    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
}
@media (prefers-reduced-motion: reduce)
{
    .carousel-item
    {
        transition: none;
    }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev
{
    display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right
{
    transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left
{
    transform: translateX(-100%);
}

.carousel-fade .carousel-item
{
    transition-property: opacity;
    transform: none;

    opacity: 0;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right
{
    z-index: 1;

    opacity: 1;
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right
{
    z-index: 0;

    transition: 0s .6s opacity;

    opacity: 0;
}
@media (prefers-reduced-motion: reduce)
{
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right
    {
        transition: none;
    }
}

.carousel-control-prev,
.carousel-control-next
{
    position: absolute;
    z-index: 1;
    top: 0;
    bottom: 0;

    display: flex;

    width: 15%;

    transition: opacity .15s ease;
    text-align: center;

    opacity: .5;
    color: #fff;

    align-items: center;
    justify-content: center;
}
@media (prefers-reduced-motion: reduce)
{
    .carousel-control-prev,
    .carousel-control-next
    {
        transition: none;
    }
}
.carousel-control-prev:hover,
.carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus
{
    text-decoration: none;

    opacity: .9;
    color: #fff;
    outline: 0;
}

.carousel-control-prev
{
    left: 0;
}

.carousel-control-next
{
    right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon
{
    display: inline-block;

    width: 20px;
    height: 20px;

    background: no-repeat 50% / 100% 100%;
}

.carousel-control-prev-icon
{
    background-image: url('data:image/svg xml,<svg xmlns=\'http://www.w3.org/2000/svg\' fill=\'#fff\' viewBox=\'0 0 8 8\'><path d=\'M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z\'/></svg>');
}

.carousel-control-next-icon
{
    background-image: url('data:image/svg xml,<svg xmlns=\'http://www.w3.org/2000/svg\' fill=\'#fff\' viewBox=\'0 0 8 8\'><path d=\'M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z\'/></svg>');
}

.carousel-indicators
{
    position: absolute;
    z-index: 15;
    right: 0;
    bottom: 0;
    left: 0;

    display: flex;

    margin-right: 15%;
    margin-left: 15%;
    padding-left: 0;

    list-style: none;

    justify-content: center;
}
.carousel-indicators li
{
    box-sizing: content-box;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;

    cursor: pointer;
    transition: opacity .6s ease;
    text-indent: -999px;

    opacity: .5;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    background-color: #fff;
    background-clip: padding-box;

    flex: 0 1 auto;
}
@media (prefers-reduced-motion: reduce)
{
    .carousel-indicators li
    {
        transition: none;
    }
}
.carousel-indicators .active
{
    opacity: 1;
}

.carousel-caption
{
    position: absolute;
    z-index: 10;
    right: 15%;
    bottom: 20px;
    left: 15%;

    padding-top: 20px;
    padding-bottom: 20px;

    text-align: center;

    color: #fff;
}

@-webkit-keyframes spinner-border
{
    to
    {
        transform: rotate(360deg);
    }
}

@keyframes spinner-border
{
    to
    {
        transform: rotate(360deg);
    }
}

.spinner-border
{
    display: inline-block;

    width: 2rem;
    height: 2rem;

    -webkit-animation: spinner-border .75s linear infinite;

            animation: spinner-border .75s linear infinite;
    vertical-align: text-bottom;

    border: .25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
}

.spinner-border-sm
{
    width: 1rem;
    height: 1rem;

    border-width: .2em;
}

@-webkit-keyframes spinner-grow
{
    0%
    {
        transform: scale(0);
    }
    50%
    {
        opacity: 1;
    }
}

@keyframes spinner-grow
{
    0%
    {
        transform: scale(0);
    }
    50%
    {
        opacity: 1;
    }
}

.spinner-grow
{
    display: inline-block;

    width: 2rem;
    height: 2rem;

    -webkit-animation: spinner-grow .75s linear infinite;

            animation: spinner-grow .75s linear infinite;
    vertical-align: text-bottom;

    opacity: 0;
    border-radius: 50%;
    background-color: currentColor;
}

.spinner-grow-sm
{
    width: 1rem;
    height: 1rem;
}

.align-baseline
{
    vertical-align: baseline !important;
}

.align-top
{
    vertical-align: top !important;
}

.align-middle
{
    vertical-align: middle !important;
}

.align-bottom
{
    vertical-align: bottom !important;
}

.align-text-bottom
{
    vertical-align: text-bottom !important;
}

.align-text-top
{
    vertical-align: text-top !important;
}

.bg-primary
{
    background-color: #f5a637 !important;
}

a.bg-primary:hover,
a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus
{
    background-color: #ed8f0c !important;
}

.bg-secondary
{
    background-color: #6c757d !important;
}

a.bg-secondary:hover,
a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus
{
    background-color: #545b62 !important;
}

.bg-success
{
    background-color: #28a745 !important;
}

a.bg-success:hover,
a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus
{
    background-color: #1e7e34 !important;
}

.bg-info
{
    background-color: #17a2b8 !important;
}

a.bg-info:hover,
a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus
{
    background-color: #117a8b !important;
}

.bg-warning
{
    background-color: #ffc107 !important;
}

a.bg-warning:hover,
a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus
{
    background-color: #d39e00 !important;
}

.bg-danger
{
    background-color: #dc3545 !important;
}

a.bg-danger:hover,
a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus
{
    background-color: #bd2130 !important;
}

.bg-light
{
    background-color: #f8f9fa !important;
}

a.bg-light:hover,
a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus
{
    background-color: #dae0e5 !important;
}

.bg-dark
{
    background-color: #343a40 !important;
}

a.bg-dark:hover,
a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus
{
    background-color: #1d2124 !important;
}

.bg-white
{
    background-color: #fff !important;
}

.bg-transparent
{
    background-color: transparent !important;
}

.border
{
    border: 1px solid #dee2e6 !important;
}

.border-top
{
    border-top: 1px solid #dee2e6 !important;
}

.border-right
{
    border-right: 1px solid #dee2e6 !important;
}

.border-bottom
{
    border-bottom: 1px solid #dee2e6 !important;
}

.border-left
{
    border-left: 1px solid #dee2e6 !important;
}

.border-0
{
    border: 0 !important;
}

.border-top-0
{
    border-top: 0 !important;
}

.border-right-0
{
    border-right: 0 !important;
}

.border-bottom-0
{
    border-bottom: 0 !important;
}

.border-left-0
{
    border-left: 0 !important;
}

.border-primary
{
    border-color: #f5a637 !important;
}

.border-secondary
{
    border-color: #6c757d !important;
}

.border-success
{
    border-color: #28a745 !important;
}

.border-info
{
    border-color: #17a2b8 !important;
}

.border-warning
{
    border-color: #ffc107 !important;
}

.border-danger
{
    border-color: #dc3545 !important;
}

.border-light
{
    border-color: #f8f9fa !important;
}

.border-dark
{
    border-color: #343a40 !important;
}

.border-white
{
    border-color: #fff !important;
}

.rounded-sm
{
    border-radius: 0 !important;
}

.rounded
{
    border-radius: 0 !important;
}

.rounded-top
{
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
}

.rounded-right
{
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

.rounded-bottom
{
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}

.rounded-left
{
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}

.rounded-lg
{
    border-radius: 0 !important;
}

.rounded-circle
{
    border-radius: 50% !important;
}

.rounded-pill
{
    border-radius: 50rem !important;
}

.rounded-0
{
    border-radius: 0 !important;
}

.clearfix::after
{
    display: block;
    clear: both;

    content: '';
}

.d-none
{
    display: none !important;
}

.d-inline
{
    display: inline !important;
}

.d-inline-block
{
    display: inline-block !important;
}

.d-block
{
    display: block !important;
}

.d-table
{
    display: table !important;
}

.d-table-row
{
    display: table-row !important;
}

.d-table-cell
{
    display: table-cell !important;
}

.d-flex
{
    display: flex !important;
}

.d-inline-flex
{
    display: inline-flex !important;
}

@media (min-width: 576px)
{
    .d-sm-none
    {
        display: none !important;
    }
    .d-sm-inline
    {
        display: inline !important;
    }
    .d-sm-inline-block
    {
        display: inline-block !important;
    }
    .d-sm-block
    {
        display: block !important;
    }
    .d-sm-table
    {
        display: table !important;
    }
    .d-sm-table-row
    {
        display: table-row !important;
    }
    .d-sm-table-cell
    {
        display: table-cell !important;
    }
    .d-sm-flex
    {
        display: flex !important;
    }
    .d-sm-inline-flex
    {
        display: inline-flex !important;
    }
}

@media (min-width: 768px)
{
    .d-md-none
    {
        display: none !important;
    }
    .d-md-inline
    {
        display: inline !important;
    }
    .d-md-inline-block
    {
        display: inline-block !important;
    }
    .d-md-block
    {
        display: block !important;
    }
    .d-md-table
    {
        display: table !important;
    }
    .d-md-table-row
    {
        display: table-row !important;
    }
    .d-md-table-cell
    {
        display: table-cell !important;
    }
    .d-md-flex
    {
        display: flex !important;
    }
    .d-md-inline-flex
    {
        display: inline-flex !important;
    }
}

@media (min-width: 992px)
{
    .d-lg-none
    {
        display: none !important;
    }
    .d-lg-inline
    {
        display: inline !important;
    }
    .d-lg-inline-block
    {
        display: inline-block !important;
    }
    .d-lg-block
    {
        display: block !important;
    }
    .d-lg-table
    {
        display: table !important;
    }
    .d-lg-table-row
    {
        display: table-row !important;
    }
    .d-lg-table-cell
    {
        display: table-cell !important;
    }
    .d-lg-flex
    {
        display: flex !important;
    }
    .d-lg-inline-flex
    {
        display: inline-flex !important;
    }
}

@media (min-width: 1200px)
{
    .d-xl-none
    {
        display: none !important;
    }
    .d-xl-inline
    {
        display: inline !important;
    }
    .d-xl-inline-block
    {
        display: inline-block !important;
    }
    .d-xl-block
    {
        display: block !important;
    }
    .d-xl-table
    {
        display: table !important;
    }
    .d-xl-table-row
    {
        display: table-row !important;
    }
    .d-xl-table-cell
    {
        display: table-cell !important;
    }
    .d-xl-flex
    {
        display: flex !important;
    }
    .d-xl-inline-flex
    {
        display: inline-flex !important;
    }
}

@media print
{
    .d-print-none
    {
        display: none !important;
    }
    .d-print-inline
    {
        display: inline !important;
    }
    .d-print-inline-block
    {
        display: inline-block !important;
    }
    .d-print-block
    {
        display: block !important;
    }
    .d-print-table
    {
        display: table !important;
    }
    .d-print-table-row
    {
        display: table-row !important;
    }
    .d-print-table-cell
    {
        display: table-cell !important;
    }
    .d-print-flex
    {
        display: flex !important;
    }
    .d-print-inline-flex
    {
        display: inline-flex !important;
    }
}

.embed-responsive
{
    position: relative;

    display: block;
    overflow: hidden;

    width: 100%;
    padding: 0;
}
.embed-responsive::before
{
    display: block;

    content: '';
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video
{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;

    width: 100%;
    height: 100%;

    border: 0;
}

.embed-responsive-21by9::before
{
    padding-top: 42.85714%;
}

.embed-responsive-16by9::before
{
    padding-top: 56.25%;
}

.embed-responsive-4by3::before
{
    padding-top: 75%;
}

.embed-responsive-1by1::before
{
    padding-top: 100%;
}

.flex-row
{
    flex-direction: row !important;
}

.flex-column
{
    flex-direction: column !important;
}

.flex-row-reverse
{
    flex-direction: row-reverse !important;
}

.flex-column-reverse
{
    flex-direction: column-reverse !important;
}

.flex-wrap
{
    flex-wrap: wrap !important;
}

.flex-nowrap
{
    flex-wrap: nowrap !important;
}

.flex-wrap-reverse
{
    flex-wrap: wrap-reverse !important;
}

.flex-fill
{
    flex: 1 1 auto !important;
}

.flex-grow-0
{
    flex-grow: 0 !important;
}

.flex-grow-1
{
    flex-grow: 1 !important;
}

.flex-shrink-0
{
    flex-shrink: 0 !important;
}

.flex-shrink-1
{
    flex-shrink: 1 !important;
}

.justify-content-start
{
    justify-content: flex-start !important;
}

.justify-content-end
{
    justify-content: flex-end !important;
}

.justify-content-center
{
    justify-content: center !important;
}

.justify-content-between
{
    justify-content: space-between !important;
}

.justify-content-around
{
    justify-content: space-around !important;
}

.align-items-start
{
    align-items: flex-start !important;
}

.align-items-end
{
    align-items: flex-end !important;
}

.align-items-center
{
    align-items: center !important;
}

.align-items-baseline
{
    align-items: baseline !important;
}

.align-items-stretch
{
    align-items: stretch !important;
}

.align-content-start
{
    align-content: flex-start !important;
}

.align-content-end
{
    align-content: flex-end !important;
}

.align-content-center
{
    align-content: center !important;
}

.align-content-between
{
    align-content: space-between !important;
}

.align-content-around
{
    align-content: space-around !important;
}

.align-content-stretch
{
    align-content: stretch !important;
}

.align-self-auto
{
    align-self: auto !important;
}

.align-self-start
{
    align-self: flex-start !important;
}

.align-self-end
{
    align-self: flex-end !important;
}

.align-self-center
{
    align-self: center !important;
}

.align-self-baseline
{
    align-self: baseline !important;
}

.align-self-stretch
{
    align-self: stretch !important;
}

@media (min-width: 576px)
{
    .flex-sm-row
    {
        flex-direction: row !important;
    }
    .flex-sm-column
    {
        flex-direction: column !important;
    }
    .flex-sm-row-reverse
    {
        flex-direction: row-reverse !important;
    }
    .flex-sm-column-reverse
    {
        flex-direction: column-reverse !important;
    }
    .flex-sm-wrap
    {
        flex-wrap: wrap !important;
    }
    .flex-sm-nowrap
    {
        flex-wrap: nowrap !important;
    }
    .flex-sm-wrap-reverse
    {
        flex-wrap: wrap-reverse !important;
    }
    .flex-sm-fill
    {
        flex: 1 1 auto !important;
    }
    .flex-sm-grow-0
    {
        flex-grow: 0 !important;
    }
    .flex-sm-grow-1
    {
        flex-grow: 1 !important;
    }
    .flex-sm-shrink-0
    {
        flex-shrink: 0 !important;
    }
    .flex-sm-shrink-1
    {
        flex-shrink: 1 !important;
    }
    .justify-content-sm-start
    {
        justify-content: flex-start !important;
    }
    .justify-content-sm-end
    {
        justify-content: flex-end !important;
    }
    .justify-content-sm-center
    {
        justify-content: center !important;
    }
    .justify-content-sm-between
    {
        justify-content: space-between !important;
    }
    .justify-content-sm-around
    {
        justify-content: space-around !important;
    }
    .align-items-sm-start
    {
        align-items: flex-start !important;
    }
    .align-items-sm-end
    {
        align-items: flex-end !important;
    }
    .align-items-sm-center
    {
        align-items: center !important;
    }
    .align-items-sm-baseline
    {
        align-items: baseline !important;
    }
    .align-items-sm-stretch
    {
        align-items: stretch !important;
    }
    .align-content-sm-start
    {
        align-content: flex-start !important;
    }
    .align-content-sm-end
    {
        align-content: flex-end !important;
    }
    .align-content-sm-center
    {
        align-content: center !important;
    }
    .align-content-sm-between
    {
        align-content: space-between !important;
    }
    .align-content-sm-around
    {
        align-content: space-around !important;
    }
    .align-content-sm-stretch
    {
        align-content: stretch !important;
    }
    .align-self-sm-auto
    {
        align-self: auto !important;
    }
    .align-self-sm-start
    {
        align-self: flex-start !important;
    }
    .align-self-sm-end
    {
        align-self: flex-end !important;
    }
    .align-self-sm-center
    {
        align-self: center !important;
    }
    .align-self-sm-baseline
    {
        align-self: baseline !important;
    }
    .align-self-sm-stretch
    {
        align-self: stretch !important;
    }
}

@media (min-width: 768px)
{
    .flex-md-row
    {
        flex-direction: row !important;
    }
    .flex-md-column
    {
        flex-direction: column !important;
    }
    .flex-md-row-reverse
    {
        flex-direction: row-reverse !important;
    }
    .flex-md-column-reverse
    {
        flex-direction: column-reverse !important;
    }
    .flex-md-wrap
    {
        flex-wrap: wrap !important;
    }
    .flex-md-nowrap
    {
        flex-wrap: nowrap !important;
    }
    .flex-md-wrap-reverse
    {
        flex-wrap: wrap-reverse !important;
    }
    .flex-md-fill
    {
        flex: 1 1 auto !important;
    }
    .flex-md-grow-0
    {
        flex-grow: 0 !important;
    }
    .flex-md-grow-1
    {
        flex-grow: 1 !important;
    }
    .flex-md-shrink-0
    {
        flex-shrink: 0 !important;
    }
    .flex-md-shrink-1
    {
        flex-shrink: 1 !important;
    }
    .justify-content-md-start
    {
        justify-content: flex-start !important;
    }
    .justify-content-md-end
    {
        justify-content: flex-end !important;
    }
    .justify-content-md-center
    {
        justify-content: center !important;
    }
    .justify-content-md-between
    {
        justify-content: space-between !important;
    }
    .justify-content-md-around
    {
        justify-content: space-around !important;
    }
    .align-items-md-start
    {
        align-items: flex-start !important;
    }
    .align-items-md-end
    {
        align-items: flex-end !important;
    }
    .align-items-md-center
    {
        align-items: center !important;
    }
    .align-items-md-baseline
    {
        align-items: baseline !important;
    }
    .align-items-md-stretch
    {
        align-items: stretch !important;
    }
    .align-content-md-start
    {
        align-content: flex-start !important;
    }
    .align-content-md-end
    {
        align-content: flex-end !important;
    }
    .align-content-md-center
    {
        align-content: center !important;
    }
    .align-content-md-between
    {
        align-content: space-between !important;
    }
    .align-content-md-around
    {
        align-content: space-around !important;
    }
    .align-content-md-stretch
    {
        align-content: stretch !important;
    }
    .align-self-md-auto
    {
        align-self: auto !important;
    }
    .align-self-md-start
    {
        align-self: flex-start !important;
    }
    .align-self-md-end
    {
        align-self: flex-end !important;
    }
    .align-self-md-center
    {
        align-self: center !important;
    }
    .align-self-md-baseline
    {
        align-self: baseline !important;
    }
    .align-self-md-stretch
    {
        align-self: stretch !important;
    }
}

@media (min-width: 992px)
{
    .flex-lg-row
    {
        flex-direction: row !important;
    }
    .flex-lg-column
    {
        flex-direction: column !important;
    }
    .flex-lg-row-reverse
    {
        flex-direction: row-reverse !important;
    }
    .flex-lg-column-reverse
    {
        flex-direction: column-reverse !important;
    }
    .flex-lg-wrap
    {
        flex-wrap: wrap !important;
    }
    .flex-lg-nowrap
    {
        flex-wrap: nowrap !important;
    }
    .flex-lg-wrap-reverse
    {
        flex-wrap: wrap-reverse !important;
    }
    .flex-lg-fill
    {
        flex: 1 1 auto !important;
    }
    .flex-lg-grow-0
    {
        flex-grow: 0 !important;
    }
    .flex-lg-grow-1
    {
        flex-grow: 1 !important;
    }
    .flex-lg-shrink-0
    {
        flex-shrink: 0 !important;
    }
    .flex-lg-shrink-1
    {
        flex-shrink: 1 !important;
    }
    .justify-content-lg-start
    {
        justify-content: flex-start !important;
    }
    .justify-content-lg-end
    {
        justify-content: flex-end !important;
    }
    .justify-content-lg-center
    {
        justify-content: center !important;
    }
    .justify-content-lg-between
    {
        justify-content: space-between !important;
    }
    .justify-content-lg-around
    {
        justify-content: space-around !important;
    }
    .align-items-lg-start
    {
        align-items: flex-start !important;
    }
    .align-items-lg-end
    {
        align-items: flex-end !important;
    }
    .align-items-lg-center
    {
        align-items: center !important;
    }
    .align-items-lg-baseline
    {
        align-items: baseline !important;
    }
    .align-items-lg-stretch
    {
        align-items: stretch !important;
    }
    .align-content-lg-start
    {
        align-content: flex-start !important;
    }
    .align-content-lg-end
    {
        align-content: flex-end !important;
    }
    .align-content-lg-center
    {
        align-content: center !important;
    }
    .align-content-lg-between
    {
        align-content: space-between !important;
    }
    .align-content-lg-around
    {
        align-content: space-around !important;
    }
    .align-content-lg-stretch
    {
        align-content: stretch !important;
    }
    .align-self-lg-auto
    {
        align-self: auto !important;
    }
    .align-self-lg-start
    {
        align-self: flex-start !important;
    }
    .align-self-lg-end
    {
        align-self: flex-end !important;
    }
    .align-self-lg-center
    {
        align-self: center !important;
    }
    .align-self-lg-baseline
    {
        align-self: baseline !important;
    }
    .align-self-lg-stretch
    {
        align-self: stretch !important;
    }
}

@media (min-width: 1200px)
{
    .flex-xl-row
    {
        flex-direction: row !important;
    }
    .flex-xl-column
    {
        flex-direction: column !important;
    }
    .flex-xl-row-reverse
    {
        flex-direction: row-reverse !important;
    }
    .flex-xl-column-reverse
    {
        flex-direction: column-reverse !important;
    }
    .flex-xl-wrap
    {
        flex-wrap: wrap !important;
    }
    .flex-xl-nowrap
    {
        flex-wrap: nowrap !important;
    }
    .flex-xl-wrap-reverse
    {
        flex-wrap: wrap-reverse !important;
    }
    .flex-xl-fill
    {
        flex: 1 1 auto !important;
    }
    .flex-xl-grow-0
    {
        flex-grow: 0 !important;
    }
    .flex-xl-grow-1
    {
        flex-grow: 1 !important;
    }
    .flex-xl-shrink-0
    {
        flex-shrink: 0 !important;
    }
    .flex-xl-shrink-1
    {
        flex-shrink: 1 !important;
    }
    .justify-content-xl-start
    {
        justify-content: flex-start !important;
    }
    .justify-content-xl-end
    {
        justify-content: flex-end !important;
    }
    .justify-content-xl-center
    {
        justify-content: center !important;
    }
    .justify-content-xl-between
    {
        justify-content: space-between !important;
    }
    .justify-content-xl-around
    {
        justify-content: space-around !important;
    }
    .align-items-xl-start
    {
        align-items: flex-start !important;
    }
    .align-items-xl-end
    {
        align-items: flex-end !important;
    }
    .align-items-xl-center
    {
        align-items: center !important;
    }
    .align-items-xl-baseline
    {
        align-items: baseline !important;
    }
    .align-items-xl-stretch
    {
        align-items: stretch !important;
    }
    .align-content-xl-start
    {
        align-content: flex-start !important;
    }
    .align-content-xl-end
    {
        align-content: flex-end !important;
    }
    .align-content-xl-center
    {
        align-content: center !important;
    }
    .align-content-xl-between
    {
        align-content: space-between !important;
    }
    .align-content-xl-around
    {
        align-content: space-around !important;
    }
    .align-content-xl-stretch
    {
        align-content: stretch !important;
    }
    .align-self-xl-auto
    {
        align-self: auto !important;
    }
    .align-self-xl-start
    {
        align-self: flex-start !important;
    }
    .align-self-xl-end
    {
        align-self: flex-end !important;
    }
    .align-self-xl-center
    {
        align-self: center !important;
    }
    .align-self-xl-baseline
    {
        align-self: baseline !important;
    }
    .align-self-xl-stretch
    {
        align-self: stretch !important;
    }
}

.float-left
{
    float: left !important;
}

.float-right
{
    float: right !important;
}

.float-none
{
    float: none !important;
}

@media (min-width: 576px)
{
    .float-sm-left
    {
        float: left !important;
    }
    .float-sm-right
    {
        float: right !important;
    }
    .float-sm-none
    {
        float: none !important;
    }
}

@media (min-width: 768px)
{
    .float-md-left
    {
        float: left !important;
    }
    .float-md-right
    {
        float: right !important;
    }
    .float-md-none
    {
        float: none !important;
    }
}

@media (min-width: 992px)
{
    .float-lg-left
    {
        float: left !important;
    }
    .float-lg-right
    {
        float: right !important;
    }
    .float-lg-none
    {
        float: none !important;
    }
}

@media (min-width: 1200px)
{
    .float-xl-left
    {
        float: left !important;
    }
    .float-xl-right
    {
        float: right !important;
    }
    .float-xl-none
    {
        float: none !important;
    }
}

.overflow-auto
{
    overflow: auto !important;
}

.overflow-hidden
{
    overflow: hidden !important;
}

.position-static
{
    position: static !important;
}

.position-relative
{
    position: relative !important;
}

.position-absolute
{
    position: absolute !important;
}

.position-fixed
{
    position: fixed !important;
}

.position-sticky
{
    position: -webkit-sticky !important;
    position:         sticky !important;
}

.fixed-top
{
    position: fixed;
    z-index: 1030;
    top: 0;
    right: 0;
    left: 0;
}

.fixed-bottom
{
    position: fixed;
    z-index: 1030;
    right: 0;
    bottom: 0;
    left: 0;
}

@supports ((position: -webkit-sticky) or (position: sticky))
{
    .sticky-top
    {
        position: -webkit-sticky;
        position:         sticky;
        z-index: 1020;
        top: 0;
    }
}

.sr-only
{
    position: absolute;

    overflow: hidden;
    clip: rect(0, 0, 0, 0);

    width: 1px;
    height: 1px;
    padding: 0;

    white-space: nowrap;

    border: 0;
}

.sr-only-focusable:active,
.sr-only-focusable:focus
{
    position: static;

    overflow: visible;
    clip: auto;

    width: auto;
    height: auto;

    white-space: normal;
}

.shadow-sm
{
    box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075) !important;
}

.shadow
{
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) !important;
}

.shadow-lg
{
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, .175) !important;
}

.shadow-none
{
    box-shadow: none !important;
}

.w-25
{
    width: 25% !important;
}

.w-50
{
    width: 50% !important;
}

.w-75
{
    width: 75% !important;
}

.w-100
{
    width: 100% !important;
}

.w-auto
{
    width: auto !important;
}

.h-25
{
    height: 25% !important;
}

.h-50
{
    height: 50% !important;
}

.h-75
{
    height: 75% !important;
}

.h-100
{
    height: 100% !important;
}

.h-auto
{
    height: auto !important;
}

.mw-100
{
    max-width: 100% !important;
}

.mh-100
{
    max-height: 100% !important;
}

.min-vw-100
{
    min-width: 100vw !important;
}

.min-vh-100
{
    min-height: 100vh !important;
}

.vw-100
{
    width: 100vw !important;
}

.vh-100
{
    height: 100vh !important;
}

.stretched-link::after
{
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    content: '';
    pointer-events: auto;

    background-color: rgba(0, 0, 0, 0);
}

.m-0
{
    margin: 0 !important;
}

.mt-0,
.my-0
{
    margin-top: 0 !important;
}

.mr-0,
.mx-0
{
    margin-right: 0 !important;
}

.mb-0,
.my-0
{
    margin-bottom: 0 !important;
}

.ml-0,
.mx-0
{
    margin-left: 0 !important;
}

.m-1
{
    margin: .25rem !important;
}

.mt-1,
.my-1
{
    margin-top: .25rem !important;
}

.mr-1,
.mx-1
{
    margin-right: .25rem !important;
}

.mb-1,
.my-1
{
    margin-bottom: .25rem !important;
}

.ml-1,
.mx-1
{
    margin-left: .25rem !important;
}

.m-2
{
    margin: .5rem !important;
}

.mt-2,
.my-2
{
    margin-top: .5rem !important;
}

.mr-2,
.mx-2
{
    margin-right: .5rem !important;
}

.mb-2,
.my-2
{
    margin-bottom: .5rem !important;
}

.ml-2,
.mx-2
{
    margin-left: .5rem !important;
}

.m-3
{
    margin: 1rem !important;
}

.mt-3,
.my-3
{
    margin-top: 1rem !important;
}

.mr-3,
.mx-3
{
    margin-right: 1rem !important;
}

.mb-3,
.my-3
{
    margin-bottom: 1rem !important;
}

.ml-3,
.mx-3
{
    margin-left: 1rem !important;
}

.m-4
{
    margin: 1.5rem !important;
}

.mt-4,
.my-4
{
    margin-top: 1.5rem !important;
}

.mr-4,
.mx-4
{
    margin-right: 1.5rem !important;
}

.mb-4,
.my-4
{
    margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4
{
    margin-left: 1.5rem !important;
}

.m-5
{
    margin: 3rem !important;
}

.mt-5,
.my-5
{
    margin-top: 3rem !important;
}

.mr-5,
.mx-5
{
    margin-right: 3rem !important;
}

.mb-5,
.my-5
{
    margin-bottom: 3rem !important;
}

.ml-5,
.mx-5
{
    margin-left: 3rem !important;
}

.p-0
{
    padding: 0 !important;
}

.pt-0,
.py-0
{
    padding-top: 0 !important;
}

.pr-0,
.px-0
{
    padding-right: 0 !important;
}

.pb-0,
.py-0
{
    padding-bottom: 0 !important;
}

.pl-0,
.px-0
{
    padding-left: 0 !important;
}

.p-1
{
    padding: .25rem !important;
}

.pt-1,
.py-1
{
    padding-top: .25rem !important;
}

.pr-1,
.px-1
{
    padding-right: .25rem !important;
}

.pb-1,
.py-1
{
    padding-bottom: .25rem !important;
}

.pl-1,
.px-1
{
    padding-left: .25rem !important;
}

.p-2
{
    padding: .5rem !important;
}

.pt-2,
.py-2
{
    padding-top: .5rem !important;
}

.pr-2,
.px-2
{
    padding-right: .5rem !important;
}

.pb-2,
.py-2
{
    padding-bottom: .5rem !important;
}

.pl-2,
.px-2
{
    padding-left: .5rem !important;
}

.p-3
{
    padding: 1rem !important;
}

.pt-3,
.py-3
{
    padding-top: 1rem !important;
}

.pr-3,
.px-3
{
    padding-right: 1rem !important;
}

.pb-3,
.py-3
{
    padding-bottom: 1rem !important;
}

.pl-3,
.px-3
{
    padding-left: 1rem !important;
}

.p-4
{
    padding: 1.5rem !important;
}

.pt-4,
.py-4
{
    padding-top: 1.5rem !important;
}

.pr-4,
.px-4
{
    padding-right: 1.5rem !important;
}

.pb-4,
.py-4
{
    padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4
{
    padding-left: 1.5rem !important;
}

.p-5
{
    padding: 3rem !important;
}

.pt-5,
.py-5
{
    padding-top: 3rem !important;
}

.pr-5,
.px-5
{
    padding-right: 3rem !important;
}

.pb-5,
.py-5
{
    padding-bottom: 3rem !important;
}

.pl-5,
.px-5
{
    padding-left: 3rem !important;
}

.m-n1
{
    margin: -.25rem !important;
}

.mt-n1,
.my-n1
{
    margin-top: -.25rem !important;
}

.mr-n1,
.mx-n1
{
    margin-right: -.25rem !important;
}

.mb-n1,
.my-n1
{
    margin-bottom: -.25rem !important;
}

.ml-n1,
.mx-n1
{
    margin-left: -.25rem !important;
}

.m-n2
{
    margin: -.5rem !important;
}

.mt-n2,
.my-n2
{
    margin-top: -.5rem !important;
}

.mr-n2,
.mx-n2
{
    margin-right: -.5rem !important;
}

.mb-n2,
.my-n2
{
    margin-bottom: -.5rem !important;
}

.ml-n2,
.mx-n2
{
    margin-left: -.5rem !important;
}

.m-n3
{
    margin: -1rem !important;
}

.mt-n3,
.my-n3
{
    margin-top: -1rem !important;
}

.mr-n3,
.mx-n3
{
    margin-right: -1rem !important;
}

.mb-n3,
.my-n3
{
    margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3
{
    margin-left: -1rem !important;
}

.m-n4
{
    margin: -1.5rem !important;
}

.mt-n4,
.my-n4
{
    margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4
{
    margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4
{
    margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4
{
    margin-left: -1.5rem !important;
}

.m-n5
{
    margin: -3rem !important;
}

.mt-n5,
.my-n5
{
    margin-top: -3rem !important;
}

.mr-n5,
.mx-n5
{
    margin-right: -3rem !important;
}

.mb-n5,
.my-n5
{
    margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5
{
    margin-left: -3rem !important;
}

.m-auto
{
    margin: auto !important;
}

.mt-auto,
.my-auto
{
    margin-top: auto !important;
}

.mr-auto,
.mx-auto
{
    margin-right: auto !important;
}

.mb-auto,
.my-auto
{
    margin-bottom: auto !important;
}

.ml-auto,
.mx-auto
{
    margin-left: auto !important;
}

@media (min-width: 576px)
{
    .m-sm-0
    {
        margin: 0 !important;
    }
    .mt-sm-0,
    .my-sm-0
    {
        margin-top: 0 !important;
    }
    .mr-sm-0,
    .mx-sm-0
    {
        margin-right: 0 !important;
    }
    .mb-sm-0,
    .my-sm-0
    {
        margin-bottom: 0 !important;
    }
    .ml-sm-0,
    .mx-sm-0
    {
        margin-left: 0 !important;
    }
    .m-sm-1
    {
        margin: .25rem !important;
    }
    .mt-sm-1,
    .my-sm-1
    {
        margin-top: .25rem !important;
    }
    .mr-sm-1,
    .mx-sm-1
    {
        margin-right: .25rem !important;
    }
    .mb-sm-1,
    .my-sm-1
    {
        margin-bottom: .25rem !important;
    }
    .ml-sm-1,
    .mx-sm-1
    {
        margin-left: .25rem !important;
    }
    .m-sm-2
    {
        margin: .5rem !important;
    }
    .mt-sm-2,
    .my-sm-2
    {
        margin-top: .5rem !important;
    }
    .mr-sm-2,
    .mx-sm-2
    {
        margin-right: .5rem !important;
    }
    .mb-sm-2,
    .my-sm-2
    {
        margin-bottom: .5rem !important;
    }
    .ml-sm-2,
    .mx-sm-2
    {
        margin-left: .5rem !important;
    }
    .m-sm-3
    {
        margin: 1rem !important;
    }
    .mt-sm-3,
    .my-sm-3
    {
        margin-top: 1rem !important;
    }
    .mr-sm-3,
    .mx-sm-3
    {
        margin-right: 1rem !important;
    }
    .mb-sm-3,
    .my-sm-3
    {
        margin-bottom: 1rem !important;
    }
    .ml-sm-3,
    .mx-sm-3
    {
        margin-left: 1rem !important;
    }
    .m-sm-4
    {
        margin: 1.5rem !important;
    }
    .mt-sm-4,
    .my-sm-4
    {
        margin-top: 1.5rem !important;
    }
    .mr-sm-4,
    .mx-sm-4
    {
        margin-right: 1.5rem !important;
    }
    .mb-sm-4,
    .my-sm-4
    {
        margin-bottom: 1.5rem !important;
    }
    .ml-sm-4,
    .mx-sm-4
    {
        margin-left: 1.5rem !important;
    }
    .m-sm-5
    {
        margin: 3rem !important;
    }
    .mt-sm-5,
    .my-sm-5
    {
        margin-top: 3rem !important;
    }
    .mr-sm-5,
    .mx-sm-5
    {
        margin-right: 3rem !important;
    }
    .mb-sm-5,
    .my-sm-5
    {
        margin-bottom: 3rem !important;
    }
    .ml-sm-5,
    .mx-sm-5
    {
        margin-left: 3rem !important;
    }
    .p-sm-0
    {
        padding: 0 !important;
    }
    .pt-sm-0,
    .py-sm-0
    {
        padding-top: 0 !important;
    }
    .pr-sm-0,
    .px-sm-0
    {
        padding-right: 0 !important;
    }
    .pb-sm-0,
    .py-sm-0
    {
        padding-bottom: 0 !important;
    }
    .pl-sm-0,
    .px-sm-0
    {
        padding-left: 0 !important;
    }
    .p-sm-1
    {
        padding: .25rem !important;
    }
    .pt-sm-1,
    .py-sm-1
    {
        padding-top: .25rem !important;
    }
    .pr-sm-1,
    .px-sm-1
    {
        padding-right: .25rem !important;
    }
    .pb-sm-1,
    .py-sm-1
    {
        padding-bottom: .25rem !important;
    }
    .pl-sm-1,
    .px-sm-1
    {
        padding-left: .25rem !important;
    }
    .p-sm-2
    {
        padding: .5rem !important;
    }
    .pt-sm-2,
    .py-sm-2
    {
        padding-top: .5rem !important;
    }
    .pr-sm-2,
    .px-sm-2
    {
        padding-right: .5rem !important;
    }
    .pb-sm-2,
    .py-sm-2
    {
        padding-bottom: .5rem !important;
    }
    .pl-sm-2,
    .px-sm-2
    {
        padding-left: .5rem !important;
    }
    .p-sm-3
    {
        padding: 1rem !important;
    }
    .pt-sm-3,
    .py-sm-3
    {
        padding-top: 1rem !important;
    }
    .pr-sm-3,
    .px-sm-3
    {
        padding-right: 1rem !important;
    }
    .pb-sm-3,
    .py-sm-3
    {
        padding-bottom: 1rem !important;
    }
    .pl-sm-3,
    .px-sm-3
    {
        padding-left: 1rem !important;
    }
    .p-sm-4
    {
        padding: 1.5rem !important;
    }
    .pt-sm-4,
    .py-sm-4
    {
        padding-top: 1.5rem !important;
    }
    .pr-sm-4,
    .px-sm-4
    {
        padding-right: 1.5rem !important;
    }
    .pb-sm-4,
    .py-sm-4
    {
        padding-bottom: 1.5rem !important;
    }
    .pl-sm-4,
    .px-sm-4
    {
        padding-left: 1.5rem !important;
    }
    .p-sm-5
    {
        padding: 3rem !important;
    }
    .pt-sm-5,
    .py-sm-5
    {
        padding-top: 3rem !important;
    }
    .pr-sm-5,
    .px-sm-5
    {
        padding-right: 3rem !important;
    }
    .pb-sm-5,
    .py-sm-5
    {
        padding-bottom: 3rem !important;
    }
    .pl-sm-5,
    .px-sm-5
    {
        padding-left: 3rem !important;
    }
    .m-sm-n1
    {
        margin: -.25rem !important;
    }
    .mt-sm-n1,
    .my-sm-n1
    {
        margin-top: -.25rem !important;
    }
    .mr-sm-n1,
    .mx-sm-n1
    {
        margin-right: -.25rem !important;
    }
    .mb-sm-n1,
    .my-sm-n1
    {
        margin-bottom: -.25rem !important;
    }
    .ml-sm-n1,
    .mx-sm-n1
    {
        margin-left: -.25rem !important;
    }
    .m-sm-n2
    {
        margin: -.5rem !important;
    }
    .mt-sm-n2,
    .my-sm-n2
    {
        margin-top: -.5rem !important;
    }
    .mr-sm-n2,
    .mx-sm-n2
    {
        margin-right: -.5rem !important;
    }
    .mb-sm-n2,
    .my-sm-n2
    {
        margin-bottom: -.5rem !important;
    }
    .ml-sm-n2,
    .mx-sm-n2
    {
        margin-left: -.5rem !important;
    }
    .m-sm-n3
    {
        margin: -1rem !important;
    }
    .mt-sm-n3,
    .my-sm-n3
    {
        margin-top: -1rem !important;
    }
    .mr-sm-n3,
    .mx-sm-n3
    {
        margin-right: -1rem !important;
    }
    .mb-sm-n3,
    .my-sm-n3
    {
        margin-bottom: -1rem !important;
    }
    .ml-sm-n3,
    .mx-sm-n3
    {
        margin-left: -1rem !important;
    }
    .m-sm-n4
    {
        margin: -1.5rem !important;
    }
    .mt-sm-n4,
    .my-sm-n4
    {
        margin-top: -1.5rem !important;
    }
    .mr-sm-n4,
    .mx-sm-n4
    {
        margin-right: -1.5rem !important;
    }
    .mb-sm-n4,
    .my-sm-n4
    {
        margin-bottom: -1.5rem !important;
    }
    .ml-sm-n4,
    .mx-sm-n4
    {
        margin-left: -1.5rem !important;
    }
    .m-sm-n5
    {
        margin: -3rem !important;
    }
    .mt-sm-n5,
    .my-sm-n5
    {
        margin-top: -3rem !important;
    }
    .mr-sm-n5,
    .mx-sm-n5
    {
        margin-right: -3rem !important;
    }
    .mb-sm-n5,
    .my-sm-n5
    {
        margin-bottom: -3rem !important;
    }
    .ml-sm-n5,
    .mx-sm-n5
    {
        margin-left: -3rem !important;
    }
    .m-sm-auto
    {
        margin: auto !important;
    }
    .mt-sm-auto,
    .my-sm-auto
    {
        margin-top: auto !important;
    }
    .mr-sm-auto,
    .mx-sm-auto
    {
        margin-right: auto !important;
    }
    .mb-sm-auto,
    .my-sm-auto
    {
        margin-bottom: auto !important;
    }
    .ml-sm-auto,
    .mx-sm-auto
    {
        margin-left: auto !important;
    }
}

@media (min-width: 768px)
{
    .m-md-0
    {
        margin: 0 !important;
    }
    .mt-md-0,
    .my-md-0
    {
        margin-top: 0 !important;
    }
    .mr-md-0,
    .mx-md-0
    {
        margin-right: 0 !important;
    }
    .mb-md-0,
    .my-md-0
    {
        margin-bottom: 0 !important;
    }
    .ml-md-0,
    .mx-md-0
    {
        margin-left: 0 !important;
    }
    .m-md-1
    {
        margin: .25rem !important;
    }
    .mt-md-1,
    .my-md-1
    {
        margin-top: .25rem !important;
    }
    .mr-md-1,
    .mx-md-1
    {
        margin-right: .25rem !important;
    }
    .mb-md-1,
    .my-md-1
    {
        margin-bottom: .25rem !important;
    }
    .ml-md-1,
    .mx-md-1
    {
        margin-left: .25rem !important;
    }
    .m-md-2
    {
        margin: .5rem !important;
    }
    .mt-md-2,
    .my-md-2
    {
        margin-top: .5rem !important;
    }
    .mr-md-2,
    .mx-md-2
    {
        margin-right: .5rem !important;
    }
    .mb-md-2,
    .my-md-2
    {
        margin-bottom: .5rem !important;
    }
    .ml-md-2,
    .mx-md-2
    {
        margin-left: .5rem !important;
    }
    .m-md-3
    {
        margin: 1rem !important;
    }
    .mt-md-3,
    .my-md-3
    {
        margin-top: 1rem !important;
    }
    .mr-md-3,
    .mx-md-3
    {
        margin-right: 1rem !important;
    }
    .mb-md-3,
    .my-md-3
    {
        margin-bottom: 1rem !important;
    }
    .ml-md-3,
    .mx-md-3
    {
        margin-left: 1rem !important;
    }
    .m-md-4
    {
        margin: 1.5rem !important;
    }
    .mt-md-4,
    .my-md-4
    {
        margin-top: 1.5rem !important;
    }
    .mr-md-4,
    .mx-md-4
    {
        margin-right: 1.5rem !important;
    }
    .mb-md-4,
    .my-md-4
    {
        margin-bottom: 1.5rem !important;
    }
    .ml-md-4,
    .mx-md-4
    {
        margin-left: 1.5rem !important;
    }
    .m-md-5
    {
        margin: 3rem !important;
    }
    .mt-md-5,
    .my-md-5
    {
        margin-top: 3rem !important;
    }
    .mr-md-5,
    .mx-md-5
    {
        margin-right: 3rem !important;
    }
    .mb-md-5,
    .my-md-5
    {
        margin-bottom: 3rem !important;
    }
    .ml-md-5,
    .mx-md-5
    {
        margin-left: 3rem !important;
    }
    .p-md-0
    {
        padding: 0 !important;
    }
    .pt-md-0,
    .py-md-0
    {
        padding-top: 0 !important;
    }
    .pr-md-0,
    .px-md-0
    {
        padding-right: 0 !important;
    }
    .pb-md-0,
    .py-md-0
    {
        padding-bottom: 0 !important;
    }
    .pl-md-0,
    .px-md-0
    {
        padding-left: 0 !important;
    }
    .p-md-1
    {
        padding: .25rem !important;
    }
    .pt-md-1,
    .py-md-1
    {
        padding-top: .25rem !important;
    }
    .pr-md-1,
    .px-md-1
    {
        padding-right: .25rem !important;
    }
    .pb-md-1,
    .py-md-1
    {
        padding-bottom: .25rem !important;
    }
    .pl-md-1,
    .px-md-1
    {
        padding-left: .25rem !important;
    }
    .p-md-2
    {
        padding: .5rem !important;
    }
    .pt-md-2,
    .py-md-2
    {
        padding-top: .5rem !important;
    }
    .pr-md-2,
    .px-md-2
    {
        padding-right: .5rem !important;
    }
    .pb-md-2,
    .py-md-2
    {
        padding-bottom: .5rem !important;
    }
    .pl-md-2,
    .px-md-2
    {
        padding-left: .5rem !important;
    }
    .p-md-3
    {
        padding: 1rem !important;
    }
    .pt-md-3,
    .py-md-3
    {
        padding-top: 1rem !important;
    }
    .pr-md-3,
    .px-md-3
    {
        padding-right: 1rem !important;
    }
    .pb-md-3,
    .py-md-3
    {
        padding-bottom: 1rem !important;
    }
    .pl-md-3,
    .px-md-3
    {
        padding-left: 1rem !important;
    }
    .p-md-4
    {
        padding: 1.5rem !important;
    }
    .pt-md-4,
    .py-md-4
    {
        padding-top: 1.5rem !important;
    }
    .pr-md-4,
    .px-md-4
    {
        padding-right: 1.5rem !important;
    }
    .pb-md-4,
    .py-md-4
    {
        padding-bottom: 1.5rem !important;
    }
    .pl-md-4,
    .px-md-4
    {
        padding-left: 1.5rem !important;
    }
    .p-md-5
    {
        padding: 3rem !important;
    }
    .pt-md-5,
    .py-md-5
    {
        padding-top: 3rem !important;
    }
    .pr-md-5,
    .px-md-5
    {
        padding-right: 3rem !important;
    }
    .pb-md-5,
    .py-md-5
    {
        padding-bottom: 3rem !important;
    }
    .pl-md-5,
    .px-md-5
    {
        padding-left: 3rem !important;
    }
    .m-md-n1
    {
        margin: -.25rem !important;
    }
    .mt-md-n1,
    .my-md-n1
    {
        margin-top: -.25rem !important;
    }
    .mr-md-n1,
    .mx-md-n1
    {
        margin-right: -.25rem !important;
    }
    .mb-md-n1,
    .my-md-n1
    {
        margin-bottom: -.25rem !important;
    }
    .ml-md-n1,
    .mx-md-n1
    {
        margin-left: -.25rem !important;
    }
    .m-md-n2
    {
        margin: -.5rem !important;
    }
    .mt-md-n2,
    .my-md-n2
    {
        margin-top: -.5rem !important;
    }
    .mr-md-n2,
    .mx-md-n2
    {
        margin-right: -.5rem !important;
    }
    .mb-md-n2,
    .my-md-n2
    {
        margin-bottom: -.5rem !important;
    }
    .ml-md-n2,
    .mx-md-n2
    {
        margin-left: -.5rem !important;
    }
    .m-md-n3
    {
        margin: -1rem !important;
    }
    .mt-md-n3,
    .my-md-n3
    {
        margin-top: -1rem !important;
    }
    .mr-md-n3,
    .mx-md-n3
    {
        margin-right: -1rem !important;
    }
    .mb-md-n3,
    .my-md-n3
    {
        margin-bottom: -1rem !important;
    }
    .ml-md-n3,
    .mx-md-n3
    {
        margin-left: -1rem !important;
    }
    .m-md-n4
    {
        margin: -1.5rem !important;
    }
    .mt-md-n4,
    .my-md-n4
    {
        margin-top: -1.5rem !important;
    }
    .mr-md-n4,
    .mx-md-n4
    {
        margin-right: -1.5rem !important;
    }
    .mb-md-n4,
    .my-md-n4
    {
        margin-bottom: -1.5rem !important;
    }
    .ml-md-n4,
    .mx-md-n4
    {
        margin-left: -1.5rem !important;
    }
    .m-md-n5
    {
        margin: -3rem !important;
    }
    .mt-md-n5,
    .my-md-n5
    {
        margin-top: -3rem !important;
    }
    .mr-md-n5,
    .mx-md-n5
    {
        margin-right: -3rem !important;
    }
    .mb-md-n5,
    .my-md-n5
    {
        margin-bottom: -3rem !important;
    }
    .ml-md-n5,
    .mx-md-n5
    {
        margin-left: -3rem !important;
    }
    .m-md-auto
    {
        margin: auto !important;
    }
    .mt-md-auto,
    .my-md-auto
    {
        margin-top: auto !important;
    }
    .mr-md-auto,
    .mx-md-auto
    {
        margin-right: auto !important;
    }
    .mb-md-auto,
    .my-md-auto
    {
        margin-bottom: auto !important;
    }
    .ml-md-auto,
    .mx-md-auto
    {
        margin-left: auto !important;
    }
}

@media (min-width: 992px)
{
    .m-lg-0
    {
        margin: 0 !important;
    }
    .mt-lg-0,
    .my-lg-0
    {
        margin-top: 0 !important;
    }
    .mr-lg-0,
    .mx-lg-0
    {
        margin-right: 0 !important;
    }
    .mb-lg-0,
    .my-lg-0
    {
        margin-bottom: 0 !important;
    }
    .ml-lg-0,
    .mx-lg-0
    {
        margin-left: 0 !important;
    }
    .m-lg-1
    {
        margin: .25rem !important;
    }
    .mt-lg-1,
    .my-lg-1
    {
        margin-top: .25rem !important;
    }
    .mr-lg-1,
    .mx-lg-1
    {
        margin-right: .25rem !important;
    }
    .mb-lg-1,
    .my-lg-1
    {
        margin-bottom: .25rem !important;
    }
    .ml-lg-1,
    .mx-lg-1
    {
        margin-left: .25rem !important;
    }
    .m-lg-2
    {
        margin: .5rem !important;
    }
    .mt-lg-2,
    .my-lg-2
    {
        margin-top: .5rem !important;
    }
    .mr-lg-2,
    .mx-lg-2
    {
        margin-right: .5rem !important;
    }
    .mb-lg-2,
    .my-lg-2
    {
        margin-bottom: .5rem !important;
    }
    .ml-lg-2,
    .mx-lg-2
    {
        margin-left: .5rem !important;
    }
    .m-lg-3
    {
        margin: 1rem !important;
    }
    .mt-lg-3,
    .my-lg-3
    {
        margin-top: 1rem !important;
    }
    .mr-lg-3,
    .mx-lg-3
    {
        margin-right: 1rem !important;
    }
    .mb-lg-3,
    .my-lg-3
    {
        margin-bottom: 1rem !important;
    }
    .ml-lg-3,
    .mx-lg-3
    {
        margin-left: 1rem !important;
    }
    .m-lg-4
    {
        margin: 1.5rem !important;
    }
    .mt-lg-4,
    .my-lg-4
    {
        margin-top: 1.5rem !important;
    }
    .mr-lg-4,
    .mx-lg-4
    {
        margin-right: 1.5rem !important;
    }
    .mb-lg-4,
    .my-lg-4
    {
        margin-bottom: 1.5rem !important;
    }
    .ml-lg-4,
    .mx-lg-4
    {
        margin-left: 1.5rem !important;
    }
    .m-lg-5
    {
        margin: 3rem !important;
    }
    .mt-lg-5,
    .my-lg-5
    {
        margin-top: 3rem !important;
    }
    .mr-lg-5,
    .mx-lg-5
    {
        margin-right: 3rem !important;
    }
    .mb-lg-5,
    .my-lg-5
    {
        margin-bottom: 3rem !important;
    }
    .ml-lg-5,
    .mx-lg-5
    {
        margin-left: 3rem !important;
    }
    .p-lg-0
    {
        padding: 0 !important;
    }
    .pt-lg-0,
    .py-lg-0
    {
        padding-top: 0 !important;
    }
    .pr-lg-0,
    .px-lg-0
    {
        padding-right: 0 !important;
    }
    .pb-lg-0,
    .py-lg-0
    {
        padding-bottom: 0 !important;
    }
    .pl-lg-0,
    .px-lg-0
    {
        padding-left: 0 !important;
    }
    .p-lg-1
    {
        padding: .25rem !important;
    }
    .pt-lg-1,
    .py-lg-1
    {
        padding-top: .25rem !important;
    }
    .pr-lg-1,
    .px-lg-1
    {
        padding-right: .25rem !important;
    }
    .pb-lg-1,
    .py-lg-1
    {
        padding-bottom: .25rem !important;
    }
    .pl-lg-1,
    .px-lg-1
    {
        padding-left: .25rem !important;
    }
    .p-lg-2
    {
        padding: .5rem !important;
    }
    .pt-lg-2,
    .py-lg-2
    {
        padding-top: .5rem !important;
    }
    .pr-lg-2,
    .px-lg-2
    {
        padding-right: .5rem !important;
    }
    .pb-lg-2,
    .py-lg-2
    {
        padding-bottom: .5rem !important;
    }
    .pl-lg-2,
    .px-lg-2
    {
        padding-left: .5rem !important;
    }
    .p-lg-3
    {
        padding: 1rem !important;
    }
    .pt-lg-3,
    .py-lg-3
    {
        padding-top: 1rem !important;
    }
    .pr-lg-3,
    .px-lg-3
    {
        padding-right: 1rem !important;
    }
    .pb-lg-3,
    .py-lg-3
    {
        padding-bottom: 1rem !important;
    }
    .pl-lg-3,
    .px-lg-3
    {
        padding-left: 1rem !important;
    }
    .p-lg-4
    {
        padding: 1.5rem !important;
    }
    .pt-lg-4,
    .py-lg-4
    {
        padding-top: 1.5rem !important;
    }
    .pr-lg-4,
    .px-lg-4
    {
        padding-right: 1.5rem !important;
    }
    .pb-lg-4,
    .py-lg-4
    {
        padding-bottom: 1.5rem !important;
    }
    .pl-lg-4,
    .px-lg-4
    {
        padding-left: 1.5rem !important;
    }
    .p-lg-5
    {
        padding: 3rem !important;
    }
    .pt-lg-5,
    .py-lg-5
    {
        padding-top: 3rem !important;
    }
    .pr-lg-5,
    .px-lg-5
    {
        padding-right: 3rem !important;
    }
    .pb-lg-5,
    .py-lg-5
    {
        padding-bottom: 3rem !important;
    }
    .pl-lg-5,
    .px-lg-5
    {
        padding-left: 3rem !important;
    }
    .m-lg-n1
    {
        margin: -.25rem !important;
    }
    .mt-lg-n1,
    .my-lg-n1
    {
        margin-top: -.25rem !important;
    }
    .mr-lg-n1,
    .mx-lg-n1
    {
        margin-right: -.25rem !important;
    }
    .mb-lg-n1,
    .my-lg-n1
    {
        margin-bottom: -.25rem !important;
    }
    .ml-lg-n1,
    .mx-lg-n1
    {
        margin-left: -.25rem !important;
    }
    .m-lg-n2
    {
        margin: -.5rem !important;
    }
    .mt-lg-n2,
    .my-lg-n2
    {
        margin-top: -.5rem !important;
    }
    .mr-lg-n2,
    .mx-lg-n2
    {
        margin-right: -.5rem !important;
    }
    .mb-lg-n2,
    .my-lg-n2
    {
        margin-bottom: -.5rem !important;
    }
    .ml-lg-n2,
    .mx-lg-n2
    {
        margin-left: -.5rem !important;
    }
    .m-lg-n3
    {
        margin: -1rem !important;
    }
    .mt-lg-n3,
    .my-lg-n3
    {
        margin-top: -1rem !important;
    }
    .mr-lg-n3,
    .mx-lg-n3
    {
        margin-right: -1rem !important;
    }
    .mb-lg-n3,
    .my-lg-n3
    {
        margin-bottom: -1rem !important;
    }
    .ml-lg-n3,
    .mx-lg-n3
    {
        margin-left: -1rem !important;
    }
    .m-lg-n4
    {
        margin: -1.5rem !important;
    }
    .mt-lg-n4,
    .my-lg-n4
    {
        margin-top: -1.5rem !important;
    }
    .mr-lg-n4,
    .mx-lg-n4
    {
        margin-right: -1.5rem !important;
    }
    .mb-lg-n4,
    .my-lg-n4
    {
        margin-bottom: -1.5rem !important;
    }
    .ml-lg-n4,
    .mx-lg-n4
    {
        margin-left: -1.5rem !important;
    }
    .m-lg-n5
    {
        margin: -3rem !important;
    }
    .mt-lg-n5,
    .my-lg-n5
    {
        margin-top: -3rem !important;
    }
    .mr-lg-n5,
    .mx-lg-n5
    {
        margin-right: -3rem !important;
    }
    .mb-lg-n5,
    .my-lg-n5
    {
        margin-bottom: -3rem !important;
    }
    .ml-lg-n5,
    .mx-lg-n5
    {
        margin-left: -3rem !important;
    }
    .m-lg-auto
    {
        margin: auto !important;
    }
    .mt-lg-auto,
    .my-lg-auto
    {
        margin-top: auto !important;
    }
    .mr-lg-auto,
    .mx-lg-auto
    {
        margin-right: auto !important;
    }
    .mb-lg-auto,
    .my-lg-auto
    {
        margin-bottom: auto !important;
    }
    .ml-lg-auto,
    .mx-lg-auto
    {
        margin-left: auto !important;
    }
}

@media (min-width: 1200px)
{
    .m-xl-0
    {
        margin: 0 !important;
    }
    .mt-xl-0,
    .my-xl-0
    {
        margin-top: 0 !important;
    }
    .mr-xl-0,
    .mx-xl-0
    {
        margin-right: 0 !important;
    }
    .mb-xl-0,
    .my-xl-0
    {
        margin-bottom: 0 !important;
    }
    .ml-xl-0,
    .mx-xl-0
    {
        margin-left: 0 !important;
    }
    .m-xl-1
    {
        margin: .25rem !important;
    }
    .mt-xl-1,
    .my-xl-1
    {
        margin-top: .25rem !important;
    }
    .mr-xl-1,
    .mx-xl-1
    {
        margin-right: .25rem !important;
    }
    .mb-xl-1,
    .my-xl-1
    {
        margin-bottom: .25rem !important;
    }
    .ml-xl-1,
    .mx-xl-1
    {
        margin-left: .25rem !important;
    }
    .m-xl-2
    {
        margin: .5rem !important;
    }
    .mt-xl-2,
    .my-xl-2
    {
        margin-top: .5rem !important;
    }
    .mr-xl-2,
    .mx-xl-2
    {
        margin-right: .5rem !important;
    }
    .mb-xl-2,
    .my-xl-2
    {
        margin-bottom: .5rem !important;
    }
    .ml-xl-2,
    .mx-xl-2
    {
        margin-left: .5rem !important;
    }
    .m-xl-3
    {
        margin: 1rem !important;
    }
    .mt-xl-3,
    .my-xl-3
    {
        margin-top: 1rem !important;
    }
    .mr-xl-3,
    .mx-xl-3
    {
        margin-right: 1rem !important;
    }
    .mb-xl-3,
    .my-xl-3
    {
        margin-bottom: 1rem !important;
    }
    .ml-xl-3,
    .mx-xl-3
    {
        margin-left: 1rem !important;
    }
    .m-xl-4
    {
        margin: 1.5rem !important;
    }
    .mt-xl-4,
    .my-xl-4
    {
        margin-top: 1.5rem !important;
    }
    .mr-xl-4,
    .mx-xl-4
    {
        margin-right: 1.5rem !important;
    }
    .mb-xl-4,
    .my-xl-4
    {
        margin-bottom: 1.5rem !important;
    }
    .ml-xl-4,
    .mx-xl-4
    {
        margin-left: 1.5rem !important;
    }
    .m-xl-5
    {
        margin: 3rem !important;
    }
    .mt-xl-5,
    .my-xl-5
    {
        margin-top: 3rem !important;
    }
    .mr-xl-5,
    .mx-xl-5
    {
        margin-right: 3rem !important;
    }
    .mb-xl-5,
    .my-xl-5
    {
        margin-bottom: 3rem !important;
    }
    .ml-xl-5,
    .mx-xl-5
    {
        margin-left: 3rem !important;
    }
    .p-xl-0
    {
        padding: 0 !important;
    }
    .pt-xl-0,
    .py-xl-0
    {
        padding-top: 0 !important;
    }
    .pr-xl-0,
    .px-xl-0
    {
        padding-right: 0 !important;
    }
    .pb-xl-0,
    .py-xl-0
    {
        padding-bottom: 0 !important;
    }
    .pl-xl-0,
    .px-xl-0
    {
        padding-left: 0 !important;
    }
    .p-xl-1
    {
        padding: .25rem !important;
    }
    .pt-xl-1,
    .py-xl-1
    {
        padding-top: .25rem !important;
    }
    .pr-xl-1,
    .px-xl-1
    {
        padding-right: .25rem !important;
    }
    .pb-xl-1,
    .py-xl-1
    {
        padding-bottom: .25rem !important;
    }
    .pl-xl-1,
    .px-xl-1
    {
        padding-left: .25rem !important;
    }
    .p-xl-2
    {
        padding: .5rem !important;
    }
    .pt-xl-2,
    .py-xl-2
    {
        padding-top: .5rem !important;
    }
    .pr-xl-2,
    .px-xl-2
    {
        padding-right: .5rem !important;
    }
    .pb-xl-2,
    .py-xl-2
    {
        padding-bottom: .5rem !important;
    }
    .pl-xl-2,
    .px-xl-2
    {
        padding-left: .5rem !important;
    }
    .p-xl-3
    {
        padding: 1rem !important;
    }
    .pt-xl-3,
    .py-xl-3
    {
        padding-top: 1rem !important;
    }
    .pr-xl-3,
    .px-xl-3
    {
        padding-right: 1rem !important;
    }
    .pb-xl-3,
    .py-xl-3
    {
        padding-bottom: 1rem !important;
    }
    .pl-xl-3,
    .px-xl-3
    {
        padding-left: 1rem !important;
    }
    .p-xl-4
    {
        padding: 1.5rem !important;
    }
    .pt-xl-4,
    .py-xl-4
    {
        padding-top: 1.5rem !important;
    }
    .pr-xl-4,
    .px-xl-4
    {
        padding-right: 1.5rem !important;
    }
    .pb-xl-4,
    .py-xl-4
    {
        padding-bottom: 1.5rem !important;
    }
    .pl-xl-4,
    .px-xl-4
    {
        padding-left: 1.5rem !important;
    }
    .p-xl-5
    {
        padding: 3rem !important;
    }
    .pt-xl-5,
    .py-xl-5
    {
        padding-top: 3rem !important;
    }
    .pr-xl-5,
    .px-xl-5
    {
        padding-right: 3rem !important;
    }
    .pb-xl-5,
    .py-xl-5
    {
        padding-bottom: 3rem !important;
    }
    .pl-xl-5,
    .px-xl-5
    {
        padding-left: 3rem !important;
    }
    .m-xl-n1
    {
        margin: -.25rem !important;
    }
    .mt-xl-n1,
    .my-xl-n1
    {
        margin-top: -.25rem !important;
    }
    .mr-xl-n1,
    .mx-xl-n1
    {
        margin-right: -.25rem !important;
    }
    .mb-xl-n1,
    .my-xl-n1
    {
        margin-bottom: -.25rem !important;
    }
    .ml-xl-n1,
    .mx-xl-n1
    {
        margin-left: -.25rem !important;
    }
    .m-xl-n2
    {
        margin: -.5rem !important;
    }
    .mt-xl-n2,
    .my-xl-n2
    {
        margin-top: -.5rem !important;
    }
    .mr-xl-n2,
    .mx-xl-n2
    {
        margin-right: -.5rem !important;
    }
    .mb-xl-n2,
    .my-xl-n2
    {
        margin-bottom: -.5rem !important;
    }
    .ml-xl-n2,
    .mx-xl-n2
    {
        margin-left: -.5rem !important;
    }
    .m-xl-n3
    {
        margin: -1rem !important;
    }
    .mt-xl-n3,
    .my-xl-n3
    {
        margin-top: -1rem !important;
    }
    .mr-xl-n3,
    .mx-xl-n3
    {
        margin-right: -1rem !important;
    }
    .mb-xl-n3,
    .my-xl-n3
    {
        margin-bottom: -1rem !important;
    }
    .ml-xl-n3,
    .mx-xl-n3
    {
        margin-left: -1rem !important;
    }
    .m-xl-n4
    {
        margin: -1.5rem !important;
    }
    .mt-xl-n4,
    .my-xl-n4
    {
        margin-top: -1.5rem !important;
    }
    .mr-xl-n4,
    .mx-xl-n4
    {
        margin-right: -1.5rem !important;
    }
    .mb-xl-n4,
    .my-xl-n4
    {
        margin-bottom: -1.5rem !important;
    }
    .ml-xl-n4,
    .mx-xl-n4
    {
        margin-left: -1.5rem !important;
    }
    .m-xl-n5
    {
        margin: -3rem !important;
    }
    .mt-xl-n5,
    .my-xl-n5
    {
        margin-top: -3rem !important;
    }
    .mr-xl-n5,
    .mx-xl-n5
    {
        margin-right: -3rem !important;
    }
    .mb-xl-n5,
    .my-xl-n5
    {
        margin-bottom: -3rem !important;
    }
    .ml-xl-n5,
    .mx-xl-n5
    {
        margin-left: -3rem !important;
    }
    .m-xl-auto
    {
        margin: auto !important;
    }
    .mt-xl-auto,
    .my-xl-auto
    {
        margin-top: auto !important;
    }
    .mr-xl-auto,
    .mx-xl-auto
    {
        margin-right: auto !important;
    }
    .mb-xl-auto,
    .my-xl-auto
    {
        margin-bottom: auto !important;
    }
    .ml-xl-auto,
    .mx-xl-auto
    {
        margin-left: auto !important;
    }
}

.text-monospace
{
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace !important;
}

.text-justify
{
    text-align: justify !important;
}

.text-wrap
{
    white-space: normal !important;
}

.text-nowrap
{
    white-space: nowrap !important;
}

.text-truncate
{
    overflow: hidden;

    white-space: nowrap;
    text-overflow: ellipsis;
}

.text-left
{
    text-align: left !important;
}

.text-right
{
    text-align: right !important;
}

.text-center
{
    text-align: center !important;
}

@media (min-width: 576px)
{
    .text-sm-left
    {
        text-align: left !important;
    }
    .text-sm-right
    {
        text-align: right !important;
    }
    .text-sm-center
    {
        text-align: center !important;
    }
}

@media (min-width: 768px)
{
    .text-md-left
    {
        text-align: left !important;
    }
    .text-md-right
    {
        text-align: right !important;
    }
    .text-md-center
    {
        text-align: center !important;
    }
}

@media (min-width: 992px)
{
    .text-lg-left
    {
        text-align: left !important;
    }
    .text-lg-right
    {
        text-align: right !important;
    }
    .text-lg-center
    {
        text-align: center !important;
    }
}

@media (min-width: 1200px)
{
    .text-xl-left
    {
        text-align: left !important;
    }
    .text-xl-right
    {
        text-align: right !important;
    }
    .text-xl-center
    {
        text-align: center !important;
    }
}

.text-lowercase
{
    text-transform: lowercase !important;
}

.text-uppercase
{
    text-transform: uppercase !important;
}

.text-capitalize
{
    text-transform: capitalize !important;
}

.font-weight-light
{
    font-weight: 300 !important;
}

.font-weight-lighter
{
    font-weight: lighter !important;
}

.font-weight-normal
{
    font-weight: 400 !important;
}

.font-weight-bold
{
    font-weight: 700 !important;
}

.font-weight-bolder
{
    font-weight: bolder !important;
}

.font-italic
{
    font-style: italic !important;
}

.text-white
{
    color: #fff !important;
}

.text-primary
{
    color: #f5a637 !important;
}

a.text-primary:hover,
a.text-primary:focus
{
    color: #d5810b !important;
}

.text-secondary
{
    color: #6c757d !important;
}

a.text-secondary:hover,
a.text-secondary:focus
{
    color: #494f54 !important;
}

.text-success
{
    color: #28a745 !important;
}

a.text-success:hover,
a.text-success:focus
{
    color: #19692c !important;
}

.text-info
{
    color: #17a2b8 !important;
}

a.text-info:hover,
a.text-info:focus
{
    color: #0f6674 !important;
}

.text-warning
{
    color: #ffc107 !important;
}

a.text-warning:hover,
a.text-warning:focus
{
    color: #ba8b00 !important;
}

.text-danger
{
    color: #dc3545 !important;
}

a.text-danger:hover,
a.text-danger:focus
{
    color: #a71d2a !important;
}

.text-light
{
    color: #f8f9fa !important;
}

a.text-light:hover,
a.text-light:focus
{
    color: #cbd3da !important;
}

.text-dark
{
    color: #343a40 !important;
}

a.text-dark:hover,
a.text-dark:focus
{
    color: #121416 !important;
}

.text-body
{
    color: #777 !important;
}

.text-muted
{
    color: #ababab !important;
}

.text-black-50
{
    color: rgba(0, 0, 0, .5) !important;
}

.text-white-50
{
    color: rgba(255, 255, 255, .5) !important;
}

.text-hide
{
    font: 0/0 a;

    color: transparent;
    border: 0;
    background-color: transparent;
    text-shadow: none;
}

.text-decoration-none
{
    text-decoration: none !important;
}

.text-break
{
    word-break: break-word !important;

    overflow-wrap: break-word !important;
}

.text-reset
{
    color: inherit !important;
}

.visible
{
    visibility: visible !important;
}

.invisible
{
    visibility: hidden !important;
}

@media print
{
    *,
    *::before,
    *::after
    {
        box-shadow: none !important;
        text-shadow: none !important;
    }
    a:not(.btn)
    {
        text-decoration: underline;
    }
    abbr[title]::after
    {
        content: ' (' attr(title) ')';
    }
    pre
    {
        white-space: pre-wrap !important;
    }
    pre,
    blockquote
    {
        border: 1px solid #adb5bd;

        page-break-inside: avoid;
    }
    thead
    {
        display: table-header-group;
    }
    tr,
    img
    {
        page-break-inside: avoid;
    }
    p,
    h2,
    h3
    {
        orphans: 3;
        widows: 3;
    }
    h2,
    h3
    {
        page-break-after: avoid;
    }
    @page
    {
        size: a3;
    }
    body
    {
        min-width: 992px !important;
    }
    .container
    {
        min-width: 992px !important;
    }
    .navbar
    {
        display: none;
    }
    .badge
    {
        border: 1px solid #000;
    }
    .table
    {
        border-collapse: collapse !important;
    }
    .table td,
    .table th
    {
        background-color: #fff !important;
    }
    .table-bordered th,
    .table-bordered td
    {
        border: 1px solid #dee2e6 !important;
    }
    .table-dark
    {
        color: inherit;
    }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody
    {
        border-color: #f0f0f0;
    }
    .table .thead-dark th
    {
        color: inherit;
        border-color: #f0f0f0;
    }
}

/**
 * Components: About description
 */
.section_about__description
{
    font-size: 1.25rem;
    line-height: 1.5;

    position: relative;

    color: #151515;
}
.section_about__description:before
{
    display: block;

    width: 30px;
    margin: 1rem 0;

    content: '';

    border-top: 3px solid #f5a637;
}

/**
 * Components: Alerts
 */
.alert
{
    font-family: 'Playfair Display', serif;

    color: #151515;
    border-width: 2px 0 0 0;
    box-shadow: 0 5px 30px 0 rgba(0, 0, 0, .15);
}
.alert-danger
{
    border-color: #dc3545;
    background-color: white;
}
.alert-success
{
    border-color: #28a745;
    background-color: white;
}

#page-alert
{
    position: fixed;
    z-index: 999;
    top: 70px;
    right: 0;
    left: 0;

    width: 100%;
    max-width: 600px;

    text-align: center;
}
@media (min-width: 768px)
{
    #page-alert
    {
        top: 90px;
        right: auto;
        left: 50%;

        transform: translateX(-50%);
    }
}

/**
 * Components: Buttons
 */
.btn
{
    font-family: 'Lato', sans-serif;
    font-size: .75rem;
    font-weight: 700;
    line-height: 1.25rem;

    letter-spacing: .05em;
    text-transform: uppercase;
}
.btn:hover,
.btn:focus,
.btn:active,
.btn:active:hover,
.btn:active:focus
{
    cursor: pointer;

    outline: none;
    box-shadow: none !important;
}
.btn-default,
.btn-primary
{
    color: #151515;
    background-color: transparent;
}
.btn-default[disabled],
.btn-primary[disabled]
{
    pointer-events: none;

    opacity: .5;
    background-color: transparent;
}
.btn-default:hover,
.btn-default:focus,
.btn-default:active,
.btn-default:active:hover,
.btn-default:active:focus,
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary:active:hover,
.btn-primary:active:focus
{
    color: #f5a637;
    border-color: #f5a637;
    background-color: transparent !important;
}
.btn-default:hover[disabled],
.btn-default:focus[disabled],
.btn-default:active[disabled],
.btn-default:active:hover[disabled],
.btn-default:active:focus[disabled],
.btn-primary:hover[disabled],
.btn-primary:focus[disabled],
.btn-primary:active[disabled],
.btn-primary:active:hover[disabled],
.btn-primary:active:focus[disabled]
{
    pointer-events: none;

    opacity: .5;
    background-color: transparent;
}

/**
 * Components: Carousel
 */
.carousel-indicators
{
    position: relative;
    bottom: 0;

    margin-top: 2rem;
}
.carousel-indicators > li
{
    width: 10px;
    height: 10px;

    cursor: pointer;

    border-radius: 50%;
    background-color: #f0f0f0;
}
.carousel-indicators > li.active
{
    background-color: #f5a637;
}

.flickity-prev-next-button
{
    top: calc(50% - 1rem - 5px);

    display: flex;

    width: 3rem;
    height: 3rem;

    opacity: .75;
    background-color: white;

    align-items: center;
    justify-content: center;
}
.dots-disabled .flickity-prev-next-button
{
    top: 50%;
}
.flickity-prev-next-button .arrow
{
    display: none;
}
.flickity-prev-next-button:before
{
    font-family: 'Font Awesome 5 Free';
    font-size: 1.25rem;
    font-weight: 900;

    content: '\f053';
}
.next.flickity-prev-next-button:before
{
    content: '\f054';
}
.flickity-prev-next-button:hover,
.flickity-prev-next-button:focus,
.flickity-prev-next-button:active,
.flickity-prev-next-button:active:hover,
.flickity-prev-next-button:active:focus
{
    opacity: 1;
}

.flickity-page-dots
{
    position: relative;
    bottom: auto;

    margin-top: 2rem;
}
.flickity-page-dots > .dot
{
    margin: 0 3px;

    opacity: 1;
    background-color: #f0f0f0;
}
.flickity-page-dots > .dot.is-selected
{
    background-color: #f5a637;
}

/**
 * Components: Carousel
 */
.section_carousel__slider__item
{
    width: 100%;
}
.section_carousel__slider__item__caption
{
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;

    padding: 2rem 1rem 1rem;

    background: linear-gradient(to bottom, transparent, rgba(0, 0, 0, .75));
}
.section_carousel__slider__item__caption__heading
{
    color: white;
}

/**
 * Components: Contact form
 */
.section_contact__form
{
    position: relative;
}
.section_contact__form:before
{
    position: absolute;
    z-index: -1;
    right: 75%;
    bottom: 25%;

    width: 35%;
    height: 100%;

    content: '';

    opacity: .05;
    background: url(/static/media/icon_dot.9e98d86b.svg) repeat;
}

/**
 * Components: Contact info
 */
.section_contact__info
{
    margin-bottom: 2rem;
    padding: 0 1rem;
}
.section_contact__info__item
{
    margin-bottom: 1rem;
}
.section_contact__info__item + .section_contact__info__item
{
    padding-top: 1rem;

    border-top: 1px solid #f0f0f0;
}
.section_contact__info__item__heading
{
    font-family: 'Lato', sans-serif;
    font-size: .75rem;
    font-weight: 900;

    letter-spacing: .05em;
    text-transform: uppercase;
}
.section_contact__info__item__content
{
    font-family: 'Playfair Display', serif;
}
.section_contact__info__item__content a[href*='tel']
{
    color: #777;
}
.section_contact__info__item__content a[href*='tel']:hover,
.section_contact__info__item__content a[href*='tel']:focus,
.section_contact__info__item__content a[href*='tel']:active,
.section_contact__info__item__content a[href*='tel']:active:hover,
.section_contact__info__item__content a[href*='tel']:active:focus
{
    text-decoration: none;
}
ul.section_contact__info__item__content
{
    padding-left: 0;

    list-style-type: none;
}
ul.section_contact__info__item__content > li
{
    display: inline-block;
}
ul.section_contact__info__item__content > li > a
{
    line-height: 1.5rem;

    display: block;

    width: 1.5rem;
    height: 1.5rem;

    text-align: center;

    color: white;
    background-color: #f5a637;
}
ul.section_contact__info__item__content > li > a:hover
{
    background-color: #f5a637;
}
ul.section_contact__info__item__content > li + li
{
    margin-left: 3px;
}

/**
 * Components: Discover description
 */
.section_discover__description
{
    font-size: 1.25rem;
    line-height: 1.5;

    position: relative;

    color: #151515;
}
.section_discover__description:before
{
    display: block;

    width: 30px;
    margin: 1rem 0;

    content: '';

    border-top: 3px solid #f5a637;
}

/**
 * Components: Discover image
 */
.section_discover__img
{
    position: relative;

    padding: 2rem 0;
}
@media (min-width: 768px)
{
    .section_discover__img
    {
        padding: 0 20% 0 0;
    }
    .section_discover__img:before,
    .section_discover__img:after
    {
        position: absolute;
        z-index: -1;

        width: 80%;
        height: 100%;

        content: '';
    }
    .section_discover__img:before
    {
        top: 10%;
        left: 20%;

        border: 1px solid #f0f0f0;
    }
    .section_discover__img:after
    {
        top: -10%;
        left: -30%;

        display: none;

        background-color: #f0f0f0;
    }
    .section_discover__img.alt:after
    {
        display: block;
    }
}

/**
 * Components: Dishes carousel
 */
.section_dishes__carousel
{
    height: 250px;
}
.section_dishes__carousel__item
{
    height: 100%;
    padding: 0 .25rem;
}
.section_dishes__carousel__item__img
{
    width: auto;
    max-width: none !important;
    height: auto !important;
    max-height: 100%;
}
.section_dishes__carousel__item__body
{
    position: absolute;
    right: .25rem;
    bottom: 0;
    left: .25rem;

    padding: 2rem 1rem 1rem;

    background: linear-gradient(to bottom, transparent, rgba(0, 0, 0, .75));
}
.section_dishes__carousel__item__body__heading
{
    display: flex;

    max-width: calc(100vw - 1rem * 2);

    color: white;

    align-items: center;
}
.section_dishes__carousel__item__body__heading > span
{
    font-size: 1.4em;

    margin-left: auto;
}

/**
 * Components: Dropdowns
 */
.dropdown-menu
{
    padding-top: 0;
    padding-bottom: 0;

    border-color: transparent;
}
@media (min-width: 768px)
{
    .dropdown-menu
    {
        box-shadow: 0 2px 5px -1px rgba(0, 0, 0, .15);
    }
    .dropdown-menu .nav-item + .nav-item
    {
        border-top: 1px solid #f0f0f0;
    }
}

.dropdown-item
{
    font-family: 'Lato', sans-serif;
    font-size: .75rem;
    font-size: .65rem;
    font-weight: 700;

    padding: .5rem 1rem;

    transition: color .1s;
    letter-spacing: .05em;
    text-transform: uppercase;
}
.dropdown-item.active,
.dropdown-item:hover
{
    color: #f5a637;
    background-color: transparent;
}
@media (min-width: 768px)
{
    .dropdown-item
    {
        padding: .8rem 1.2rem;
    }
}
@media (min-width: 768px)
{
    .dropdown-item + .dropdown-item
    {
        border-top: 1px solid #f0f0f0;
    }
}

/**
 * Components: Event
 */
.section_events__item
{
    position: relative;
}
.section_events__item__bg
{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    transition: opacity .25s linear;

    opacity: 0;
    background-color: #333;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}
.section_events__item__bg:before
{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    content: '';

    background: linear-gradient(45deg, black, transparent);
}
.active .section_events__item__bg
{
    opacity: 1;
}
.section_events__item__content_lg
{
    overflow: hidden;

    max-height: 0;

    transition: opacity .25s;

    opacity: 0;
    color: rgba(255, 255, 255, .7);
}
.active .section_events__item__content_lg
{
    max-height: 99999px;

    opacity: 1;
}
.section_events__item__content_lg > .row
{
    padding: 150px 0;
}
.section_events__item__content_sm
{
    cursor: pointer;
}
.active .section_events__item__content_sm
{
    display: none;
}
.section_events__item__content_sm > .row
{
    padding: 40px 0;

    border-bottom: 1px solid #f0f0f0;
}
.section_events__item__content_sm time
{
    font-family: 'Playfair Display', serif;
    font-size: 2.5rem;
    line-height: 1;

    display: block;

    margin: 0;

    text-align: center;

    color: #151515;
}
.section_events__item__content_sm time small
{
    font-family: 'Lato', sans-serif;
    font-size: 1rem;

    display: block;

    margin-top: .5em;
}
.section_events__item__content_sm h4,
.section_events__item__content_sm .section_menu__item__price
{
    margin-bottom: 2rem;
}
@media (min-width: 768px)
{
    .section_events__item__content_sm h4,
    .section_events__item__content_sm .section_menu__item__price
    {
        margin: 0;
    }
}
.section_events__item__content_sm .btn
{
    margin: 0;
}

/**
 * Components: Fonts
 */
h1,
h2,
h3,
h4,
.section_menu__item__price,
h5,
h6
{
    margin-bottom: .8em;
}

h1
{
    font-size: 1.9375rem;
}

h2
{
    font-size: 1.75rem;
}

h3
{
    font-size: 1.5625rem;
}

h4,
.section_menu__item__price
{
    font-size: 1.375rem;
}

h5
{
    font-size: 1.1875rem;
}

h6
{
    font-size: 1rem;
}

.text-white
{
    color: white;
}
.text-white.btn-default:hover,
.text-white.btn-default:focus,
.text-white.btn-default:active,
.text-white.btn-default:active:hover,
.text-white.btn-default:active:focus,
.text-white.btn-primary:hover,
.text-white.btn-primary:focus,
.text-white.btn-primary:active,
.text-white.btn-primary:active:hover,
.text-white.btn-primary:active:focus
{
    color: #f5a637 !important;
}

.text-heading
{
    color: #151515;
}

blockquote
{
    font-size: 1.25rem;
}
blockquote > p
{
    font-family: 'Playfair Display', serif;

    position: relative;

    margin-bottom: 1em;

    color: #151515;
}
blockquote > p:before
{
    content: open-quote;
}
blockquote > p:after
{
    content: close-quote;
}
blockquote footer
{
    font-family: 'Lato', sans-serif;
    font-size: .75rem;
    font-weight: 700;

    letter-spacing: .05em;
    text-transform: uppercase;
}

.lead
{
    color: #151515;
}

.font-small
{
    font-family: 'Lato', sans-serif;
    font-size: .75rem;
    font-weight: 700;

    letter-spacing: .05em;
    text-transform: uppercase;
}

::selection
{
    color: white;
    background-color: #f5a637;
}

/**
 * Components: Forms
 */
.form-control-sm
{
    font-size: 1rem;
}

.form-control:-ms-input-placeholder
{
    font-family: 'Playfair Display', serif;
}

.form-control::-webkit-input-placeholder
{
    font-family: 'Playfair Display', serif;
}

.form-control::-ms-input-placeholder
{
    font-family: 'Playfair Display', serif;
}

.form-control::placeholder
{
    font-family: 'Playfair Display', serif;
}

.invalid-feedback
{
    font-size: .95rem;
}

input[type='date'].form-control,
input[type='time'].form-control
{
    height: calc(1.25em + 2rem + 2px);
}

input[type='date'].form-control-sm,
input[type='time'].form-control-sm
{
    height: calc(1.25em + 1.5rem + 2px);
}

/**
 * Components: Gallery
 */
.section_gallery__grid__item
{
    padding-right: .5rem;
    padding-bottom: 1rem;
    padding-left: .5rem;
}
.section_gallery__grid__item > a
{
    position: relative;

    display: block;
}
.section_gallery__grid__item > a:before
{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    content: '';
    transition: opacity .3s;

    opacity: 0;
    background-color: rgba(0, 0, 0, .5);
}
.section_gallery__grid__item > a:after
{
    position: absolute;
    top: 50%;
    left: 50%;

    content: url(/static/media/icon_plus.7042fb74.svg);
    transition: opacity .3s;
    transform: translate(-50%, -50%);

    opacity: 0;
}
.section_gallery__grid__item > a:hover:before,
.section_gallery__grid__item > a:hover:after
{
    opacity: 1;
}

/**
 * Components: Lightbox
 */
.lightboxOverlay
{
    opacity: .5;
}

.lb-outerContainer,
.lightbox .lb-image
{
    border-radius: 0;
}

.lb-container
{
    padding: 20px;
}

.lb-data .lb-close
{
    background: url(/static/media/icon_close.f2a711b5.svg) no-repeat center center;
}

.lb-nav a.lb-prev
{
    padding: 20px;

    background: none;
}
.lb-nav a.lb-prev:before
{
    position: absolute;
    top: 50%;
    left: 30px;

    content: url(/static/media/icon_prev.fe192a35.svg);
    transform: translateY(-50%);
}

.lb-nav a.lb-next
{
    padding: 20px;

    background: none;
}
.lb-nav a.lb-next:before
{
    position: absolute;
    top: 50%;
    right: 30px;

    content: url(/static/media/icon_next.30ad66d7.svg);
    transform: translateY(-50%);
}

/**
 * Components: Map
 */
.section_map__map
{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}
.section_map__map__heading
{
    font-family: 'Lato', sans-serif;
    font-size: .75rem;
    font-weight: 800;

    margin-top: 1rem;

    letter-spacing: .05em;
    text-transform: uppercase;
}
.section_map__map__content
{
    line-height: 1.7;
}

/**
 * Components: Menu grid
 */
.section_menu__item
{
    padding: 1rem 0;

    border-bottom: 1px solid #f0f0f0;
}
.section_menu__item__img
{
    position: relative;

    overflow: hidden;

    height: 0;
    padding-bottom: 100%;
}
.section_menu__item__img > img
{
    position: absolute;
    top: 0;
    left: 0;

    width: auto;
    max-width: 1000px !important;
    height: 100% !important;
    max-height: 100%;
}
.section_menu__item p
{
    margin-bottom: 0;
}

/**
 * Components: Menu navigation
 */
.section_menu__nav
{
    margin-bottom: 2.5rem;
}
.section_menu__nav > ul
{
    padding-left: 0;

    list-style-type: none;

    text-align: center;
}
.section_menu__nav > ul > li
{
    display: inline-block;
}
.section_menu__nav > ul > li > a
{
    font-family: 'Lato', sans-serif;
    font-size: .75rem;
    font-weight: 900;

    position: relative;

    display: block;

    padding: 1rem 1rem;

    transition: color .1s;
    letter-spacing: .05em;
    text-transform: uppercase;

    color: #777;
}
.section_menu__nav > ul > li > a:after
{
    position: absolute;
    right: 1rem;
    bottom: 5px;
    left: 1rem;

    display: block;

    content: '';
    transition: all .1s;
    transform: translateY(10px);

    opacity: 0;
    border-top: 3px solid #f5a637;
}
.section_menu__nav > ul > li > a:hover,
.section_menu__nav > ul > li > a:focus,
.section_menu__nav > ul > li > a:active,
.section_menu__nav > ul > li > a:active:hover,
.section_menu__nav > ul > li > a:active:focus
{
    text-decoration: none;

    color: #151515;
}
.section_menu__nav > ul > li.active > a
{
    color: #151515;
}
.section_menu__nav > ul > li.active > a:after
{
    transform: translateY(0);

    opacity: 1;
}

/**
 * Components: Navbar
 */
.navbar
{
    min-height: 70px;
}
@media (max-width: 991.98px)
{
    .navbar
    {
        padding-top: 0;
        padding-bottom: 0;
    }
}
@media (max-width: 991.98px)
{
    .navbar > .container
    {
        width: 100%;
    }
}
@media (max-width: 991.98px)
{
    .navbar-nav
    {
        padding-bottom: 1rem;
    }
}
.navbar .nav-link
{
    font-family: 'Lato', sans-serif;
    font-size: .75rem;
    font-weight: 700;

    transition: color .1s;
    letter-spacing: .05em;
    text-transform: uppercase;
}
@media (min-width: 768px)
{
    .navbar .nav-link
    {
        padding-right: 1rem !important;
        padding-left: 1rem !important;
    }
}
.navbar-brand
{
    font-family: 'Playfair Display', serif;
    font-size: 2rem;
    font-style: italic;

    transition: color .1s;
}
.navbar-brand > img
{
    width: auto;
    max-height: calc(70px - 10px - 1rem);
}
@media (min-width: 992px)
{
    .navbar-brand
    {
        margin-left: 1rem;
    }
}
.navbar-toggler
{
    right: 0;

    display: inline-flex;

    height: 70px;
    padding-top: 0;
    padding-bottom: 0;

    align-items: center;
}
.navbar:before
{
    position: absolute;
    z-index: -1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    content: '';
    transition: all .3s;
    transform: translateY(-100%);

    background-color: white;
}
.navbar-light
{
    background-color: transparent;
}
.navbar-light .navbar-brand
{
    color: white;
}
.navbar-light .navbar-brand:hover,
.navbar-light .navbar-brand:focus,
.navbar-light .navbar-brand:active,
.navbar-light .navbar-brand:active:hover,
.navbar-light .navbar-brand:active:focus
{
    color: white;
}
.navbar-dark
{
    background-color: transparent;
}
.navbar-dark:before
{
    transform: translateY(0);
}
.navbar-dark .navbar-brand
{
    color: #151515;
}

/**
 * Components: Newsletter
 */
@media (min-width: 768px)
{
    .section_newsletter__form .row > [class*='col']
    {
        padding-right: .5rem;
        padding-left: .5rem;
    }
}

.section_newsletter__form #mce-hidden-input
{
    position: absolute;

    transform: scale(0);
}

/**
 * Components: Phone number
 */
.section_phone__row > [class*='col']
{
    position: relative;
}
.section_phone__row > [class*='col']:before,
.section_phone__row > [class*='col']:after
{
    position: absolute;

    width: 2rem;
    height: 2rem;

    content: '';
}
.section_phone__row > [class*='col']:before
{
    bottom: 0;
    left: 1rem;

    border-bottom: 1px solid #f5a637;
    border-left: 1px solid #f5a637;
}
@media (min-width: 768px)
{
    .section_phone__row > [class*='col']:before
    {
        left: 0;
    }
}
.section_phone__row > [class*='col']:after
{
    top: 0;
    right: 1rem;

    border-top: 1px solid #f5a637;
    border-right: 1px solid #f5a637;
}
@media (min-width: 768px)
{
    .section_phone__row > [class*='col']:after
    {
        right: 0;
    }
}

.section_phone__number
{
    font-family: 'Playfair Display', serif;
    font-size: 2.5rem;
    font-weight: bold;
}
.section_phone__number > a
{
    color: #151515;
}
.section_phone__number > a:hover,
.section_phone__number > a:focus,
.section_phone__number > a:active,
.section_phone__number > a:active:hover,
.section_phone__number > a:active:focus
{
    text-decoration: none;
}

/**
 * Components: Quote
 */
.section_quote__blockquote
{
    margin-bottom: 0;
}
.section_quote__blockquote:before
{
    font-family: 'Playfair Display', serif;
    font-size: 10rem;
    line-height: 5rem;

    display: block;

    content: '”';
    text-align: center;

    color: rgba(21, 21, 21, .1);
}
.section_quote__blockquote > footer
{
    position: relative;

    margin-top: 2rem;
    padding-top: 2rem;
}
.section_quote__blockquote > footer:before
{
    position: absolute;
    top: 0;
    left: 50%;

    width: 50px;

    content: '';
    transform: translateX(-50%);

    border-top: 2px solid #f5a637;
}

/**
 * Components: Scaffolding
 */
html
{
    font-size: 14px;

    position: relative;
}
@media (min-width: 576px)
{
    html
    {
        font-size: 15px;
    }
}

body
{
    position: relative;
}

/**
 * Components: Section
 */
.section
{
    position: relative;

    padding: 3rem 0;
}
@media (min-width: 768px)
{
    .section
    {
        padding: 6rem 0;
    }
}
.section[data-parallax='scroll']
{
    background-color: transparent;
}
.section_gray
{
    background-color: #f0f0f0;
}
.section_border_top
{
    border-top: 1px solid #f0f0f0;
}
.section_border_bottom
{
    border-bottom: 1px solid #f0f0f0;
}
.section_no-padding_top
{
    padding-top: 0;
}
.section_no-padding_bottom
{
    padding-bottom: 0;
}
.section__preheading
{
    font-family: 'Lato', sans-serif;
    font-size: .75rem;
    font-weight: 700;

    letter-spacing: .05em;
    text-transform: uppercase;
}
.section__heading
{
    max-width: 600px;
    margin-bottom: 2.5rem;
}
.section__heading.text-center
{
    margin-right: auto;
    margin-left: auto;
}
.section__heading + .section__subheading
{
    margin-top: -1.5rem;
}
.section__subheading
{
    max-width: 600px;
    margin-bottom: 2.5rem;
}
.section__subheading.text-center
{
    margin-right: auto;
    margin-left: auto;
}

/**
 * Components: Tables
 */
.table thead th
{
    border-bottom-width: 1px;
}

/**
 * Components: Testimonials carousel
 */
.section_testimonials__blockquote
{
    position: relative;

    max-width: 500px;
    margin-bottom: 2rem;
}
@media (min-width: 768px)
{
    .section_testimonials__blockquote
    {
        margin-bottom: 0;
    }
}

.section_testimonials__photo > img
{
    max-width: 50vw;
    margin-bottom: 2rem;

    border-radius: 50% !important;
}
@media (min-width: 768px)
{
    .section_testimonials__photo > img
    {
        max-width: 100%;
        margin-bottom: 0;
    }
}

.section_testimonials__carousel__item
{
    width: 100%;
}

.section
{
    /*** Sections: Welcome ***/
    /*** Sections: About ***/
    /*** Sections: Reservation ***/
    /*** Sections: Gallery ***/
    /*** Sections: Map ***/
    /*** Sections: Header ***/
    /*** Sections: Info ***/
}
.section_welcome
{
    padding-bottom: 70px;

    color: white;
}
.section_welcome:before
{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    content: '';

    opacity: .5;
    background: black;
}
@media (min-width: 768px)
{
    .section_welcome
    {
        height: 100vh;
        padding-top: 70px;
        padding-bottom: 0;
    }
}
.section_welcome__heading
{
    font-size: 5rem;
    font-weight: 700;

    color: white;
}
.section_welcome__subheading
{
    margin-bottom: 3rem;
}
.section_welcome .text-muted
{
    color: rgba(255, 255, 255, .7) !important;
}
@media (min-width: 768px)
{
    .section_welcome__main
    {
        height: calc(100vh - 70px * 2);
    }
}
.section_welcome__main > .container
{
    height: 100%;
}
.section_welcome__main > .container > .row
{
    height: 100%;
}
.section_welcome__footer
{
    height: 70px;
    margin-top: 3rem;
}
.section_welcome__footer > .container
{
    height: 100%;
}
.section_welcome__footer > .container > .row
{
    height: 100%;
}
@media (min-width: 768px)
{
    .section_welcome__footer
    {
        margin-top: 0;
    }
}
.section_welcome__footer__social
{
    padding-left: 0;

    list-style-type: none;
}
@media (min-width: 768px)
{
    .section_welcome__footer__social
    {
        margin-bottom: 0;
    }
}
.section_welcome__footer__social > li
{
    display: inline-block;
}
.section_welcome__footer__social > li > a
{
    transition: color .1s;

    color: rgba(255, 255, 255, .7);
}
.section_welcome__footer__social > li > a:hover,
.section_welcome__footer__social > li > a:focus,
.section_welcome__footer__social > li > a:active,
.section_welcome__footer__social > li > a:active:hover,
.section_welcome__footer__social > li > a:active:focus
{
    color: #f5a637;
}
.section_welcome__footer__social > li + li
{
    margin-left: 2rem;
}
.section_welcome__footer__address
{
    font-family: 'Playfair Display', serif;

    color: rgba(255, 255, 255, .7);
}
.section_welcome__footer__address > i
{
    margin-right: 1rem;

    color: #f5a637;
}
.section_about
{
    min-height: 500px;

    background: url(/static/media/19.a5487b8c.jpg) no-repeat center center/cover;
}
@media (min-width: 768px)
{
    .section_about
    {
        height: 100vh;
    }
}
.section_about:before
{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    content: '';

    background: linear-gradient(white, rgba(255, 255, 255, .1) 30%, transparent), linear-gradient(to bottom right, white 0%, white 15%, transparent 30%, transparent 100%);
}
@media (min-width: 768px)
{f
    .section_reservation__row [class*='col']
    {
        padding-right: .5rem;
        padding-left: .5rem;
    }
}
.section_gallery
{
    padding-right: .5rem;
    padding-left: .5rem;
}
.section_map
{
    min-height: 30rem;
}
.section_header
{
    padding: calc(70px + 3rem) 0 3rem;
}
.section_header:before
{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    content: '';

    opacity: .5;
    background-color: black;
}
@media (min-width: 768px)
{
    .section_header
    {
        padding: calc(70px + 9rem) 0 9rem;
    }
}
.section_header__heading
{
    font-size: 2.5rem;
    font-weight: 700;

    color: white;
}
@media (min-width: 768px)
{
    .section_info
    {
        overflow: hidden;
    }
}
.section_info__body
{
    padding: 3rem 0;
}
@media (min-width: 768px)
{
    .section_info__body
    {
        padding: 6rem 0;
    }
}
.section_info__img
{
    position: relative;

    height: 100%;
}
@media (max-width: 991.98px)
{
    .section_info__img > img
    {
        max-width: 100%;
        height: auto;
    }
}
@media (min-width: 768px)
{
    .section_info__img > img
    {
        position: absolute;
        top: 0;
        right: -1rem;

        width: auto;
        max-width: none !important;
        height: 100% !important;
        max-height: 100%;
    }
    .section_info_opposite .section_info__img > img
    {
        right: auto;
        left: -1rem;
    }
}

/**
 * Layouts: Footer
 */
.section_footer
{
    background-color: #151515;
}
@media (min-width: 768px)
{
    .section_footer [class*='col']
    {
        padding: 0 2rem;
    }
}
.section_footer a
{
    color: #777;
}
.section_footer a:hover,
.section_footer a:focus,
.section_footer a:active,
.section_footer a:active:hover,
.section_footer a:active:focus
{
    color: #777;
}
.section_footer__heading
{
    font-family: 'Lato', sans-serif;
    font-size: .75rem;
    font-weight: 700;

    margin-top: 2rem;
    margin-bottom: 1.5rem;

    letter-spacing: .05em;
    text-transform: uppercase;

    color: #f5a637;
}
@media (min-width: 768px)
{
    .section_footer__heading
    {
        margin-top: 0;
    }
}
.section_footer__info
{
    padding-left: 0;

    list-style-type: none;
}
.section_footer__info > li
{
    position: relative;

    padding-left: 2rem;
}
.section_footer__info > li > i
{
    position: absolute;
    top: .4em;
    left: 0;
}
.section_footer__info > li + li
{
    margin-top: .5rem;
}
.section_footer__open__days
{
    font-family: 'Lato', sans-serif;
    font-size: .75rem;
    font-weight: 700;
    font-weight: 900;

    letter-spacing: .05em;
    text-transform: uppercase;
}
.section_footer__open__time
{
    font-family: 'Playfair Display', serif;
    font-size: 1.25rem;
}
.section_footer__open + .section_footer__open
{
    margin-top: 1rem;
}
.section_footer__copyright
{
    margin-top: 2rem;
}
.section_footer__copyright:before
{
    display: inline-block;

    width: 3rem;
    margin-right: 1rem;

    content: '';
    vertical-align: middle;

    border-top: 1px solid #777;
}
.preloader {
  align-items: center;
  background: rgb(23, 22, 22);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  transition: opacity 0.3s linear;
  width: 100%;
  z-index: 9999;
}

